import useActiveWorkspaceId from "../../hooks/useActiveWorkspaceId";
import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import WorkspaceLoading from "./WorkspaceLoading";
import WorkspacePostCreateConfetti from "../WorkspacePostCreateConfetti";

export default function WorkspaceSelected({
  children,
}: {
  children: ReactNode;
}) {
  const workspaceId = useActiveWorkspaceId();

  return workspaceId ? (
    <>
      <WorkspacePostCreateConfetti />
      <WorkspaceLoading>{children}</WorkspaceLoading>
    </>
  ) : (
    <Navigate to="/" replace />
  );
}
