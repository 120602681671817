import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  mailId: string;
  signatureId: string;
};

export type typeResultData = { signatureId: string; title: string };
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___signatureSelect(___options: typeOptions) {
  return async function ({
    workspaceId,
    mailId,
    signatureId,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      mailId, // (required) (undefined === null | "") Joi.string().pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
      signatureId, // (required) (undefined === null | "") Joi.string().min(0).max(3000)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (mailId === null || mailId === "") {
      delete ___payload?.["mailId"];
    }

    if (signatureId === null || signatureId === "") {
      delete ___payload?.["signatureId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      mailId: Joi.string()
        .pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
      signatureId: Joi.string().min(0).max(3000).required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___signatureSelect" failed even before calling the network.
api/private/sdk/signature/select.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/signature/select",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
