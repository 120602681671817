import { UAParser } from "ua-parser-js";

export default function getValidSemverEnv(uaString: string): string {
  const parser = new UAParser();
  parser.setUA(uaString);

  const parserResult = parser.getResult();

  let splitParts = (parserResult.browser.version || "").split(".");

  // if smaller than needed
  while (splitParts.length < 3) {
    splitParts.push("0");
  }
  // if more than needed
  if (splitParts.length > 3) {
    splitParts = splitParts.slice(0, 3);
  }
  // remove non-numeric characters
  splitParts = splitParts.map((splitPart) => splitPart.replace(/\D/g, ""));

  return splitParts.join(".");
}
