import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = { workspaceId: string; mailId: string };

export type typeResultData = {
  workspaceId: string;
  mailId: string;
  title: string;
  createdAtMs: number;
  isAvailableForTracking: boolean;
  isAvailableForSending: boolean;
  isAuthorized: boolean;
  isActive: boolean;
  isWarmEnabled: boolean;
  mailMatchingProvider: "unknown" | "zoho" | "microsoft" | "gmail";
  mailAuthProvider?: "smtp" | "zoho" | "microsoft" | "gmail";
  fromName: string;
  limits: {
    minutes: number;
    maxEmailsSent: number;
  }[];
  sendLimit: null | {
    error: {
      code: string;
      message: string;
      details?: any;
    } | null;
    limitHit: null | {
      maxEmailsSent: number;
      minutes: number;
      sentAgainstThisLimit: number;
    };
    stats: {
      maxEmailsSent: number;
      minutes: number;
      sentAgainstThisLimit: number;
    }[];
  };
  checkLimit: null | {
    checkInboxOncePerMinutes: number;
  };
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___mailGet(___options: typeOptions) {
  return async function ({
    workspaceId,
    mailId,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      mailId, // (required) (undefined === null | "") Joi.string().pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (mailId === null || mailId === "") {
      delete ___payload?.["mailId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      mailId: Joi.string()
        .pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___mailGet" failed even before calling the network.
api/private/sdk/mail/get.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/mail/get",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
