import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import InfoBoxCreateBan from "./InfoBoxCreateBan";
import { LoadingButton } from "@mui/lab";
import useAdminSuspendCreate from "utils/reactQueryHooks/admin-suspend/useAdminSuspendCreate";
import { useSnackbar } from "notistack";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function DialogCreateBan({ isOpen, onClose }: Props) {
  const snackbar = useSnackbar();
  const [valueToBan, setValueToBan] = useState("");

  const createBan = useAdminSuspendCreate({});

  const isInvalidText = valueToBan.includes("@") === false;
  const isCreateBanDisabled =
    valueToBan === "" || isInvalidText || createBan.isLoading;

  const onCreateBan = async () => {
    if (!isCreateBanDisabled) {
      const createResult = await createBan.mutateAsync({
        value: valueToBan,
      });

      if (!createResult.error) {
        snackbar.enqueueSnackbar(`Ban created: ${valueToBan}`, {
          variant: "info",
        });
        onClose();
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      disableRestoreFocus={true}
    >
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <InfoBoxCreateBan />
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoFocus
              disabled={createBan.isLoading}
              fullWidth
              label="Enter email address or domain to be banned"
              InputLabelProps={{ shrink: true }}
              name="create-new-ban"
              size="small"
              onChange={(e) => setValueToBan(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onCreateBan();
                }
              }}
              value={valueToBan}
            />

            {valueToBan && isInvalidText ? (
              <Alert severity="error" sx={{ mt: 1 }}>
                Value must contain '@'
              </Alert>
            ) : null}
          </Grid>

          <Grid container item xs={12} justifyContent={"space-between"}>
            <Grid item>
              <Button color="error" onClick={onClose}>
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <LoadingButton
                disabled={isCreateBanDisabled}
                loading={createBan.isLoading}
                onClick={onCreateBan}
                variant="contained"
              >
                Create ban
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
