import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import IconButton from "@mui/material/IconButton";
import useGetThemeMode from "../utils/useGetThemeMode";
import { CircularProgress, Tooltip } from "@mui/material";
import { useState } from "react";

export default function ThemeModeSelection() {
  const themeMode = useGetThemeMode();
  const [isClicked, setIsClicked] = useState(false);

  return (
    <>
      <Tooltip
        title={
          themeMode === "dark"
            ? "Click to use light theme"
            : "Click to use dark theme"
        }
      >
        {themeMode === "dark" ? (
          <IconButton
            onClick={() => {
              setIsClicked(true);
              localStorage.setItem("ui-theme", "light");
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            }}
          >
            {isClicked ? (
              <CircularProgress size={16} style={{ margin: 4 }} />
            ) : (
              <DarkModeIcon />
            )}
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              setIsClicked(true);
              localStorage.setItem("ui-theme", "dark");
              // eslint-disable-next-line no-restricted-globals
              location.reload();
            }}
          >
            {isClicked ? (
              <CircularProgress size={16} style={{ margin: 4 }} />
            ) : (
              <LightModeIcon />
            )}
          </IconButton>
        )}
      </Tooltip>
    </>
  );
}
