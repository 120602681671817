import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  mailId: string;
  title?: string;
  mailMatchingProvider?: string;
  isAvailableForSending?: boolean;
  isAvailableForTracking?: boolean;
  fromName?: string;
  isWarmEnabled?: boolean;
};

export type typeResultData = {
  workspaceId: string;
  mailId: string;
  title: string;
  mailMatchingProvider: "gmail" | "zoho" | "microsoft" | "unknown";
  isAvailableForTracking: boolean;
  isAvailableForSending: boolean;
  isWarmEnabled: boolean;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___mailUpdate(___options: typeOptions) {
  return async function ({
    workspaceId,
    mailId,
    title,
    mailMatchingProvider,
    isAvailableForSending,
    isAvailableForTracking,
    fromName,
    isWarmEnabled,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      mailId, // (required) (undefined === null | "") Joi.string().pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
      title, // (optional) (undefined === null | "") Joi.string().min(0).max(200)
      mailMatchingProvider, // (optional) (undefined === null | "") Joi.string().valid('gmail', 'microsoft', 'zoho', 'unknown')
      isAvailableForSending, // (optional) (undefined === null) Joi.boolean()
      isAvailableForTracking, // (optional) (undefined === null) Joi.boolean()
      fromName, // (optional) (undefined === null | "") Joi.string().min(0).max(300)
      isWarmEnabled, // (optional) (undefined === null) Joi.boolean()
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (mailId === null || mailId === "") {
      delete ___payload?.["mailId"];
    }

    if (title === null || title === "") {
      delete ___payload?.["title"];
    }

    if (mailMatchingProvider === null || mailMatchingProvider === "") {
      delete ___payload?.["mailMatchingProvider"];
    }

    if (isAvailableForSending === null) {
      delete ___payload?.["isAvailableForSending"];
    }

    if (isAvailableForTracking === null) {
      delete ___payload?.["isAvailableForTracking"];
    }

    if (fromName === null || fromName === "") {
      delete ___payload?.["fromName"];
    }

    if (isWarmEnabled === null) {
      delete ___payload?.["isWarmEnabled"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      mailId: Joi.string()
        .pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
      title: Joi.string().min(0).max(200),
      mailMatchingProvider: Joi.string().valid(
        "gmail",
        "microsoft",
        "zoho",
        "unknown"
      ),
      isAvailableForSending: Joi.boolean(),
      isAvailableForTracking: Joi.boolean(),
      fromName: Joi.string().min(0).max(300),
      isWarmEnabled: Joi.boolean(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___mailUpdate" failed even before calling the network.
api/private/sdk/mail/update.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/mail/update",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
