import { Alert, useTheme } from "@mui/material";
import React from "react";

type Props = {};

export default function InfoBoxEnterValueToCheck({}: Props) {
  const theme = useTheme();

  return (
    <Alert severity="info">
      <ol style={{ margin: 0, padding: theme.spacing(0, 0, 0, 2) }}>
        <li>
          You can check exact email address like{" "}
          <strong>'someone@gmail.com'</strong>
        </li>
        <li>
          You can check email domain by starting with @.
          <br />
          E.g. <strong>'@gmail.com'</strong> would check if all gmail mails are
          banned or not
        </li>
      </ol>
    </Alert>
  );
}
