import { useQueryParams } from "use-query-params";
import useActiveWorkspaceId from "../hooks/useActiveWorkspaceId";
import React, { useEffect, useState } from "react";
import useWorkspaceGet from "../utils/reactQueryHooks/workspace/useWorkspaceGet";
import { Fade } from "@mui/material";
import Confetti from "react-confetti";

const ls = require("localstorage-ttl");

export default function WorkspacePostCreateConfetti() {
  const [queryParams] = useQueryParams({
    ["after-create"]: "yes",
    ["after-join"]: "yes",
  });

  const workspaceId = useActiveWorkspaceId();
  const isAfterCreateOrJoin =
    queryParams["after-create"] === "yes" ||
    queryParams["after-join"] === "yes";

  const [doShowConfetti, setDoShowConfetti] = useState(false);

  useWorkspaceGet(
    { workspaceId },
    {
      enabled: Boolean(workspaceId),
    }
  );

  useEffect(() => {
    let timeOut: any = null;
    if (
      isAfterCreateOrJoin &&
      doShowConfetti === false &&
      !ls.get(`confetti-${workspaceId}`)
    ) {
      setDoShowConfetti(true);
      timeOut = setTimeout(() => {
        ls.set(`confetti-${workspaceId}`, "done", 24 * 60 * 60 * 1000);
        setDoShowConfetti(false);
      }, 1500);
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [isAfterCreateOrJoin]);

  return (
    <>
      {isAfterCreateOrJoin ? (
        <Fade in={doShowConfetti} timeout={1200}>
          <div
            style={{
              zIndex: Number.MAX_SAFE_INTEGER,
              position: "fixed",
              top: 0,
              width: "100wv",
            }}
          >
            <Confetti numberOfPieces={100} />
          </div>
        </Fade>
      ) : null}
    </>
  );
}
