import { CelebrationRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Card, Grid, Typography, useTheme } from "@mui/material";
import { WORKSPACE_ID, routes } from "config";
import { useJune } from "hooks/june/useJune";
import useActiveWorkspaceId from "hooks/useActiveWorkspaceId";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLeadsListListInfinity } from "utils/reactQueryHooks/leads-list/useLeadsListList";
import useProspectingBucketCreateTrial from "utils/reactQueryHooks/prospecting-bucket/useProspectingBucketCreateTrial";
import useProspectingBucketHaveIUsedTrialCredits from "utils/reactQueryHooks/prospecting-bucket/useProspectingBucketHaveIUsedTrialCredits";

type Props = {};

export default function MenuItemClaimProspectorCredits({}: Props) {
  const theme = useTheme();
  const { trackJuneEvent } = useJune();
  const workspaceId = useActiveWorkspaceId();
  const navigate = useNavigate();

  const leadsListListQuery = useLeadsListListInfinity(
    {
      workspaceId,
    },
    { refetchInterval: 10 * 1000 }
  );
  const areProspectorCreditsUsed = useProspectingBucketHaveIUsedTrialCredits({
    workspaceId,
  });
  const doShowClaimCredits =
    leadsListListQuery.isFetched &&
    areProspectorCreditsUsed.isFetched &&
    areProspectorCreditsUsed?.data?.data?.isUsed === false &&
    !leadsListListQuery.data?.pages[0].data?.Items[0]?.leadsListId;

  const claim = useProspectingBucketCreateTrial({
    onSuccess: (res) => {
      if (!res.error) {
        trackJuneEvent("prospector_trial_credits_claimed", null);
      }
    },
  });

  return doShowClaimCredits ? (
    <Grid item xs={12}>
      <Card style={{ padding: theme.spacing(1) }} variant="outlined">
        <Typography
          fontWeight={700}
          style={{
            textAlign: "center",
            marginBottom: theme.spacing(1),
          }}
        >
          One time offer!
        </Typography>

        <LoadingButton
          loading={claim.isLoading}
          onClick={async () => {
            const claimResult = await claim.mutateAsync({ workspaceId });

            if (
              claimResult.data?.prospectingCreditsBucketId &&
              !claimResult.error
            ) {
              navigate(
                `${routes.firstSalesProspects.path}?${WORKSPACE_ID}=${workspaceId}`
              );
            }
          }}
          fullWidth
          startIcon={<CelebrationRounded />}
          size="large"
          style={{
            // padding: theme.spacing(1, 2),
            // borderRadius: 0,
            justifyContent: "start",
          }}
          variant="contained"
        >
          Claim free credits
        </LoadingButton>
      </Card>
    </Grid>
  ) : null;
}
