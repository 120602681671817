import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ReactNode } from "react";
import { Route } from ".";
import { BusinessCenterOutlined, WalletRounded } from "@mui/icons-material";

type Props = { route: Route };

export default function SidebarMenuItemIcon({ route }: Props) {
  let startIcon: null | ReactNode = null;

  if (route.path === "/campaigns") {
    startIcon = <EmailOutlinedIcon />;
  }
  if (route.path === "/contacts") {
    startIcon = <ContactsOutlinedIcon />;
  }
  if (route.path === "/settings") {
    startIcon = <SettingsOutlinedIcon />;
  }
  if (route.path === "/billing") {
    startIcon = <AttachMoneyOutlinedIcon />;
  }
  if (route.path === "/firstsales-prospects") {
    startIcon = <BusinessCenterOutlined />;
  }
  if (route.path === "/bonus") {
    startIcon = <WalletRounded />;
  }

  return startIcon;
}
