import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  campaignId?: string;
  perPage?: 5 | 15 | 25 | 50 | 100;
  lastKnown?: string;
  sortOrder?: "asc" | "desc";
  planItemBucket?:
    | "planned"
    | "executing"
    | "done"
    | "delayed"
    | "sent"
    | "failed"
    | "resuming";
};

export type typeResultData = {
  Items: {
    workspaceId: string;
    planItemId: string;
    mailId: string;
    campaignId: string;
    delayToAtMs?: number;
    contactId: string;
    doneAtMs?: number;
    willSendDate: string;
    planItemBucket:
      | "planned"
      | "executing"
      | "done"
      | "sent"
      | "delayed"
      | "resuming"
      | "failed";
    type: "new_execution" | "follow_up";
    failCount: number;
    createdAtMs: number;
    forDateTimeSlot: string;
    campaign: null | {
      isRunning: boolean;
      title: string;
    };
    mail: null | {
      isActive: boolean;
      isAuthorized: boolean;
      mailMatchingProvider: "gmail" | "zoho" | "microsoft" | "unknown";
      title: string;
    };
    contact: null | {
      contactId: string;
      firstName?: string;
      lastName?: string;
      email: string;
      bucket: "new" | "active" | "suspended";
      status: "warm" | "cold";
      lastEventAtMs: number;
      validatedAtMs: number;
      createdAtMs: number;
      tags: string[];
      primaryMx?: string;
      isDisposable?: boolean;
      mailProvider?: "unknown" | "zoho" | "microsoft" | "gmail";
    };
  }[];
  lastKnown: string;
  isThereMore: boolean;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___planList(___options: typeOptions) {
  return async function ({
    workspaceId,
    campaignId,
    perPage,
    lastKnown,
    sortOrder,
    planItemBucket,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      campaignId, // (optional) (undefined === null | "") Joi.string().pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/)
      perPage, // (optional) (undefined === null) Joi.number().valid(5, 15, 25, 50, 100)
      lastKnown, // (optional) (undefined === null | "") Joi.string().min(1).max(100)
      sortOrder, // (optional) (undefined === null) Joi.string().valid('asc', 'desc')
      planItemBucket, // (optional) (undefined === null) Joi.string().valid(   "planned",   "executing",   "done",   "delayed",  "sent", "failed", "resuming" )
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (campaignId === null || campaignId === "") {
      delete ___payload?.["campaignId"];
    }

    if (perPage === null) {
      delete ___payload?.["perPage"];
    }

    if (lastKnown === null || lastKnown === "") {
      delete ___payload?.["lastKnown"];
    }

    if (sortOrder === null) {
      delete ___payload?.["sortOrder"];
    }

    if (planItemBucket === null) {
      delete ___payload?.["planItemBucket"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      campaignId: Joi.string().pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/),
      perPage: Joi.number().valid(5, 15, 25, 50, 100),
      lastKnown: Joi.string().min(1).max(100),
      sortOrder: Joi.string().valid("asc", "desc"),
      planItemBucket: Joi.string().valid(
        "planned",
        "executing",
        "done",
        "delayed",
        "sent",
        "failed",
        "resuming"
      ),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___planList" failed even before calling the network.
api/private/sdk/plan/list.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/plan/list",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
