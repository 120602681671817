import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import React from "react";
import SingleBanCheckResult from "./SingleBanCheckResult";

type Props = {
  valuesToCheck: string[];
};

export default function BanCheckResults({ valuesToCheck }: Props) {
  return (
    <>
      {valuesToCheck.length > 0 ? (
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <Typography color="text.secondary" variant="body2">
              Results:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper} variant="outlined">
              <Table sx={{ width: "100%" }} size="small">
                <TableBody>
                  {valuesToCheck.map((_valueToCheck, i) => (
                    <SingleBanCheckResult
                      key={_valueToCheck + i}
                      value={_valueToCheck}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
