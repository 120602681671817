import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/team/get-invitation-url";
import useCallSdk from "../useCallSdk";

export function buildTeamGetInvitationUrlQueryKey(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  let _queryKey: string[] = [];

  if (
    typeof workspaceId !== "undefined" &&
    workspaceId !== null &&
    workspaceId !== ""
  ) {
    _queryKey.push(String(workspaceId));
  }

  return ["team:get-invitation-url", ..._queryKey];
}

export function useTeamGetInvitationUrlQueryFn(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().teamGetInvitationUrl({
      workspaceId,
    });
}

export type typeTeamGetInvitationUrlHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useTeamGetInvitationUrl(
  _payload: typePayload,
  _options?: typeTeamGetInvitationUrlHookOptions
) {
  const workspaceId = _payload.workspaceId;
  _options = _options || {};
  const _queryKey = buildTeamGetInvitationUrlQueryKey({ workspaceId });
  const _queryFn = useTeamGetInvitationUrlQueryFn({ workspaceId });

  return useQuery(_queryKey, _queryFn, _options);
}
