import {
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/admin-suspend/list";
import useCallSdk from "../useCallSdk";
import { useEffect } from "react";

export function buildAdminSuspendListQueryKey(_payload?: typePayload) {
  const lastKnown = _payload?.lastKnown;
  const perPage = _payload?.perPage;
  let _queryKey: string[] = [];

  if (
    typeof lastKnown !== "undefined" &&
    lastKnown !== null &&
    lastKnown !== ""
  ) {
    _queryKey.push(String(lastKnown));
  }

  if (typeof perPage !== "undefined" && perPage !== null) {
    _queryKey.push(String(perPage));
  }

  return ["admin-suspend:list", ..._queryKey];
}

export function useAdminSuspendListQueryFn(_payload?: typePayload) {
  const lastKnown = _payload?.lastKnown;
  const perPage = _payload?.perPage;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().adminSuspendList({
      lastKnown,
      perPage,
    });
}

export type typeAdminSuspendListHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useAdminSuspendList(
  _payload?: typePayload,
  _options?: typeAdminSuspendListHookOptions
) {
  const lastKnown = _payload?.lastKnown;
  const perPage = _payload?.perPage;
  _options = _options || {};
  const _queryKey = buildAdminSuspendListQueryKey({ lastKnown, perPage });
  const _queryFn = useAdminSuspendListQueryFn({ lastKnown, perPage });

  return useQuery(_queryKey, _queryFn, _options);
}

export type typeAdminSuspendListHookOptionsInfinite = UseInfiniteQueryOptions<
  typeResult,
  unknown,
  typeResult,
  typeResult,
  string[]
>;

export function useAdminSuspendListInfinity(
  _payload?: typePayload,
  _options?: typeAdminSuspendListHookOptionsInfinite
) {
  const perPage = _payload?.perPage;
  const _queryClient = useQueryClient();
  _options = _options || {};
  const _queryKey = buildAdminSuspendListQueryKey({ perPage });
  const _sdk = useCallSdk();

  if (typeof _options.getNextPageParam === "undefined") {
    _options.getNextPageParam = (_lastPage: typeResult) =>
      _lastPage.data?.isThereMore ? _lastPage.data?.lastKnown : undefined;
  }

  useEffect(() => {
    const _onUnmount = () => {
      _queryClient.setQueryData(_queryKey, (_oldData: any) => {
        if (_oldData?.pages?.[1] && _oldData?.pageParams[1]) {
          return {
            pages: _oldData.pages.slice(0, 1),
            pageParams: _oldData.pageParams.slice(0, 1),
          };
        }
      });
    };

    return _onUnmount;
  }, []);

  return useInfiniteQuery(
    _queryKey,
    ({ pageParam = undefined }) =>
      _sdk.callSdk().adminSuspendList({
        perPage,
        lastKnown: pageParam,
      }),
    _options
  );
}
