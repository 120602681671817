import useDebounce from "hooks/useDebounce";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import countryCodeToName from "utils/countryCodeToName";
import { useAuth } from "utils/providers/AuthProvider";
import useUserGetMeta from "utils/reactQueryHooks/user/useUserGetMeta";
import useWorkspaceGet from "utils/reactQueryHooks/workspace/useWorkspaceGet";
import useActiveWorkspaceId from "../useActiveWorkspaceId";
import { useJune } from "./useJune";
import { routes } from "config";

export default function useJuneEventsFireOnAppStateChange() {
  const { juneAnalytics, trackJuneEvent } = useJune();

  const auth = useAuth();

  const workspaceId = useActiveWorkspaceId();
  const userGetMeta = useUserGetMeta({}, { enabled: auth.isLoggedIn });

  const location = useLocation();
  const currentUrl = location.pathname + location.search;

  const workspace = useWorkspaceGet(
    {
      workspaceId,
    },
    { enabled: Boolean(workspaceId && auth.isLoggedIn) }
  );

  const debounce = useDebounce({ delayMs: 500 }); // effects fire twice in strict mode. Don't want to give wrong impression Eg page visited twice when it actually was visited only once, hence slight debounce

  useEffect(() => {
    const userId = userGetMeta.data?.data?.userId || "";
    if (userId) {
      juneAnalytics?.identify(userId, {
        email: userGetMeta.data?.data?.email || "",
        country: countryCodeToName(userGetMeta.data?.data?.countryCode || ""),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGetMeta.data?.data?.userId]);

  useEffect(() => {
    if (workspace.data?.data?.workspaceId) {
      juneAnalytics.group(workspaceId, {
        name: workspace.data?.data?.title,
        createdAt: new Date(
          workspace?.data?.data?.createdAtMs || 0
        ).toISOString(), // seems to be the format it is expecting
        isPaidWorkspace: workspace.data?.data?.isPaid ? "yes" : "no",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace.data?.data?.workspaceId]);

  useEffect(() => {
    let pageName = location.pathname;

    if (location.pathname === routes.bonus.path) {
      trackJuneEvent("bonus_section_visited", null);
    }

    debounce(() => juneAnalytics?.page(pageName)); // it automatically adds many other properties before sending req so not doing it manually here

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);
}
