import { Schedule } from "components/Campaigns/Campaign/CampaignSchedule/ScheduleWorkdays";

export const config = {
  staticHost: "https://client.firstsales.io",
  apiHost: "https://api.firstsales.io/private",
  // process.env.NODE_ENV === "production"
  //   ? "https://api.firstsales.io/private"
  //   : "http://localhost:11136/private",
  authCookieDomain:
    process.env.NODE_ENV === "production" ? "firstsales.io" : "localhost",
  googleClientId:
    process.env.NODE_ENV === "production"
      ? "736211669191-5332iq27jlt50tdvfjfjfunnf6iov8u7.apps.googleusercontent.com"
      : "578257960648-0ue1gtq7omuiu4rqjihgn1dflqtln4vh.apps.googleusercontent.com",
  juneWriteKey:
    process.env.NODE_ENV === "production"
      ? "QrP5Cm0etuS2UzrD"
      : "QrP5Cm0etuS2UzrD",
  customDomainCnameValue: "redirect.clikk2.link",
  // bottle-neck related
  concurrentContactsUploadCount: 100,
  concurrentMassActionsCount: 30,
  retryDelayMs: 5000,
  maxRetriesCount: 2,
  //
  contactsCreateMaxCsvRowsCount: 500000,
  maxAttributesPerContact: 50,
  trialDaysForNewWorkspace: 7,
  maxVariationsPerSequence: 300,
  defaultMailAccountLimits: [
    {
      minutes: 1,
      maxEmailsSent: 1,
    },
    {
      minutes: 5,
      maxEmailsSent: 2,
    },
    {
      minutes: 60,
      maxEmailsSent: 7,
    },
    {
      minutes: 60 * 24,
      maxEmailsSent: 40,
    },
  ],
  maxDomainsLimit: 25,

  prospectorFreeLeadsCount: 15,
} as const;

export const routes = {
  workspaces: {
    path: "/workspaces",
    title: "Workspaces",
    isInTopMenu: false,
    isNew: false,
  },
  contacts: {
    path: "/contacts",
    title: "Contacts",
    isInTopMenu: true,
    isNew: false,
  },
  campaigns: {
    path: "/campaigns",
    title: "Campaigns",
    isInTopMenu: true,
    isNew: false,
  },
  firstSalesProspects: {
    path: "/firstsales-prospects",
    title: "Firstsales Prospector",
    isInTopMenu: true,
    isNew: false,
  },
  campaignBuilder: {
    path: "/ai-campaign-builder",
    title: "AI Campaign builder",
    isInTopMenu: false,
    isNew: false,
  },
  templates: {
    path: "/templates",
    title: "Templates",
    // todo change this to true once released
    isInTopMenu: false,
    isNew: false,
  },
  settings: {
    path: "/settings",
    title: "Settings",
    isInTopMenu: true,
    isNew: false,
  },
  billing: {
    path: "/billing",
    title: "Billing",
    isInTopMenu: true,
    isNew: false,
  },
  bonus: {
    path: "/bonus",
    title: "Bonus",
    isInTopMenu: true,
    isNew: false,
  },
  admin: {
    path: "/admin",
    title: "Admin",
    isInTopMenu: true,
    isNew: false,
  },

  auth_login: {
    path: "/auth/login",
    title: "Login",
    isInTopMenu: false,
    isNew: false,
  },
  join_workspace: {
    path: "/join-workspace",
    title: "Join workspace",
    isInTopMenu: false,
    isNew: false,
  },
  share_leads_list: {
    path: "/share-leads-list",
    title: "share leads list",
    isInTopMenu: false,
    isNew: false,
  },
  fs_prospector_plans: {
    path: "/fs-prospector-plans",
    title: "Firstsales prospector plans",
    isInTopMenu: false,
    isNew: false,
  },
  fs_outreach_plans: {
    path: "/fs-outreach-plans",
    title: "Firstsales outreach plans",
    isInTopMenu: false,
    isNew: false,
  },
  plans: {
    path: "/plans",
    title: "plans",
    isInTopMenu: false,
    isNew: false,
  },
} as const;

export const ACCESS_TOKEN = "_tm-access-token";
export const ACCESS_TOKEN_EXPIRES_AT = "_tm-access-token-expires-at";
export const URL_BEFORE_AUTH = "_tm-url-before-auth";
export const WORKSPACE_ID = "workspace-id";

interface Feature {
  name: string;
  value: string | boolean | number | null;
  isQuantity?: boolean;
  quantityStep?: number;
}

interface Plan {
  id: string;
  monthlyPriceId?: string;
  yearlyPriceId?: string;
  title: string;
  description: string;
  features: Feature[];
}

export const plans: Plan[] = [
  {
    id: "plan-1",
    monthlyPriceId: "price_1NC6qpEmg5o5LsYYc6rEDIYb",
    yearlyPriceId: "price_1NC6qpEmg5o5LsYYo9zkyZfC",
    title: "Small Business",
    description: "Team Size of 1-5",
    features: [
      { name: "Team members", value: "Unlimited" },
      { name: "Total No. of Mail Accounts", value: "Unlimited" },
      { name: "Total No. of Reply Tracking Accounts", value: "Unlimited" },
      { name: "Total No. of Active Leads (Upload Contacts)", value: "15,000" },
      { name: "Total No. of Emails Sent", value: "60,000" },
      { name: "Total No. of Lists", value: "Unlimited" },
      { name: "Total No. of Segments", value: 10 },
      { name: "Advanced Sequences", value: "Unlimited" },
      { name: "AI Credits", value: 250 },
      { name: "Analytics", value: true },
      { name: "Global Block List", value: true },
      { name: "A/B Split Testing", value: true },
      { name: "Bounce Detection", value: true },
      { name: "General Support", value: true },
      { name: "Priority Support", value: false },
      { name: "Pricing Monthly", value: "$60/month" },
      { name: "Pricing Yearly", value: "$600/year" },
    ],
  },
  {
    id: "plan-2",
    monthlyPriceId: "price_1NC6qvEmg5o5LsYYPhzAeZoX",
    yearlyPriceId: "price_1NCHc1Emg5o5LsYYkDr8BXAR",
    title: "Medium Business",
    description: "Sales Team Size of 6-50",

    features: [
      { name: "Team members", value: "Unlimited" },
      {
        name: "Total No. of Mail Accounts",
        value: 1,
        isQuantity: true,
        quantityStep: 1,
      },
      {
        name: "Total No. of Reply Tracking Accounts",
        value: 1,
        isQuantity: true,
        quantityStep: 1,
      },
      {
        name: "Total No. of Active Leads (Upload Contacts)",
        value: "Unlimited",
      },
      { name: "Total No. of Emails Sent", value: "Unlimited" },
      { name: "Total No. of Lists", value: "Unlimited" },
      { name: "Total No. of Segments", value: "Unlimited" },
      { name: "Advanced Sequences", value: "Unlimited" },
      { name: "AI Credits", value: 1000, isQuantity: true, quantityStep: 1000 },
      { name: "Analytics", value: true },
      { name: "Global Block List", value: true },
      { name: "A/B Split Testing", value: true },
      { name: "Bounce Detection", value: true },
      { name: "General Support", value: true },
      { name: "Priority Support", value: true },
      { name: "Pricing Monthly", value: "$50/user/month" },
      { name: "Pricing Yearly", value: "$500/user/year" },
    ],
  },
  {
    id: "plan-3",
    title: "Enterprise",
    description: "For Sales Team Size of 50+",
    features: [
      { name: "Team members", value: "Unlimited" },
      { name: "Total No. of Mail Accounts", value: "Let's Talk" },
      { name: "Total No. of Reply Tracking Accounts", value: "Let's Talk" },
      {
        name: "Total No. of Active Leads (Upload Contacts)",
        value: "Let's Talk",
      },
      { name: "Total No. of Emails Sent", value: "Let's Talk" },
      { name: "Total No. of Lists", value: "Let's Talk" },
      { name: "Total No. of Segments", value: "Let's Talk" },
      { name: "Advanced Sequences", value: "Let's Talk" },
      { name: "AI Credits", value: "Let's Talk" },
      { name: "Analytics", value: true },
      { name: "Global Block List", value: true },
      { name: "A/B Split Testing", value: true },
      { name: "Bounce Detection", value: true },
      { name: "General Support", value: true },
      { name: "Priority Support", value: true },
      { name: "Pricing Monthly", value: "Let's talk" },
      { name: "Pricing Yearly", value: "Let's talk" },
    ],
  },
];

// TODO: remove old ones once released and stable
export const newPlans = [
  {
    title: "Firstsales Outreach 1",
    interval: "month",
    id: "price_1NYXnxEmg5o5LsYYODS2IIDD",
    unit_amount: 60,
    contactsCount: "15000",
    emailSentCount30d: "60000",
    aiCredits30d: "100",
  },
  {
    title: "Firstsales Outreach 1",
    interval: "year",
    id: "price_1NYXnxEmg5o5LsYY3HjBdJrI",
    unit_amount: 600,
    contactsCount: "15000",
    emailSentCount30d: "60000",
    aiCredits30d: "100",
  },
  {
    title: "Firstsales Outreach 2",
    interval: "month",
    id: "price_1NYXrEEmg5o5LsYY9JRPptEP",
    unit_amount: 120,
    contactsCount: "31500",
    emailSentCount30d: "126000",
    aiCredits30d: "210",
  },
  {
    title: "Firstsales Outreach 2",
    interval: "year",
    id: "price_1NYXrFEmg5o5LsYYHiOnZxyL",
    unit_amount: 1200,
    contactsCount: "31500",
    emailSentCount30d: "126000",
    aiCredits30d: "210",
  },
  {
    title: "Firstsales Outreach 3",
    interval: "month",
    id: "price_1NYXrZEmg5o5LsYY0Ekj1Ubo",
    unit_amount: 180,
    contactsCount: "48825",
    emailSentCount30d: "195300",
    aiCredits30d: "325",
  },
  {
    title: "Firstsales Outreach 3",
    interval: "year",
    id: "price_1NYXrZEmg5o5LsYYgR1uoKtA",
    unit_amount: 1800,
    contactsCount: "48825",
    emailSentCount30d: "195300",
    aiCredits30d: "325",
  },
  {
    title: "Firstsales Outreach 4",
    interval: "month",
    id: "price_1NYXrtEmg5o5LsYYjL5VIxZg",
    unit_amount: 240,
    contactsCount: "66150",
    emailSentCount30d: "264600",
    aiCredits30d: "441",
  },
  {
    title: "Firstsales Outreach 4",
    interval: "year",
    id: "price_1NYXrtEmg5o5LsYYVzNLW7Cl",
    unit_amount: 2400,
    contactsCount: "66150",
    emailSentCount30d: "264600",
    aiCredits30d: "441",
  },
  {
    title: "Firstsales Outreach 5",
    interval: "month",
    id: "price_1NYXsBEmg5o5LsYYK51lvLlL",
    unit_amount: 360,
    contactsCount: "102533",
    emailSentCount30d: "410130",
    aiCredits30d: "684",
  },
  {
    title: "Firstsales Outreach 5",
    interval: "year",
    id: "price_1NYXsBEmg5o5LsYYPEM2M5cj",
    unit_amount: 3600,
    contactsCount: "102533",
    emailSentCount30d: "410130",
    aiCredits30d: "684",
  },
  {
    title: "Firstsales Outreach 6",
    interval: "month",
    id: "price_1NYXsTEmg5o5LsYY8BulzpyN",
    unit_amount: 480,
    contactsCount: "138916",
    emailSentCount30d: "555660",
    aiCredits30d: "926",
  },
  {
    title: "Firstsales Outreach 6",
    interval: "year",
    id: "price_1NYXsTEmg5o5LsYYPw95Sm5V",
    unit_amount: 4800,
    contactsCount: "138916",
    emailSentCount30d: "555660",
    aiCredits30d: "926",
  },
  {
    title: "Firstsales Outreach 7",
    interval: "month",
    id: "price_1NYXskEmg5o5LsYYVodklsDU",
    unit_amount: 720,
    contactsCount: "215268",
    emailSentCount30d: "861074",
    aiCredits30d: "1425",
  },
  {
    title: "Firstsales Outreach 7",
    interval: "year",
    id: "price_1NYXskEmg5o5LsYYRWsn3r6z",
    unit_amount: 7200,
    contactsCount: "215268",
    emailSentCount30d: "861074",
    aiCredits30d: "1425",
  },
  {
    title: "Firstsales Outreach 8",
    interval: "month",
    id: "price_1NYXt0Emg5o5LsYY1pgnXp7o",
    unit_amount: 960,
    contactsCount: "291722",
    emailSentCount30d: "1166886",
    aiCredits30d: "1944",
  },
  {
    title: "Firstsales Outreach 8",
    interval: "year",
    id: "price_1NYXt0Emg5o5LsYYedEIPtCf",
    unit_amount: 9600,
    contactsCount: "291722",
    emailSentCount30d: "1166886",
    aiCredits30d: "1944",
  },
  {
    title: "Firstsales Outreach 9",
    interval: "month",
    id: "price_1NYXtMEmg5o5LsYYwM8BCpF6",
    unit_amount: 1440,
    contactsCount: "452669",
    emailSentCount30d: "1810677",
    aiCredits30d: "3014",
  },
  {
    title: "Firstsales Outreach 9",
    interval: "year",
    id: "price_1NYXtMEmg5o5LsYYvP55rMNp",
    unit_amount: 14400,
    contactsCount: "452669",
    emailSentCount30d: "1810677",
    aiCredits30d: "3014",
  },
  {
    title: "Firstsales Outreach 10",
    interval: "month",
    id: "price_1NYXtbEmg5o5LsYYd2uj1rqh",
    unit_amount: 1920,
    contactsCount: "613616",
    emailSentCount30d: "2454460",
    aiCredits30d: "4083",
  },
  {
    title: "Firstsales Outreach 10",
    interval: "year",
    id: "price_1NYXtbEmg5o5LsYYB8e8l1bw",
    unit_amount: 19200,
    contactsCount: "613616",
    emailSentCount30d: "2454460",
    aiCredits30d: "4083",
  },
  {
    title: "Firstsales Outreach 11",
    interval: "month",
    id: "price_1NYXtpEmg5o5LsYYcLcle78P",
    unit_amount: 2880,
    contactsCount: "951104",
    emailSentCount30d: "3804418",
    aiCredits30d: "6330",
  },
  {
    title: "Firstsales Outreach 11",
    interval: "year",
    id: "price_1NYXtpEmg5o5LsYYVjUfklnS",
    unit_amount: 28800,
    contactsCount: "951104",
    emailSentCount30d: "3804418",
    aiCredits30d: "6330",
  },
  {
    title: "Firstsales Outreach 12",
    interval: "month",
    id: "price_1NYXu7Emg5o5LsYYB5QZ5vhn",
    unit_amount: 3840,
    contactsCount: "1288592",
    emailSentCount30d: "5154368",
    aiCredits30d: "8575",
  },
  {
    title: "Firstsales Outreach 12",
    interval: "year",
    id: "price_1NYXu7Emg5o5LsYYI80dnIhB",
    unit_amount: 38400,
    contactsCount: "1288592",
    emailSentCount30d: "5154368",
    aiCredits30d: "8575",
  },
  {
    title: "Firstsales Outreach 13",
    interval: "month",
    id: "price_1NYXuOEmg5o5LsYYvdYGJN7g",
    unit_amount: 4368,
    contactsCount: "1997118",
    emailSentCount30d: "7988474",
    aiCredits30d: "13291",
  },
  {
    title: "Firstsales Outreach 13",
    interval: "year",
    id: "price_1NYXuOEmg5o5LsYY8Z2s3AFc",
    unit_amount: 43680,
    contactsCount: "1997118",
    emailSentCount30d: "7988474",
    aiCredits30d: "13291",
  },
  {
    title: "Firstsales Outreach 14",
    interval: "month",
    id: "price_1NYXufEmg5o5LsYYtRAbt1l0",
    unit_amount: 4896,
    contactsCount: "2705644",
    emailSentCount30d: "10822576",
    aiCredits30d: "18007",
  },
  {
    title: "Firstsales Outreach 14",
    interval: "year",
    id: "price_1NYXufEmg5o5LsYYFEcZVgxk",
    unit_amount: 48960,
    contactsCount: "2705644",
    emailSentCount30d: "10822576",
    aiCredits30d: "18007",
  },
  {
    title: "Firstsales Outreach 15",
    interval: "month",
    id: "price_1NYXutEmg5o5LsYYl6TbMavI",
    unit_amount: 7344,
    contactsCount: "4188748",
    emailSentCount30d: "16754992",
    aiCredits30d: "27911",
  },
  {
    title: "Firstsales Outreach 15",
    interval: "year",
    id: "price_1NYXutEmg5o5LsYYJrwQfmUt",
    unit_amount: 73440,
    contactsCount: "4188748",
    emailSentCount30d: "16754992",
    aiCredits30d: "27911",
  },
  {
    title: "Firstsales Outreach 16",
    interval: "month",
    id: "price_1NYXv6Emg5o5LsYYo2CewA36",
    unit_amount: 11016,
    contactsCount: "6492360",
    emailSentCount30d: "25969440",
    aiCredits30d: "43261",
  },
  {
    title: "Firstsales Outreach 16",
    interval: "year",
    id: "price_1NYXv6Emg5o5LsYYLAexlm8l",
    unit_amount: 110160,
    contactsCount: "6492360",
    emailSentCount30d: "25969440",
    aiCredits30d: "43261",
  },
] as const;

export const prospectorPlans = [
  // {
  //   planNumber: "1",
  //   pricePerMonth: "$49",
  //   pricePerYear: "$490",
  //   verifiedProspectsPerMonth: "1000",
  //   verifiedProspectsPerYear: "12000",
  //   priceIdMonth: "price_1NZacrEmg5o5LsYYFOKFY0qh",
  //   priceIdYear: "price_1NZacrEmg5o5LsYYqA7RnXDn",
  //   buyLinkMonth: "https://buy.stripe.com/3cs17N1cueOl6Ag3da",
  //   buyLinkYear: "https://buy.stripe.com/6oE9Ej6wOay59Ms5lh",
  // },
  // {
  //   planNumber: "2",
  //   pricePerMonth: "$74",
  //   pricePerYear: "$740",
  //   verifiedProspectsPerMonth: "2000",
  //   verifiedProspectsPerYear: "24000",
  //   priceIdMonth: "price_1NZaf2Emg5o5LsYY5qGN7Lvh",
  //   priceIdYear: "price_1NZaf2Emg5o5LsYYsueIo4i6",
  //   buyLinkMonth: "https://buy.stripe.com/3cs7wb3kC5dL9Ms8xx",
  //   buyLinkYear: "https://buy.stripe.com/00geYD9J0ay59Ms010",
  // },
  {
    planNumber: "3",
    pricePerMonth: "$99",
    pricePerYear: "$990",
    verifiedProspectsPerMonth: "5000",
    verifiedProspectsPerYear: "60000",
    priceIdMonth: "price_1NZafXEmg5o5LsYYJu5qWvo4",
    priceIdYear: "price_1NZafXEmg5o5LsYYIfQSonqC",
    buyLinkMonth: "https://buy.stripe.com/cN203JdZgcGd9MsbJL",
    buyLinkYear: "https://buy.stripe.com/cN2cQvdZg49H8Io012",
    roi: "Medium",
  },
  // {
  //   planNumber: "4",
  //   pricePerMonth: "$172",
  //   pricePerYear: "$1,720",
  //   verifiedProspectsPerMonth: "7000",
  //   verifiedProspectsPerYear: "84000",
  //   priceIdMonth: "price_1NZafrEmg5o5LsYYCWqa3yBG",
  //   priceIdYear: "price_1NZafrEmg5o5LsYYtgd2g1XA",
  //   buyLinkMonth: "https://buy.stripe.com/9AQ6s75sK21zcYEdRU",
  //   buyLinkYear: "https://buy.stripe.com/eVa5o3g7o5dL9MsdRV",
  // },
  {
    planNumber: "5",
    pricePerMonth: "$245",
    pricePerYear: "$2,450",
    verifiedProspectsPerMonth: "15000",
    verifiedProspectsPerYear: "180000",
    priceIdMonth: "price_1NZanMEmg5o5LsYYZbkKJg9X",
    priceIdYear: "price_1NZanMEmg5o5LsYYHhyXcFZ9",
    buyLinkMonth: "https://buy.stripe.com/5kA03J9J0fSpgaQbJP",
    buyLinkYear: "https://buy.stripe.com/8wMaIng7o6hP3o4eW0",
    roi: "High",
  },
  {
    planNumber: "6",
    pricePerMonth: "$295",
    pricePerYear: "$2,950",
    verifiedProspectsPerMonth: "20000",
    verifiedProspectsPerYear: "240000",
    priceIdMonth: "price_1NZannEmg5o5LsYYSLnxqvFx",
    priceIdYear: "price_1NZannEmg5o5LsYYuPU7myDp",
    buyLinkMonth: "https://buy.stripe.com/3cs9Ej08qdKhf6MdRZ",
    buyLinkYear: "https://buy.stripe.com/9AQ4jZg7oay5aQw4ho",
    roi: "Very high",
  },
  {
    planNumber: "7",
    pricePerMonth: "$490",
    pricePerYear: "$4,900",
    verifiedProspectsPerMonth: "40000",
    verifiedProspectsPerYear: "480000",
    priceIdMonth: "price_1NZao8Emg5o5LsYY0LxlgsvT",
    priceIdYear: "price_1NZao8Emg5o5LsYYEMFvVJby",
    buyLinkMonth: "https://buy.stripe.com/14kbMr5sK0Xv1fW5lv",
    buyLinkYear: "https://buy.stripe.com/aEU8Af9J035D8Io29i",
    roi: "Highest",
  },
  {
    planNumber: "8",
    pricePerMonth: "$735",
    pricePerYear: "$7,350",
    verifiedProspectsPerMonth: "60000",
    verifiedProspectsPerYear: "720000",
    priceIdMonth: "price_1NZaoXEmg5o5LsYY0hENjhit",
    priceIdYear: "price_1NZaoXEmg5o5LsYYto7R2eIq",
    buyLinkMonth: "https://buy.stripe.com/dR66s72gygWtbUA29l",
    buyLinkYear: "https://buy.stripe.com/cN28Af6wOeOl9Ms01c",
    roi: "Highest",
  },
  // {
  //   planNumber: "9",
  //   pricePerMonth: "$980",
  //   pricePerYear: "$9,800",
  //   verifiedProspectsPerMonth: "80000",
  //   verifiedProspectsPerYear: "960000",
  //   priceIdMonth: "price_1NZaotEmg5o5LsYYi0CLl4c8",
  //   priceIdYear: "price_1NZaotEmg5o5LsYYXK12GuqY",
  //   buyLinkMonth: "https://buy.stripe.com/cN2eYD9J0ay50bS3dr",
  //   buyLinkYear: "https://buy.stripe.com/eVa2bRdZgay5f6MbJW",
  // },
  {
    planNumber: "10",
    pricePerMonth: "$1,225",
    pricePerYear: "$12,250",
    verifiedProspectsPerMonth: "100000",
    verifiedProspectsPerYear: "1200000",
    priceIdMonth: "price_1NZapHEmg5o5LsYYFrBdj3tm",
    priceIdYear: "price_1NZapHEmg5o5LsYYUTawPbn5",
    buyLinkMonth: "https://buy.stripe.com/5kA6s78EWay58IocO3",
    buyLinkYear: "https://buy.stripe.com/3csaIncVc35Df6MeWa",
    roi: "Highest",
  },
  // {
  //   planNumber: "11",
  //   pricePerMonth: "$1,470",
  //   pricePerYear: "$14,700",
  //   verifiedProspectsPerMonth: "120000",
  //   verifiedProspectsPerYear: "1440000",
  //   priceIdMonth: "price_1NZapcEmg5o5LsYYBJNqdYE3",
  //   priceIdYear: "price_1NZapcEmg5o5LsYYZzCUKxYD",
  //   buyLinkMonth: "https://buy.stripe.com/9AQ03Jf3kgWtbUA15n",
  //   buyLinkYear: "https://buy.stripe.com/cN28Af9J049H8Io15m",
  // },
  {
    planNumber: "12",
    pricePerMonth: "$1999",
    pricePerYear: "$19990",
    verifiedProspectsPerMonth: "Unlimited",
    verifiedProspectsPerYear: "Unlimited",
    priceIdMonth: "price_1NZaq1Emg5o5LsYY6rLncsWL",
    priceIdYear: "price_1NZaq1Emg5o5LsYYndjAmLw8",
    buyLinkMonth: "https://buy.stripe.com/fZeaIn9J08pXe2IcO7",
    buyLinkYear: "https://buy.stripe.com/cN2g2H08q8pXcYEg0i",
    roi: "Unlimited",
  },
] as const;

// 8am to 1pm. 15 emails/mail account.
// 2pm to 7pm. 20 emails/mail account.

// schedule days variants
type ScheduleDays = Schedule["days"];
let firstRange = {
  from: 480, // 8:00
  to: 780, // 13:00
  maxSendCount: 15,
};
let secondRange = {
  from: 840, // 14:00
  to: 1140, // 19:00
  maxSendCount: 20,
};
export const scheduleDaysVariantWorkingDays: ScheduleDays = {
  monday: [firstRange, secondRange],
  tuesday: [firstRange, secondRange],
  wednesday: [firstRange, secondRange],
  thursday: [firstRange, secondRange],
  friday: [firstRange, secondRange],
  saturday: [],
  sunday: [],
};
