import loadable from "@loadable/component";
import AuthScreensLayout from "components/AuthScreensLayout";
import PostLoginLayout from "components/PostLoginLayout";
import PostLoginSectionLayout from "components/PostLoginSectionLayout";
import AuthorizedRoute from "utils/providers/AuthProvider/helpers/jsx/AuthorizedRoute";
import AuthScreen from "utils/providers/AuthProvider/helpers/jsx/AuthScreen";
import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "../../config";
import WorkspaceSelected from "../WorkspaceSelected";
import LinkedinPopupCallback from "../Login/LinkedinPopupCallback";
import Admin from "../Admin";
import ShareLeadsList from "components/ShareLeadsList";
import useJuneEventsFireOnAppStateChange from "hooks/june/useJuneEventsFireOnAppStateChange";

const Login = loadable(
  () => import(/* webpackPrefetch: true */ "components/Login")
);
const Workspaces = loadable(
  () => import(/* webpackPrefetch: true */ "components/Workspaces")
);
const Contacts = loadable(
  () => import(/* webpackPrefetch: true */ "components/Contacts")
);
const Campaigns = loadable(
  () => import(/* webpackPrefetch: true */ "components/Campaigns")
);
const FirstsalesProspects = loadable(
  () => import(/* webpackPrefetch: true */ "components/FirstsalesProspects")
);
const Templates = loadable(
  () => import(/* webpackPrefetch: true */ "components/Templates")
);
const Settings = loadable(
  () => import(/* webpackPrefetch: true */ "components/Settings")
);
const Billing = loadable(
  () => import(/* webpackPrefetch: true */ "components/Billing")
);
const Onboarding = loadable(
  () => import(/* webpackPrefetch: true */ "components/CampaignBuilder")
);
const NotFound = loadable(
  () => import(/* webpackPrefetch: true */ "components/partials/NotFound")
);
const JoinWorkspace = loadable(
  () => import(/* webpackPrefetch: true */ "components/JoinWorkspace")
);
const ProspectorPlansPublicPage = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "components/PublicPages/ProspectorPlansPublicPage"
    )
);
const OutreachPlansPublicPage = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "components/PublicPages/OutreachPlansPublicPage"
    )
);
const PlansPublicPage = loadable(
  () =>
    import(/* webpackPrefetch: true */ "components/PublicPages/PlansPublicPage")
);
const Bonus = loadable(
  () => import(/* webpackPrefetch: true */ "components/Bonus")
);

setTimeout(() => {
  // after app loaded - preload other components, all are small enough and this removes lag while navigating.
  // todo better track when app is idle for 1 second and then preload.
  Login.preload();
  Workspaces.preload();
  Contacts.preload();
  Campaigns.preload();
  Onboarding.preload();
  Templates.preload();
  Settings.preload();
  NotFound.preload();
  FirstsalesProspects.preload();
}, 2000);

export default function AppRoutes() {
  useJuneEventsFireOnAppStateChange();

  return (
    <Routes>
      <Route
        path={"/"}
        element={<Navigate replace to={routes.workspaces.path} />}
      />
      <Route
        path={"/linkedin-auth-callback"}
        element={<LinkedinPopupCallback />}
      />

      <Route
        path={routes.fs_prospector_plans.path}
        element={<ProspectorPlansPublicPage />}
      />
      <Route
        path={routes.fs_outreach_plans.path}
        element={<OutreachPlansPublicPage />}
      />
      <Route path={routes.plans.path} element={<PlansPublicPage />} />

      <Route
        path={routes.workspaces.path}
        element={
          <AuthorizedRoute>
            <Workspaces />
          </AuthorizedRoute>
        }
      />
      <Route
        path={routes.join_workspace.path}
        element={
          <AuthorizedRoute>
            <JoinWorkspace />
          </AuthorizedRoute>
        }
      />

      <Route
        path={routes.share_leads_list.path}
        element={
          <AuthorizedRoute>
            <ShareLeadsList />
          </AuthorizedRoute>
        }
      />

      <Route
        element={
          <AuthorizedRoute>
            <WorkspaceSelected>
              <PostLoginLayout />
            </WorkspaceSelected>
          </AuthorizedRoute>
        }
      >
        <Route element={<PostLoginSectionLayout />}>
          <Route path={routes.contacts.path} element={<Contacts />} />
          <Route path={routes.admin.path} element={<Admin />} />
          <Route path={routes.campaigns.path} element={<Campaigns />} />
          <Route
            path={routes.firstSalesProspects.path}
            element={<FirstsalesProspects />}
          />
          <Route path={routes.templates.path} element={<Templates />} />
          <Route path={routes.campaignBuilder.path} element={<Onboarding />} />
          <Route path={routes.settings.path} element={<Settings />} />
          <Route path={routes.billing.path} element={<Billing />} />
          <Route path={routes.bonus.path} element={<Bonus />} />
        </Route>
      </Route>
      <Route
        element={
          // route restriction handled here
          <AuthScreen>
            <AuthScreensLayout />
          </AuthScreen>
        }
      >
        <Route element={<Login />} path={routes.auth_login.path} />
      </Route>

      <Route element={<NotFound />} path="*" />
    </Routes>
  );
}
