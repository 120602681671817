import { Outlet } from "react-router-dom";
import Header from "./TopPart";
import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import SidebarContent from "./SidebarContent";

const drawerWidth = 260;
export default function PostLoginLayout() {
  const theme = useTheme();
  const isMdDown = useMediaQuery((_theme) => theme.breakpoints.down("md"));

  return (
    <div style={{ minHeight: "100vh" }}>
      {isMdDown ? (
        <Header />
      ) : (
        <Drawer
          variant="persistent"
          anchor={"left"}
          open={true}
          sx={{
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
        >
          <SidebarContent />
        </Drawer>
      )}

      <div style={{ marginLeft: !isMdDown ? drawerWidth : undefined }}>
        <Outlet />
      </div>
    </div>
  );
}
