import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/leads-share-link/get";
import useCallSdk from "../useCallSdk";

export function buildLeadsShareLinkGetQueryKey(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const leadsShareLinkId = _payload.leadsShareLinkId;
  let _queryKey: string[] = [];

  if (
    typeof workspaceId !== "undefined" &&
    workspaceId !== null &&
    workspaceId !== ""
  ) {
    _queryKey.push(String(workspaceId));
  }

  if (
    typeof leadsShareLinkId !== "undefined" &&
    leadsShareLinkId !== null &&
    leadsShareLinkId !== ""
  ) {
    _queryKey.push(String(leadsShareLinkId));
  }

  return ["leads-share-link:get", ..._queryKey];
}

export function useLeadsShareLinkGetQueryFn(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const leadsShareLinkId = _payload.leadsShareLinkId;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().leadsShareLinkGet({
      workspaceId,
      leadsShareLinkId,
    });
}

export type typeLeadsShareLinkGetHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useLeadsShareLinkGet(
  _payload: typePayload,
  _options?: typeLeadsShareLinkGetHookOptions
) {
  const workspaceId = _payload.workspaceId;
  const leadsShareLinkId = _payload.leadsShareLinkId;
  _options = _options || {};
  const _queryKey = buildLeadsShareLinkGetQueryKey({
    workspaceId,
    leadsShareLinkId,
  });
  const _queryFn = useLeadsShareLinkGetQueryFn({
    workspaceId,
    leadsShareLinkId,
  });

  return useQuery(_queryKey, _queryFn, _options);
}
