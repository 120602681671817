import { Grid } from "@mui/material";
import React from "react";
import TableBannedAccounts from "./TableBannedAccounts";

type Props = {};

export default function TabBannedAccounts({}: Props) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableBannedAccounts />
        </Grid>
      </Grid>
    </div>
  );
}
