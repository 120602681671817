import { WORKSPACE_ID } from "../../config";
import { StringParam, useQueryParams } from "use-query-params";
import { useQueryClient } from "@tanstack/react-query";
import {
  buildCampaignListQueryKey,
  useCampaignListQueryFn,
} from "../../utils/reactQueryHooks/campaign/useCampaignList";

export default function usePrefetchCampaigns() {
  const queryClient = useQueryClient();
  const [queryParams] = useQueryParams({
    [WORKSPACE_ID]: StringParam,
  });
  let workspaceId = queryParams[WORKSPACE_ID] || "";

  const campaignListQueryKey = buildCampaignListQueryKey({
    workspaceId,
    sortOrder: "desc",
  });
  const campaignListQueryFn = useCampaignListQueryFn({
    workspaceId,
    sortOrder: "desc",
  });

  return () => {
    queryClient.prefetchInfiniteQuery(
      campaignListQueryKey,
      () => campaignListQueryFn(),
      { staleTime: Infinity }
    );
  };
}
