import { ErrorOutlineRounded } from "@mui/icons-material";
import { Typography, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import useAdminSuspendDelete from "utils/reactQueryHooks/admin-suspend/useAdminSuspendDelete";

type Params = {};

export default function useConfirmUnBan({}: Params) {
  const theme = useTheme();
  const confirm = useConfirm();

  const snackbar = useSnackbar();

  const deleteBan = useAdminSuspendDelete({ doWaitForDependentQueries: true });

  const onUnBan = ({ value }: { value: string }) => {
    confirm({
      title: (
        <Typography
          component="div"
          style={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
          variant="h6"
        >
          <ErrorOutlineRounded />
          Remove this ban?
        </Typography>
      ),
      description: (
        <>
          Are you sure you want to un-ban <strong>{value}</strong> ?
        </>
      ),
      confirmationText: "Un-ban",
    })
      .then(async () => {
        const deleteResult = await deleteBan.mutateAsync({
          value,
        });
        if (!deleteResult.error) {
          snackbar.enqueueSnackbar(`Ban removed: ${value}`, {
            variant: "info",
          });
        }
      })
      .catch(() => console.log("#hjz6328903"));
  };

  return { onUnBan, isLoading: deleteBan.isLoading };
}
