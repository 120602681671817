import CallRoundedIcon from "@mui/icons-material/CallRounded";
import FacebookIcon from "@mui/icons-material/Facebook";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import { Button, Grid } from "@mui/material";
import { useJune } from "hooks/june/useJune";
import useActiveWorkspaceId from "hooks/useActiveWorkspaceId";

type Props = {};

export default function SidebarBottomButtons({}: Props) {
  const workspaceId = useActiveWorkspaceId();

  const { trackJuneEvent } = useJune();

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Button
          style={{ width: 210 }}
          color="inherit"
          href="https://firstsales.io/demo/"
          onClick={() => trackJuneEvent("book_demo_call_button_clicked", null)}
          target="_blank"
          rel="noopener"
          size="small"
          startIcon={<CallRoundedIcon />}
          variant="outlined"
        >
          Book a Demo call
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Button
          style={{ width: 210 }}
          color="inherit"
          href={`mailto:support@firstsales.io?subject=Need help with FirstSales. Workspace ID: ${workspaceId}`}
          target="_blank"
          onClick={() => trackJuneEvent("support_button_clicked", null)}
          rel="noopener"
          size="small"
          startIcon={<SupportAgentRoundedIcon />}
          variant="outlined"
        >
          Support
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Button
          style={{ width: 210 }}
          color="inherit"
          href="https://help.firstsales.io/"
          target="_blank"
          onClick={() => trackJuneEvent("knowledgebase_button_clicked", null)}
          rel="noopener"
          size="small"
          startIcon={<MenuBookRoundedIcon />}
          variant="outlined"
        >
          Knowledgebase
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Button
          style={{ width: 210 }}
          color="inherit"
          href="https://learn.firstsales.io/"
          onClick={() =>
            trackJuneEvent("cold_email_masterclass_button_clicked", null)
          }
          target="_blank"
          rel="noopener"
          size="small"
          startIcon={<SchoolRoundedIcon />}
          variant="outlined"
        >
          Cold Email Masterclass
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Button
          style={{ width: 210 }}
          color="inherit"
          href="https://www.facebook.com/groups/firstsalesio"
          target="_blank"
          onClick={() =>
            trackJuneEvent("join_our_community_button_clicked", null)
          }
          rel="noopener"
          size="small"
          startIcon={<FacebookIcon />}
          variant="outlined"
        >
          Join our community
        </Button>
      </Grid>
    </Grid>
  );
}
