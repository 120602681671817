import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {};

export type typeResultData = { percentOff: number; offerEndInSeconds: number };
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___userGetTrialDiscount(___options: typeOptions) {
  return async function ({}: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {};

    let schema = Joi.object({});

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___userGetTrialDiscount" failed even before calling the network.
api/private/sdk/user/get-trial-discount.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/user/get-trial-discount",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
