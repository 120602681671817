import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = { workspaceId: string; planItemId: string };

export type typeResultData = {
  workspaceId: string;
  planItemId: string;
  mailId: string;
  campaignId: string;
  contactId: string;
  willSendDate: string;
  type: "new_execution" | "follow_up";
  messageId?: string;
  mailMessageId?: string;
  doneAtMs?: number;
  mailSubject?: string;
  failCount: number;
  mailSentAt?: number;
  sequenceId?: string;
  stepId?: string;
  variantId?: string;
  bucket: "planned" | "executing" | "done" | "resuming" | "delayed" | "failed";
  createdAtMs: number;
  forDateTimeSlot: string;
  prevStepStepId?: string;
  prevStepVariantId?: string;
  prevStepSentAt?: string;
  prevPlanItemId?: string;
  replyToMessageId?: string;
  replyToSubject?: string;
  campaign: null | {
    isRunning: boolean;
    title: string;
  };
  mail: null | {
    isActive: boolean;
    isAuthorized: boolean;
    mailMatchingProvider: "gmail" | "zoho" | "microsoft" | "unknown";
    title: string;
  };
  contact: null | {
    firstName?: string;
    lastName?: string;
    email: string;
    bucket: "new" | "active" | "suspended";
    status: "warm" | "cold";
    lastEventAtMs: number;
    validatedAtMs: number;
    createdAtMs: number;
    tags: string[];
    primaryMx?: string;
    isDisposable?: boolean;
    mailProvider?: "unknown" | "zoho" | "microsoft" | "gmail";
  };
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___planItemGet(___options: typeOptions) {
  return async function ({
    workspaceId,
    planItemId,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      planItemId, // (required) (undefined === null | "") Joi.string().pattern(/^[a-zA-Z0-9-_]{15,100}$/)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (planItemId === null || planItemId === "") {
      delete ___payload?.["planItemId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      planItemId: Joi.string()
        .pattern(/^[a-zA-Z0-9-_]{15,100}$/)
        .required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___planItemGet" failed even before calling the network.
api/private/sdk/plan-item/get.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/plan-item/get",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
