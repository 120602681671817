import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/user/get-trial-discount";
import useCallSdk from "../useCallSdk";

export function buildUserGetTrialDiscountQueryKey(_payload?: typePayload) {
  let _queryKey: string[] = [];

  return ["user:get-trial-discount", ..._queryKey];
}

export function useUserGetTrialDiscountQueryFn(_payload?: typePayload) {
  const _sdk = useCallSdk();

  return () => _sdk.callSdk().userGetTrialDiscount({});
}

export type typeUserGetTrialDiscountHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useUserGetTrialDiscount(
  _payload?: typePayload,
  _options?: typeUserGetTrialDiscountHookOptions
) {
  _options = _options || {};
  const _queryKey = buildUserGetTrialDiscountQueryKey({});
  const _queryFn = useUserGetTrialDiscountQueryFn({});

  return useQuery(_queryKey, _queryFn, _options);
}
