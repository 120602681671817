import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = { workspaceId: string };

export type typeResultData = {
  wsCheckMailRestartedAt: number;
  wsContactsSegmentationRestartedAt: number;
  wsContactsValidationRestartedAt: number;
  wsDomainsValidationRestartedAt: number;
  wsPlanExecutionsRestartedAt: number;
  wsExecutePlanRestartedAt: number;
  segmentsUpdatedAt: number;
  workspaceSubscriptions: {
    aiCredits30d: string;
    contactsCount: string;
    emailSentCount30d: string;
    liveChatEnabled: string;
    mailAccSendCount: string;
    mailAccTrackCount: string;
    segmentsCount: string;
    subscriptionOverview: {
      subscriptionItem: {
        quantity?: number;
        plan: {
          metadata: {
            aiCredits30d: string;
            contactsCount: string;
            emailSentCount30d: string;
            liveChatEnabled: string;
            mailAccSendCount: string;
            mailAccTrackCount: string;
            segmentsCount: string;
          } | null;
          amount: number | null;
          interval: "day" | "month" | "week" | "year";
          currency: string;
          active: boolean;
        };
      };
      subscription: {
        id: string;
        current_period_start: number;
        current_period_end: number;
        cancel_at_period_end: boolean;
        cancel_at: number | null;
        status:
          | "active"
          | "canceled"
          | "incomplete"
          | "incomplete_expired"
          | "past_due"
          | "paused"
          | "trialing"
          | "unpaid";
        trial_end: number | null;
        trial_start: number | null;
      };
    };
  }[];
  workersTiming: {
    timeout_stuck_plan_items: {
      everySeconds: number;
    };
    ws_resume_delayed_items: {
      everySeconds: number;
    };
    ws_execute_plan: {
      reprocessEveryCampaignEverySeconds: number;
      everySeconds: number;
    };
    ws_plan_executions: {
      reprocessEveryCampaignEverySeconds: number;
      everySeconds: number;
    };
    ws_check_mail: {
      reprocessEveryMailboxEverySeconds: number;
      everySeconds: number;
    };
    ws_domains_validation: {
      reProcessEveryItemEverySeconds: number;
      everySeconds: number;
    };
    ws_contacts_segmentation: {
      reProcessEveryContactEverySeconds: number;
      everySeconds: number;
    };
    ws_contacts_validation: {
      reProcessEveryContactEverySeconds: number;
      everySeconds: number;
    };
  };
  wsContactsSegmentationFinishedAt: number;
  wsContactsSegmentationTookSeconds: number;
  totalContactsSuspended: number;
  totalContacts: number;
  totalRunningCampaigns: number;
  totalCampaigns: number;
  totalContactsWarm: number;
  totalMembers: number;
  totalActiveMailAccounts: number;
  totalActiveMailAccountsForSending: number;
  totalActiveMailAccountsForTracking: number;
  totalActiveDomains: number;
  totalMailAccounts: number;
  totalDomains: number;
  totalSegments: number;
  userSegments: number;
  totalLists: number;
  emailSendCreditsBase: number;
  emailSendCreditsRefiledAt: number;
  aiCreditsBase: number;
  aiCreditsAddon: number;
  aiCreditsRefiledAt: number;
  aiCredits30d: number;
  emailSentCount30d: number;
  mailAccSendCount: number;
  mailAccTrackCount: number;
  contactsCount: number;
  segmentsCount: number;
  softLimitCampaignsCount: number;
  softLimitContactsCount: number;
  softLimitSegmentsCount: number;
  newestContactId: string;
  liveChatEnabled: "yes" | "no";
  countryCode: string;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___workspaceGetMetaStats(___options: typeOptions) {
  return async function ({ workspaceId }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___workspaceGetMetaStats" failed even before calling the network.
api/private/sdk/workspace/get-meta-stats.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/workspace/get-meta-stats",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
