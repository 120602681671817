import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  typePayload,
  typeResult,
} from "../../sdk/prospecting-bucket/have-i-used-trial-credits";
import useCallSdk from "../useCallSdk";

export function buildProspectingBucketHaveIUsedTrialCreditsQueryKey(
  _payload?: typePayload
) {
  let _queryKey: string[] = [];

  return ["prospecting-bucket:have-i-used-trial-credits", ..._queryKey];
}

export function useProspectingBucketHaveIUsedTrialCreditsQueryFn(
  _payload?: typePayload
) {
  const _sdk = useCallSdk();

  return () => _sdk.callSdk().prospectingBucketHaveIUsedTrialCredits({});
}

export type typeProspectingBucketHaveIUsedTrialCreditsHookOptions =
  UseQueryOptions<typeResult, unknown, typeResult, string[]>;

export default function useProspectingBucketHaveIUsedTrialCredits(
  _payload?: typePayload,
  _options?: typeProspectingBucketHaveIUsedTrialCreditsHookOptions
) {
  _options = _options || {};
  const _queryKey = buildProspectingBucketHaveIUsedTrialCreditsQueryKey({});
  const _queryFn = useProspectingBucketHaveIUsedTrialCreditsQueryFn({});

  return useQuery(_queryKey, _queryFn, _options);
}
