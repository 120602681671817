import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/workspace/get-meta-stats";
import useCallSdk from "../useCallSdk";

export function buildWorkspaceGetMetaStatsQueryKey(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  let _queryKey: string[] = [];

  if (
    typeof workspaceId !== "undefined" &&
    workspaceId !== null &&
    workspaceId !== ""
  ) {
    _queryKey.push(String(workspaceId));
  }

  return ["workspace:get-meta-stats", ..._queryKey];
}

export function useWorkspaceGetMetaStatsQueryFn(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().workspaceGetMetaStats({
      workspaceId,
    });
}

export type typeWorkspaceGetMetaStatsHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useWorkspaceGetMetaStats(
  _payload: typePayload,
  _options?: typeWorkspaceGetMetaStatsHookOptions
) {
  const workspaceId = _payload.workspaceId;
  _options = _options || {};
  const _queryKey = buildWorkspaceGetMetaStatsQueryKey({ workspaceId });
  const _queryFn = useWorkspaceGetMetaStatsQueryFn({ workspaceId });

  return useQuery(_queryKey, _queryFn, _options);
}
