import { ErrorOutlineRounded } from "@mui/icons-material";
import { Typography, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import useAdminSuspendCreate from "utils/reactQueryHooks/admin-suspend/useAdminSuspendCreate";

type Params = {};

export default function useConfirmCreateBan({}: Params) {
  const theme = useTheme();
  const confirm = useConfirm();

  const snackbar = useSnackbar();

  const createBan = useAdminSuspendCreate({ doWaitForDependentQueries: true });

  const onCreateBan = ({ value }: { value: string }) => {
    confirm({
      title: (
        <Typography
          component="div"
          style={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
          }}
          variant="h6"
        >
          <ErrorOutlineRounded />
          Create ban?
        </Typography>
      ),
      description: (
        <>
          Are you sure you want to ban <strong>{value}</strong> ?
        </>
      ),
      confirmationText: "Ban",
    })
      .then(async () => {
        const deleteResult = await createBan.mutateAsync({
          value,
        });
        if (!deleteResult.error) {
          snackbar.enqueueSnackbar(`Ban created: ${value}`, {
            variant: "info",
          });
        }
      })
      .catch(() => console.log("#gyj32893287436"));
  };

  return { onCreateBan, isLoading: createBan.isLoading };
}
