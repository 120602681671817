import { Grid } from "@mui/material";
import { routes } from "config";
import useActiveWorkspaceId from "hooks/useActiveWorkspaceId";
import React from "react";
import useWorkspaceGetMetaStats from "utils/reactQueryHooks/workspace/useWorkspaceGetMetaStats";
import StaffOnly from "../../../utils/StaffOnly";
import MenuItemClaimProspectorCredits from "./MenuItemClaimProspectorCredits";
import SidebarMenuItem from "./SidebarMenuItem";
import Unreleased from "components/partials/Unreleased";

type RouteKey = keyof typeof routes;
export type Route = (typeof routes)[RouteKey];

type Props = {};

export default function SidebarMenuItems({}: Props) {
  const workspaceId = useActiveWorkspaceId();

  const menuItems: React.ReactNode[] = [];

  const stats = useWorkspaceGetMetaStats(
    { workspaceId },
    {
      enabled: typeof workspaceId === "string", // needed because sidebar renders even outside of workspace
      refetchInterval: 5000,
    }
  );

  if (workspaceId) {
    for (const [, route] of Object.entries(routes)) {
      if (route.isInTopMenu === true) {
        if (route.path === "/admin") {
          menuItems.push(
            <StaffOnly identifier="211212916765" key={route.path}>
              <SidebarMenuItem stats={stats} route={route} key={route.path} />
            </StaffOnly>
          );
        } else if (route.path === "/firstsales-prospects") {
          menuItems.push(
            <SidebarMenuItem stats={stats} route={route} key={route.path} />
          );
          menuItems.push(
            <MenuItemClaimProspectorCredits key="claim-prospector-credits" />
          );
        } else {
          menuItems.push(
            <SidebarMenuItem stats={stats} route={route} key={route.path} />
          );
        }
      }
    }
  }

  return (
    <Grid container item xs={12} spacing={1}>
      {menuItems.map((menuItem, i) => (
        <React.Fragment key={i}>{menuItem}</React.Fragment>
      ))}
    </Grid>
  );
}
