import { Container } from "@mui/material";
import { ADMIN_CHECK_IF_BANNED_OPEN } from "queryParams";
import { BooleanParam, useQueryParams } from "use-query-params";
import TabsAdmin from "./TabsAdmin";
import CheckIfBanned from "./TabBannedAccounts/CheckIfBannedView";

export default function AdminLayout() {
  const [queryParams] = useQueryParams({
    [ADMIN_CHECK_IF_BANNED_OPEN]: BooleanParam,
  });

  let activeView: React.ReactNode = null;

  if (queryParams[ADMIN_CHECK_IF_BANNED_OPEN]) {
    activeView = <CheckIfBanned />;
  } else {
    activeView = <TabsAdmin />;
  }

  return <Container maxWidth="md">{activeView}</Container>;
}
