import { useTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

export default function Unreleased({
  children,
  identifier,
}: {
  children: ReactNode;
  identifier?: string;
}) {
  let isVisible = localStorage.getItem("i43itejfdgg") === "142grw4dga";
  /*
  localStorage.setItem("i43itejfdgg", "142grw4dga");
*/

  identifier = identifier || "";
  const theme = useTheme();
  if (isVisible) {
    return (
      <div
        style={{
          border: `1px solid ${red[500]}`,
          borderRadius: theme.shape.borderRadius,
          width: "100%",
        }}
      >
        <div
          style={{
            background: red[500],
            padding: theme.spacing(1),
            textAlign: "center",
          }}
        >
          <Typography variant={"body1"} style={{ color: "#FFFFFF" }}>
            Not Released {identifier}
          </Typography>
        </div>
        <div style={{ padding: theme.spacing(1) }}>{children}</div>
      </div>
    );
  }

  return null;
}
