import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  perPage?: number;
  lastKnown?: string;
};

export type typeResultData = {
  Items: {
    tag: string;
  }[];
  lastKnown: string;
  isThereMore: boolean;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___tagsList(___options: typeOptions) {
  return async function ({
    workspaceId,
    perPage,
    lastKnown,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      perPage, // (optional) (undefined === null) Joi.number().valid(5, 15, 25, 50, 100, 200, 500, 1000)
      lastKnown, // (optional) (undefined === null | "") Joi.string().min(1).max(100)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (perPage === null) {
      delete ___payload?.["perPage"];
    }

    if (lastKnown === null || lastKnown === "") {
      delete ___payload?.["lastKnown"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      perPage: Joi.number().valid(5, 15, 25, 50, 100, 200, 500, 1000),
      lastKnown: Joi.string().min(1).max(100),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___tagsList" failed even before calling the network.
api/private/sdk/tags/list.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/tags/list",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
