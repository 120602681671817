import { Grid, Typography } from "@mui/material";
import SelectWorkspace from "components/PublicPages/ProspectorPlansPublicPage/StepWorkspaceSelection/SelectWorkspace";
import React from "react";
import ButtonCreateWorkspaceForLeadsListShare from "../ButtonCreateWorkspaceForLeadsListShare";
import { LoadingButton } from "@mui/lab";
import { routes } from "config";
import { useNavigate } from "react-router-dom";
import { typeResultData } from "utils/sdk/leads-share-link/get";
import useTimeLeftTimer from "hooks/useTimeLeftTimer/useTimeLeftTimer";
import LeadsListDescription from "./LeadsListDescription";

type Props = {
  shareLinkGet: typeResultData;
};

export default function AddLeadsListToOwnWorkspace({ shareLinkGet }: Props) {
  const navigate = useNavigate();
  const [selectedWorkspace, setSelectedWorkspace] = React.useState("");

  const { timerText } = useTimeLeftTimer({
    timerEndsAt: shareLinkGet.expireAt || 0,
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LeadsListDescription shareLinkGet={shareLinkGet} />
      </Grid>

      {shareLinkGet.expireAt ? (
        <Grid item xs={12}>
          <Typography
            color={"text.secondary"}
            textAlign={"center"}
            variant="body2"
          >
            Share link expires in {timerText}
          </Typography>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <Typography textAlign={"center"} color={"text.secondary"}>
          Please select a workspace to add leads list to
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <SelectWorkspace
          onChange={(value) => setSelectedWorkspace(value)}
          value={selectedWorkspace}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography textAlign={"center"} variant="h6">
          OR
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ButtonCreateWorkspaceForLeadsListShare />
      </Grid>

      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} md={"auto"}>
          <LoadingButton
            color="error"
            fullWidth
            onClick={() => navigate(routes.workspaces.path)}
          >
            Cancel
          </LoadingButton>
        </Grid>
        <Grid item xs={true} />
        <Grid item xs={12} md={"auto"}>
          <LoadingButton
            fullWidth
            // TODO:
            // loading={acceptInvitationUrl.isLoading}
            disabled={selectedWorkspace === ""}
            // onClick={onAcceptInvitation}
            variant="contained"
          >
            Add to workspace
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
