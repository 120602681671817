import React from "react";
import useWorkspaceGetMetaStats from "utils/reactQueryHooks/workspace/useWorkspaceGetMetaStats";
import { routes } from "config";
import { CircularProgress, useTheme } from "@mui/material";
import { Route } from "..";
import SidebarStatsContacts from "./SidebarStatsContacts";
import SidebarStatsCampaigns from "./SidebarStatsCampaigns";

type Props = {
  route: Route;
  stats: ReturnType<typeof useWorkspaceGetMetaStats>;
};

export default function SidebarMenuItemStats({ route, stats }: Props) {
  const theme = useTheme();

  const areStatsLoaded = stats.isFetched;

  const sidebarStatsSpinner = (
    <CircularProgress
      size={10}
      style={{ color: theme.palette.text.secondary }}
    />
  );

  let statsElement: React.ReactNode = null;
  if (route.path === routes.contacts.path) {
    statsElement = areStatsLoaded ? (
      <SidebarStatsContacts stats={stats} />
    ) : (
      sidebarStatsSpinner
    );
  } else if (route.path === routes.campaigns.path) {
    statsElement = areStatsLoaded ? (
      <SidebarStatsCampaigns stats={stats} />
    ) : (
      sidebarStatsSpinner
    );
  }

  return statsElement;
}
