import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  tenant?: string;
  type:
    | "CREATE_EMAIL_COPY_WITH_CTA"
    | "CREATE_GREETING"
    | "CREATE_ICE_BREAKER"
    | "CREATE_PRE_TEXT"
    | "CREATE_SUBJECT";
  payload?: string;
};

export type typeResultData = { jobId: string };
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___jobCreate(___options: typeOptions) {
  return async function ({
    workspaceId,
    tenant,
    type,
    payload,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      tenant, // (optional) (undefined === null | "") Joi.string().min(0).max(300)
      type, // (required) (undefined === null) Joi.string().valid("CREATE_SUBJECT","CREATE_PRE_TEXT","CREATE_GREETING","CREATE_ICE_BREAKER","CREATE_EMAIL_COPY_WITH_CTA")
      payload, // (optional) (undefined === null) Joi.object()
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (tenant === null || tenant === "") {
      delete ___payload?.["tenant"];
    }

    if (type === null) {
      delete ___payload?.["type"];
    }

    if (payload === null) {
      delete ___payload?.["payload"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      tenant: Joi.string().min(0).max(300),
      type: Joi.string()
        .valid(
          "CREATE_SUBJECT",
          "CREATE_PRE_TEXT",
          "CREATE_GREETING",
          "CREATE_ICE_BREAKER",
          "CREATE_EMAIL_COPY_WITH_CTA"
        )
        .required(),
      payload: Joi.object(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___jobCreate" failed even before calling the network.
api/private/sdk/job/create.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/job/create",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
