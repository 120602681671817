import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  mailId: string;
  title?: string;
  mailAuthProvider: string;
  authDetails: Object;
  mailLimits: any;
};

export type typeResultData = {
  workspaceId: string;
  mailId: string;
  mailAuthProvider?: "gmail" | "zoho" | "microsoft365" | "smtp";
  serviceMailId: string;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___mailAuthorize(___options: typeOptions) {
  return async function ({
    workspaceId,
    mailId,
    title,
    mailAuthProvider,
    authDetails,
    mailLimits,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      mailId, // (required) (undefined === null | "") Joi.string().pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
      title, // (optional) (undefined === null | "") Joi.string().min(0).max(200)
      mailAuthProvider, // (required) (undefined === null | "") Joi.string().valid('gmail', 'microsoft365', 'zoho', 'smtp')
      authDetails, // (required) (undefined === null | "") Joi.object().pattern(Joi.string().min(1).max(100),Joi.string().trim().min(0).max(1000))
      mailLimits, // (required) (undefined === null) Joi.array().max(10).items(Joi.object({minutes: Joi.number().min(0),maxEmailsSent: Joi.number().min(0),}))
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (mailId === null || mailId === "") {
      delete ___payload?.["mailId"];
    }

    if (title === null || title === "") {
      delete ___payload?.["title"];
    }

    if (mailAuthProvider === null || mailAuthProvider === "") {
      delete ___payload?.["mailAuthProvider"];
    }

    if (authDetails === null || authDetails === "") {
      delete ___payload?.["authDetails"];
    }

    if (mailLimits === null) {
      delete ___payload?.["mailLimits"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      mailId: Joi.string()
        .pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
      title: Joi.string().min(0).max(200),
      mailAuthProvider: Joi.string()
        .valid("gmail", "microsoft365", "zoho", "smtp")
        .required(),
      authDetails: Joi.object()
        .pattern(
          Joi.string().min(1).max(100),
          Joi.string().trim().min(0).max(1000)
        )
        .required(),
      mailLimits: Joi.array()
        .max(10)
        .items(
          Joi.object({
            minutes: Joi.number().min(0),
            maxEmailsSent: Joi.number().min(0),
          })
        )
        .required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___mailAuthorize" failed even before calling the network.
api/private/sdk/mail/authorize.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/mail/authorize",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
