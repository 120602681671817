import { TableCell, TableRow, Typography } from "@mui/material";
import useAdminSuspendGet from "utils/reactQueryHooks/admin-suspend/useAdminSuspendGet";
import SingleBanCheckActionButton from "./SingleBanCheckActionButton";
import SingleBanCheckStatus from "./SingleBanCheckStatus";

type Props = { value: string };

export default function SingleBanCheckResult({ value }: Props) {
  const suspendGet = useAdminSuspendGet(
    {
      value,
    },
    {
      // to avoid snack-bars on error, while we handle error by showing UI
      onSettled: () => {},
    }
  );

  return (
    <TableRow hover={false}>
      <TableCell>
        <Typography variant={"body2"} color={"text.secondary"}>
          <strong>{value[0] === "@" ? "Domain" : "Email"}</strong>: {value}
        </Typography>

        <Typography variant={"body2"} color={"text.secondary"}>
          <strong>Status</strong>:{" "}
          <SingleBanCheckStatus suspendGet={suspendGet} />
        </Typography>
      </TableCell>

      <TableCell align="right">
        <SingleBanCheckActionButton suspendGet={suspendGet} value={value} />
      </TableCell>
    </TableRow>
  );
}
