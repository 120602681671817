import { useTheme } from "@mui/material";
import { Typography } from "@mui/material";

export default function NotFound() {
  const theme = useTheme();

  return (
    <>
      <Typography
        color={"error"}
        style={{ margin: theme.spacing(5, 0) }}
        textAlign="center"
        variant="h2"
      >
        404, sorry this page does not exist
      </Typography>
    </>
  );
}
