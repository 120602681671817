import { Box, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import HelloBar from "./HelloBar";
import useWorkspaceGet from "../utils/reactQueryHooks/workspace/useWorkspaceGet";
import useActiveWorkspaceId from "../hooks/useActiveWorkspaceId";
import { routes } from "config";

export default function PostLoginSectionLayout() {
  const theme = useTheme();
  const workspaceId = useActiveWorkspaceId();
  const workspace = useWorkspaceGet({ workspaceId });
  const location = useLocation();
  const navigate = useNavigate();
  const isPaid = workspace?.data?.data?.isPaid === true;
  const doNavigateToBilling =
    isPaid === false &&
    location.pathname.indexOf("/billing") === -1 &&
    location.pathname.indexOf("/settings") === -1 &&
    location.pathname.indexOf(routes.firstSalesProspects.path);

  useEffect(() => {
    if (doNavigateToBilling) {
      navigate(`/billing?workspace-id=${workspaceId}`);
    }
  }, [doNavigateToBilling]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: theme.spacing(0, 0, 20, 0),
        }}
      >
        <HelloBar />
        <div style={{ padding: theme.spacing(0, 0.5, 0) }}>
          <Outlet />
        </div>
      </Box>
    </>
  );
}
