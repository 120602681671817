import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  tenant: string;
  attribute: string;
  perPage?: number;
  lastKnown?: any;
  searchQuery?: string;
};

export type typeResultData = {
  Items: {
    value: string;
  }[];
  lastKnown: string;
  isThereMore: boolean;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___analyticsCustomerAttributeValuesList(
  ___options: typeOptions
) {
  return async function ({
    workspaceId,
    tenant,
    attribute,
    perPage,
    lastKnown,
    searchQuery,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      tenant, // (required) (undefined === null | "") Joi.string().min(0).max(300)
      attribute, // (required) (undefined === null | "") Joi.string().min(0).max(300)
      perPage, // (optional) (undefined === null) Joi.number().valid(5, 15, 25, 50, 100, 200)
      lastKnown, // (optional) (undefined === null | "") Joi.alternatives(Joi.string().min(1).max(100), Joi.object().min(2).max(4))
      searchQuery, // (optional) (undefined === null | "") Joi.string().min(0).max(300)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (tenant === null || tenant === "") {
      delete ___payload?.["tenant"];
    }

    if (attribute === null || attribute === "") {
      delete ___payload?.["attribute"];
    }

    if (perPage === null) {
      delete ___payload?.["perPage"];
    }

    if (lastKnown === null || lastKnown === "") {
      delete ___payload?.["lastKnown"];
    }

    if (searchQuery === null || searchQuery === "") {
      delete ___payload?.["searchQuery"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      tenant: Joi.string().min(0).max(300).required(),
      attribute: Joi.string().min(0).max(300).required(),
      perPage: Joi.number().valid(5, 15, 25, 50, 100, 200),
      lastKnown: Joi.alternatives(
        Joi.string().min(1).max(100),
        Joi.object().min(2).max(4)
      ),
      searchQuery: Joi.string().min(0).max(300),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___analyticsCustomerAttributeValuesList" failed even before calling the network.
api/private/sdk/analytics/customer-attribute-values-list.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/analytics/customer-attribute-values-list",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
