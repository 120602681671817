import fetch from "cross-fetch";
  
  interface Payload {
  httpMethod:
    | "POST"
    | "GET"
    | "UPDATE"
    | "HEAD"
    | "PUT"
    | "DELETE"
    | "CONNECT"
    | "OPTIONS"
    | "TRACE"
    | "PATCH";
  endpoint: string;
  payload: Object;
  options: {
    headers?: { [key: string]: string };
    apiHost?: string;
    isDebugMode?: boolean;
  };
}

export default async function call({
  httpMethod,
  endpoint,
  payload,
  options,
}: Payload) {
  if (!options.apiHost) {
    console.error(`#202233493010506 sdk apiHost is not set: `, options);
  }

  const url = `${options.apiHost}${endpoint}`;

  let result;
  let rawResult;

  let fetchOptions: RequestInit = {
    method: httpMethod,
    mode: "cors",
    cache: "no-cache",
    headers: options?.headers,
    redirect: "follow",
  };

  try {
    if (options?.headers?.["content-type"] === "application/json") {
      if (typeof payload !== "string") {
        payload = JSON.stringify(payload);
      }
    }

    if (payload) {
      fetchOptions.body = payload as BodyInit;
    }

    rawResult = await fetch(url, fetchOptions);
    if (!rawResult.ok) {
      console.error(
        `#202228718345782 Failed to call API. Network was not OK.`
      );
    }
    try {
      if (options?.headers?.["accept"] === "application/json") {
        result = rawResult.json();
      } else {
        result = rawResult.blob();
      }
    } catch (e) {
      console.error(
        `#2022292194346183 Failed to parse result: \n`,
         rawResult
       );
      result = rawResult;
    }
  } catch (e) {
    console.error(`#20222871824027 Failed to call API: `, e);
    result = Promise.reject({
      // @ts-ignore
      error: { code: "INTERNAL_ERROR", message: e?.message },
      data: null,
    });
  }

  return options.isDebugMode === true
    ? {
        httpMethod,
        endpoint,
        payload,
        options,
        fetchOptions,
        rawResult,
        result,
      }
    : result;
}
