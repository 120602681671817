import { WORKSPACE_ID } from "../../config";
import { StringParam, useQueryParams } from "use-query-params";
import { useQueryClient } from "@tanstack/react-query";
import {
  buildContactListQueryKey,
  useContactListQueryFn,
} from "../../utils/reactQueryHooks/contact/useContactList";
import useActiveWorkspaceId from "../useActiveWorkspaceId";

export default function usePrefetchContacts(payload?: { workspaceId: string }) {
  const queryClient = useQueryClient();

  const activeWorkspaceId = useActiveWorkspaceId();

  let workspaceId = activeWorkspaceId || payload?.workspaceId || "";

  const contactListQueryKey = buildContactListQueryKey({
    workspaceId,
    perPage: 25,
  });
  const contactListQueryFn = useContactListQueryFn({ workspaceId });

  return () => {
    queryClient.prefetchInfiniteQuery(
      contactListQueryKey,
      () => contactListQueryFn(),
      { staleTime: Infinity }
    );
  };
}
