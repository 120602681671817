import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/admin-suspend/get";
import useCallSdk from "../useCallSdk";

export function buildAdminSuspendGetQueryKey(_payload: typePayload) {
  const value = _payload.value;
  let _queryKey: string[] = [];

  if (typeof value !== "undefined" && value !== null && value !== "") {
    _queryKey.push(String(value));
  }

  return ["admin-suspend:get", ..._queryKey];
}

export function useAdminSuspendGetQueryFn(_payload: typePayload) {
  const value = _payload.value;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().adminSuspendGet({
      value,
    });
}

export type typeAdminSuspendGetHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useAdminSuspendGet(
  _payload: typePayload,
  _options?: typeAdminSuspendGetHookOptions
) {
  const value = _payload.value;
  _options = _options || {};
  const _queryKey = buildAdminSuspendGetQueryKey({ value });
  const _queryFn = useAdminSuspendGetQueryFn({ value });

  return useQuery(_queryKey, _queryFn, _options);
}
