import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  invitation: string;
  firstName: string;
  lastName?: string;
  workspaceId: string;
};

export type typeResultData = {
  workspaceId: string;
  userId: string;
  workspaceTitle: string;
  firstName: string;
  lastName?: string;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___teamAcceptInvitationUrl(___options: typeOptions) {
  return async function ({
    invitation,
    firstName,
    lastName,
    workspaceId,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      invitation, // (required) (undefined === null | "") Joi.string().min(0).max(3000)
      firstName, // (required) (undefined === null | "") Joi.string().min(0).max(200)
      lastName, // (optional) (undefined === null | "") Joi.string().min(0).max(200)
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
    };

    if (invitation === null || invitation === "") {
      delete ___payload?.["invitation"];
    }

    if (firstName === null || firstName === "") {
      delete ___payload?.["firstName"];
    }

    if (lastName === null || lastName === "") {
      delete ___payload?.["lastName"];
    }

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }
    let schema = Joi.object({
      invitation: Joi.string().min(0).max(3000).required(),
      firstName: Joi.string().min(0).max(200).required(),
      lastName: Joi.string().min(0).max(200),
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___teamAcceptInvitationUrl" failed even before calling the network.
api/private/sdk/team/accept-invitation-url.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/team/accept-invitation-url",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
