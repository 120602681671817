import { Grid, Paper, Table, TableBody, TableContainer } from "@mui/material";
import ButtonLoadMore from "components/partials/ButtonLoadMore";
import LoadingAndEmptyState from "components/partials/LoadingAndEmptyState";
import React from "react";
import { useAdminSuspendListInfinity } from "utils/reactQueryHooks/admin-suspend/useAdminSuspendList";
import TableRowSingleBan from "./TableRowSingleBan";
import ButtonRefresh from "components/partials/ButtonRefresh";
import ButtonsBanViewActions from "../ButtonsBanViewActions";

type Props = {};

export default function TableBannedAccounts({}: Props) {
  const list = useAdminSuspendListInfinity({});

  const rows: React.ReactNode[] = [];
  list.data?.pages?.forEach((page, pageIndex) => {
    page?.data?.Items?.forEach((ban, itemIndex) => {
      rows.push(<TableRowSingleBan key={pageIndex + itemIndex} ban={ban} />);
    });
  });
  const itemsCount = rows.length;

  return (
    <Grid container spacing={2}>
      <Grid
        container
        item
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item>
          <ButtonsBanViewActions />
        </Grid>

        <Grid item>
          <ButtonRefresh
            onClick={list.refetch}
            isLoading={list.isFetching}
            tooltipText={"Refresh bans list"}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper} variant="outlined">
          <Table sx={{ width: "100%" }} size="small">
            <TableBody>{rows}</TableBody>
          </Table>

          <LoadingAndEmptyState
            emptyText="No bans yet"
            isLoading={list.isLoading}
            itemsCount={itemsCount}
            loadingText="Loading bans..."
          />
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <ButtonLoadMore infiniteQuery={list} tableItemsCount={itemsCount} />
      </Grid>
    </Grid>
  );
}
