import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  webhookId: string;
  webhookCallId: string;
};

export type typeResultData = {
  webhookId: string;
  webhookCallId: string;
  type: "pending" | "success" | "error";
  createdAtMs: number;
  payload: any;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___webhookCallGet(___options: typeOptions) {
  return async function ({
    workspaceId,
    webhookId,
    webhookCallId,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      webhookId, // (required) (undefined === null | "") Joi.string().min(0).max(3000)
      webhookCallId, // (required) (undefined === null | "") Joi.string().min(0).max(3000)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (webhookId === null || webhookId === "") {
      delete ___payload?.["webhookId"];
    }

    if (webhookCallId === null || webhookCallId === "") {
      delete ___payload?.["webhookCallId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      webhookId: Joi.string().min(0).max(3000).required(),
      webhookCallId: Joi.string().min(0).max(3000).required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___webhookCallGet" failed even before calling the network.
api/private/sdk/webhook-call/get.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/webhook-call/get",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
