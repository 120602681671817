import { useTheme } from "@mui/material";
import React from "react";
import useAdminSuspendGet from "utils/reactQueryHooks/admin-suspend/useAdminSuspendGet";

type Props = {
  suspendGet: ReturnType<typeof useAdminSuspendGet>;
};

export default function SingleBanCheckStatus({ suspendGet }: Props) {
  const theme = useTheme();

  const isBanned = suspendGet.data?.data?.value;

  let status: React.ReactNode;
  if (suspendGet.isLoading) {
    status = <span>checking...</span>;
  } else if (isBanned) {
    status = <span style={{ color: theme.palette.error.main }}>Banned</span>;
  } else {
    status = (
      <span style={{ color: theme.palette.success.main }}>Not banned</span>
    );
  }

  return <>{status}</>;
}
