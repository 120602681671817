import moment from "moment";
import Cookies from "js-cookie";
import { ACCESS_TOKEN, ACCESS_TOKEN_EXPIRES_AT } from "../../../../config";
import getCookieConfig from "./getCookieConfig";
import { UAParser } from "ua-parser-js";
import getValidSemverEnv from "../../../reactQueryHooks/getValidSemverEnv";

export default function setAllAuthCookies({
  accessToken,
  accessTokenExpiresAt,
}: {
  accessToken: string;
  accessTokenExpiresAt: number;
}) {
  const cookieConfig = getCookieConfig();

  const parser = new UAParser();
  const parserResult = parser.getResult();
  const environment = [
    parserResult.browser.name,
    parserResult.device.model,
    parserResult.device.type,
    parserResult.device.vendor,
    parserResult.os.name,
    parserResult.os.version,
    parserResult.cpu.architecture,
  ]
    .filter((_env) => Boolean(_env))
    .join(" ");

  let envVersion = getValidSemverEnv(navigator.userAgent);

  // used by Chrome extension
  Cookies.set("_tm-environment", environment, {
    path: cookieConfig.path,
    domain: cookieConfig.domain,
  });

  // used by Chrome extension
  Cookies.set("_tm-environment-version", envVersion, {
    path: cookieConfig.path,
    domain: cookieConfig.domain,
  });

  Cookies.set(ACCESS_TOKEN, accessToken, {
    path: cookieConfig.path,
    domain: cookieConfig.domain,
    expires: moment.unix(accessTokenExpiresAt).toDate(),
  });
  Cookies.set(ACCESS_TOKEN_EXPIRES_AT, String(accessTokenExpiresAt), {
    path: cookieConfig.path,
    domain: cookieConfig.domain,
    expires: moment.unix(accessTokenExpiresAt).toDate(),
  });
}
