import { useQueryClient } from "@tanstack/react-query";
import {
  buildWorkspaceListQueryKey,
  useWorkspaceListQueryFn,
} from "../../utils/reactQueryHooks/workspace/useWorkspaceList";

export default function usePrefetchWorkspaces() {
  const queryClient = useQueryClient();

  const workspaceListQueryKey = buildWorkspaceListQueryKey({ perPage: 1000 });
  const workspaceListQueryFn = useWorkspaceListQueryFn({ perPage: 1000 });

  return () => {
    queryClient.prefetchQuery(
      workspaceListQueryKey,
      () => workspaceListQueryFn(),
      { staleTime: Infinity }
    );
  };
}
