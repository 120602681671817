import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  isActive: boolean;
  url: string;
  filters: {
    campaignIds: string[] | "all";
    events: (
      | "C_EMAIL_SENT"
      | "C_EMAIL_OPENED"
      | "C_EMAIL_OPENED_ALL"
      | "C_EMAIL_CLICKED"
      | "C_EMAIL_CLICKED_ALL"
      | "C_EMAIL_REPLIED"
      | "C_EMAIL_BOUNCED"
      | "C_EMAIL_UNSUBSCRIBED"
    )[];
  }[];
  note?: string;
  headers?: {
    key: string;
    value?: string;
  }[];
  body?: string;
  httpMethod?: "GET" | "POST" | "DELETE" | "PATCH" | "PUT";
};

export type typeResultData = { webhookId: string };
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___webhookCreate(___options: typeOptions) {
  return async function ({
    workspaceId,
    isActive,
    url,
    filters,
    note,
    headers,
    body,
    httpMethod,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      isActive, // (required) (undefined === null) Joi.boolean()
      url, // (required) (undefined === null | "") Joi.string().min(0).max(3000)
      filters, // (required) (undefined === null) Joi.array().items(   Joi.object({     campaignIds: Joi.alternatives()       .try(         Joi.array()           .items(Joi.string().pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/))           .min(1)           .max(10000),         Joi.string().valid("all")       )       .required(),     events: Joi.alternatives().try(       Joi.array()         .items(           Joi.string().valid(             "C_EMAIL_SENT",             "C_EMAIL_OPENED",             "C_EMAIL_OPENED_ALL",             "C_EMAIL_CLICKED",             "C_EMAIL_CLICKED_ALL",             "C_EMAIL_REPLIED",             "C_EMAIL_BOUNCED",             "C_EMAIL_UNSUBSCRIBED",           )         )         .min(1)         .unique(),       Joi.string().valid("all")     ),   }) );
      note, // (optional) (undefined === null | "") Joi.string().min(0).max(3000)
      headers, // (optional) (undefined === null) Joi.object().pattern(Joi.string(), [Joi.string(), Joi.number(), Joi.boolean()])
      body, // (optional) (undefined === null | "") Joi.string().min(0).max(50000)
      httpMethod, // (optional) (undefined === null) Joi.string().allow("GET", "POST", "DELETE", "PATCH", "PUT")
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (isActive === null) {
      delete ___payload?.["isActive"];
    }

    if (url === null || url === "") {
      delete ___payload?.["url"];
    }

    if (filters === null) {
      delete ___payload?.["filters"];
    }

    if (note === null || note === "") {
      delete ___payload?.["note"];
    }

    if (headers === null) {
      delete ___payload?.["headers"];
    }

    if (body === null || body === "") {
      delete ___payload?.["body"];
    }

    if (httpMethod === null) {
      delete ___payload?.["httpMethod"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      isActive: Joi.boolean().required(),
      url: Joi.string().min(0).max(3000).required(),
      filters: Joi.array()
        .items(
          Joi.object({
            campaignIds: Joi.alternatives()
              .try(
                Joi.array()
                  .items(
                    Joi.string().pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/)
                  )
                  .min(1)
                  .max(10000),
                Joi.string().valid("all")
              )
              .required(),
            events: Joi.alternatives().try(
              Joi.array()
                .items(
                  Joi.string().valid(
                    "C_EMAIL_SENT",
                    "C_EMAIL_OPENED",
                    "C_EMAIL_OPENED_ALL",
                    "C_EMAIL_CLICKED",
                    "C_EMAIL_CLICKED_ALL",
                    "C_EMAIL_REPLIED",
                    "C_EMAIL_BOUNCED",
                    "C_EMAIL_UNSUBSCRIBED"
                  )
                )
                .min(1)
                .unique(),
              Joi.string().valid("all")
            ),
          })
        )
        .required(),
      note: Joi.string().min(0).max(3000),
      headers: Joi.object().pattern(Joi.string(), [
        Joi.string(),
        Joi.number(),
        Joi.boolean(),
      ]),
      body: Joi.string().min(0).max(50000),
      httpMethod: Joi.string().allow("GET", "POST", "DELETE", "PATCH", "PUT"),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___webhookCreate" failed even before calling the network.
api/private/sdk/webhook/create.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/webhook/create",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
