import { Grid, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

export default function ProgressItem({
  isDone,
  label,
  onClick,
  showLineThrough,
}: {
  isDone: boolean;
  label: string;
  onClick?: () => void;
  showLineThrough?: boolean;
}) {
  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
      flexWrap={"nowrap"}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <Grid item>
        {isDone ? (
          <CheckCircleOutlineIcon fontSize={"small"} />
        ) : (
          <CancelIcon fontSize={"small"} color={"error"} />
        )}
      </Grid>
      <Grid item>
        <Typography
          variant={"body2"}
          style={{
            color: "inherit",
            textDecoration: showLineThrough ? "line-through" : "none",
          }}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
}
