import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  leadsListId: string;
  title: string;
};

export type typeResultData = { isSuccess: boolean };
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___leadsListUpdate(___options: typeOptions) {
  return async function ({
    workspaceId,
    leadsListId,
    title,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      leadsListId, // (required) (undefined === null | "") Joi.string().pattern(/^[a-zA-Z0-9-_]{15,100}$/)
      title, // (required) (undefined === null | "") Joi.string().min(0).max(3000)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (leadsListId === null || leadsListId === "") {
      delete ___payload?.["leadsListId"];
    }

    if (title === null || title === "") {
      delete ___payload?.["title"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      leadsListId: Joi.string()
        .pattern(/^[a-zA-Z0-9-_]{15,100}$/)
        .required(),
      title: Joi.string().min(0).max(3000).required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___leadsListUpdate" failed even before calling the network.
api/private/sdk/leads-list/update.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/leads-list/update",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
