import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  campaignId?: string;
  sequenceId?: string;
  stepId?: string;
  variationId?: string;
};

export type typeResultData = {
  emailsSent: string;
  emailsOpened: string;
  emailsOpenedAll: string;
  emailsClicked: string;
  emailsClickedAll: string;
  emailsReplied: string;
  emailsBounced: string;
  emailsUnsubscribed: string;
  contactsOutreached: string;
  contactsOpened: string;
  contactsOpenedAll: string;
  contactsClicked: string;
  contactsClickedAll: string;
  contactsReplied: string;
  contactsBounced: string;
  contactsUnsubscribed: string;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___campaignGetMetricsIds(___options: typeOptions) {
  return async function ({
    workspaceId,
    campaignId,
    sequenceId,
    stepId,
    variationId,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      campaignId, // (optional) (undefined === null | "") Joi.string().pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/)
      sequenceId, // (optional) (undefined === null | "") Joi.string().pattern(/^sequence-[a-zA-Z0-9-_]{15,60}$/)
      stepId, // (optional) (undefined === null | "") Joi.string().min(0).max(2000)
      variationId, // (optional) (undefined === null | "") Joi.string().min(0).max(2000)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (campaignId === null || campaignId === "") {
      delete ___payload?.["campaignId"];
    }

    if (sequenceId === null || sequenceId === "") {
      delete ___payload?.["sequenceId"];
    }

    if (stepId === null || stepId === "") {
      delete ___payload?.["stepId"];
    }

    if (variationId === null || variationId === "") {
      delete ___payload?.["variationId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      campaignId: Joi.string().pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/),
      sequenceId: Joi.string().pattern(/^sequence-[a-zA-Z0-9-_]{15,60}$/),
      stepId: Joi.string().min(0).max(2000),
      variationId: Joi.string().min(0).max(2000),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___campaignGetMetricsIds" failed even before calling the network.
api/private/sdk/campaign/get-metrics-ids.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/campaign/get-metrics-ids",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
