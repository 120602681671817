import { Alert, useTheme } from "@mui/material";
import React from "react";

type Props = {};

export default function InfoBoxCreateBan({}: Props) {
  const theme = useTheme();

  return (
    <Alert severity="info">
      <ol style={{ margin: 0, padding: theme.spacing(0, 0, 0, 2) }}>
        <li>
          You can ban exact email address like{" "}
          <strong>'someone@gmail.com'</strong>
        </li>
        <li>
          You can ban email domain by starting with @.
          <br />
          E.g. <strong>'@gmail.com'</strong> would ban all gmail mails
        </li>
      </ol>
    </Alert>
  );
}
