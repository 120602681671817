import { Alert, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import InfoBoxEnterValueToCheck from "./InfoBoxEnterValueToCheck";

type Props = {
  valuesToCheck: string[];
  setValuesToCheck: (valuesToCheck: string[]) => void;
};

export default function EnterValueToCheck({
  valuesToCheck,
  setValuesToCheck,
}: Props) {
  const [valueToCheck, setValueToCheck] = useState("");

  const isInvalidText = valueToCheck.includes("@") === false;
  const isCheckDisabled =
    valueToCheck === "" || isInvalidText || valuesToCheck?.[0] === valueToCheck;

  const onCheck = () => {
    if (isCheckDisabled) return;

    const isEnteredValueDomain = valueToCheck[0] === "@";
    if (isEnteredValueDomain) {
      setValuesToCheck([valueToCheck]);
    } else {
      const domain = `@` + valueToCheck.split("@")[1];
      setValuesToCheck([valueToCheck, domain]);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InfoBoxEnterValueToCheck />
      </Grid>

      <Grid container item xs={12} alignItems={"center"} spacing={1}>
        <Grid item xs={true}>
          <TextField
            autoFocus
            fullWidth
            name="check-ban"
            label="Email address or domain to be checked"
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={(e) => setValueToCheck(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onCheck();
              }
            }}
            value={valueToCheck}
          />
        </Grid>

        <Grid item xs="auto">
          <Button
            disabled={isCheckDisabled}
            onClick={onCheck}
            variant="contained"
          >
            Check
          </Button>
        </Grid>

        {valueToCheck && isInvalidText ? (
          <Grid item xs={12}>
            <Alert severity="error" sx={{ mt: 1 }}>
              Value must contain '@'
            </Alert>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
