export default function setDefaultHeaders({
  headers,
}: {
  headers?: { [key: string]: string };
}) {
  headers = headers || {};

  let cleanHeaders: { [key: string]: string } = {};
  Object.keys(headers).map((key) => {
    cleanHeaders[key.toLowerCase()] = headers?.[key] || "";
  });
  headers = cleanHeaders;
  // clean up headers to keep all lowercase --- END ---

  // some default headers --- START ---
  if (!headers["accept"]) {
    headers["accept"] = "application/json";
  }
  if (!headers["content-type"]) {
    headers["content-type"] = "application/json";
  }
  // some default headers --- END ---

  return headers;
}