import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/user/get-meta";
import useCallSdk from "../useCallSdk";

export function buildUserGetMetaQueryKey(_payload?: typePayload) {
  let _queryKey: string[] = [];

  return ["user:get-meta", ..._queryKey];
}

export function useUserGetMetaQueryFn(_payload?: typePayload) {
  const _sdk = useCallSdk();

  return () => _sdk.callSdk().userGetMeta({});
}

export type typeUserGetMetaHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useUserGetMeta(
  _payload?: typePayload,
  _options?: typeUserGetMetaHookOptions
) {
  _options = _options || {};
  const _queryKey = buildUserGetMetaQueryKey({});
  const _queryFn = useUserGetMetaQueryFn({});

  return useQuery(_queryKey, _queryFn, _options);
}
