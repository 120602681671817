import {
  Card,
  CardContent,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import Unreleased from "components/partials/Unreleased";
import moment from "moment-timezone";
import {
  LEADS_LIST_ORIGINAL_WORKSPACE,
  LEADS_SHARE_LINK_ID,
} from "queryParams";
import React from "react";
import { StringParam, useQueryParams } from "use-query-params";
import useLeadsShareLinkGet from "utils/reactQueryHooks/leads-share-link/useLeadsShareLinkGet";
import AddLeadsListToOwnWorkspace from "./AddLeadsListToOwnWorkspace";

type Props = {};

export default function ShareLeadsList({}: Props) {
  const theme = useTheme();

  const [queryParams] = useQueryParams({
    [LEADS_SHARE_LINK_ID]: StringParam,
    [LEADS_LIST_ORIGINAL_WORKSPACE]: StringParam,
  });
  const leadsShareLinkId = queryParams[LEADS_SHARE_LINK_ID] || "";
  const leadsListOriginalWorkspaceId =
    queryParams[LEADS_LIST_ORIGINAL_WORKSPACE] || "";

  const shareLinkGet = useLeadsShareLinkGet(
    {
      leadsShareLinkId,
      workspaceId: leadsListOriginalWorkspaceId,
    },
    {
      refetchInterval: 5 * 1000, // so we can check if expired to update UI every 5 seconds
    }
  );

  const hasLinkExpired = Boolean(
    shareLinkGet.data?.data?.expireAt &&
      shareLinkGet.data.data.expireAt < moment().unix()
  );

  let content: React.ReactNode;

  if (shareLinkGet.isLoading) {
    content = (
      <Typography
        color="text.secondary"
        textAlign={"center"}
        style={{
          padding: theme.spacing(7, 0),
        }}
      >
        Loading...
      </Typography>
    );
  } else if (!shareLinkGet.data?.data?.leadsShareLinkId || hasLinkExpired) {
    content = (
      <Typography
        color="error"
        textAlign={"center"}
        style={{
          padding: theme.spacing(7, 0),
        }}
      >
        Share link expired or invalid
      </Typography>
    );
  } else {
    content = (
      <AddLeadsListToOwnWorkspace shareLinkGet={shareLinkGet.data.data} />
    );
  }

  return (
    <Unreleased identifier="#uai8327938749">
      <Container maxWidth="sm" sx={{ padding: theme.spacing(2, 0) }}>
        <div style={{ margin: theme.spacing(5, 0) }}>
          <div style={{ textAlign: "center", margin: theme.spacing(2, 0) }}>
            <img
              src={
                theme.palette.mode === "light" ? "/logo.svg" : "/logo-light.svg"
              }
              alt="First Sales.io"
            />
          </div>
        </div>
        <Card variant="outlined">
          <CardContent>{content}</CardContent>
        </Card>
      </Container>
    </Unreleased>
  );
}
