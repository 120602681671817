import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  leadsListId?: string;
  leadsListTitle?: string;
  query?: string;
  savedSearchId?: string;
  lastViewedAt?: number;
  sessionId: string;
  decorationId: string;
  title: string;
  totalCount: number;
  jobState: "PENDING" | "RUNNING";
  originalCountText: string;
};

export type typeResultData = { jobId: string };
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___linkedinJobCreate(___options: typeOptions) {
  return async function ({
    workspaceId,
    leadsListId,
    leadsListTitle,
    query,
    sessionId,
    decorationId,
    title,
    totalCount,
    jobState,
    originalCountText,
    savedSearchId,
    lastViewedAt,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      leadsListId, // (optional) (undefined === null | "") Joi.string().pattern(/^[a-zA-Z0-9-_]{15,100}$/)
      leadsListTitle, // (optional) (undefined === null | "") Joi.string().min(0).max(3000)
      query, // (optional) (undefined === "") Joi.string().min(0).max(50000)
      sessionId, // (required) (undefined === "") Joi.string().min(0).max(3000)
      decorationId, // (required) (undefined === "") Joi.string().min(0).max(3000)
      title, // (required) (undefined === "") Joi.string().min(0).max(3000)
      totalCount, // (required) (undefined === null) Joi.number().integer()
      jobState, // (required) Joi.string().valid("PENDING","RUNNING")
      originalCountText, // (required) (undefined === null | "") Joi.string().min(0).max(3000)
      savedSearchId, // (optional) (undefined === "") Joi.string().min(0).max(3000)
      lastViewedAt, // (optional) (undefined === null) Joi.number().integer().min(0)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (leadsListId === null || leadsListId === "") {
      delete ___payload?.["leadsListId"];
    }

    if (leadsListTitle === null || leadsListTitle === "") {
      delete ___payload?.["leadsListTitle"];
    }

    if (query === "") {
      delete ___payload?.["query"];
    }

    if (sessionId === "") {
      delete ___payload?.["sessionId"];
    }

    if (decorationId === "") {
      delete ___payload?.["decorationId"];
    }

    if (title === "") {
      delete ___payload?.["title"];
    }

    if (totalCount === null) {
      delete ___payload?.["totalCount"];
    }

    if (originalCountText === null || originalCountText === "") {
      delete ___payload?.["originalCountText"];
    }

    if (savedSearchId === "") {
      delete ___payload?.["savedSearchId"];
    }

    if (lastViewedAt === null) {
      delete ___payload?.["lastViewedAt"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      leadsListId: Joi.string().pattern(/^[a-zA-Z0-9-_]{15,100}$/),
      leadsListTitle: Joi.string().min(0).max(3000),
      query: Joi.string().min(0).max(50000),
      sessionId: Joi.string().min(0).max(3000).required(),
      decorationId: Joi.string().min(0).max(3000).required(),
      title: Joi.string().min(0).max(3000).required(),
      totalCount: Joi.number().integer().required(),
      jobState: Joi.string().valid("PENDING", "RUNNING").required(),
      originalCountText: Joi.string().min(0).max(3000).required(),
      savedSearchId: Joi.string().min(0).max(3000),
      lastViewedAt: Joi.number().integer().min(0),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___linkedinJobCreate" failed even before calling the network.
api/private/sdk/linkedin-job/create.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/linkedin-job/create",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
