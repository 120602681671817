import { ErrorOutlineRounded } from "@mui/icons-material";
import { Typography, useTheme } from "@mui/material";
import { ConfirmOptions, useConfirm } from "material-ui-confirm";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type NavigationStatusBlocked = {
  isBlocked: true;
  /** async action that should be performed when user clicks on "save changes", like making request to save some data */
  onBeforeUnblock: () => Promise<unknown>;
  /** options to customize confirm dialog */
  confirmOptions?: ConfirmOptions;
  disableNavigateAfterConfirm?: boolean;
};
type NavigationStatus =
  | {
      isBlocked: false;
    }
  | NavigationStatusBlocked;

type BlockableNavigationContextInterface = {
  navigationStatus: NavigationStatus;
  setNavigationStatus: (navigationStatus: NavigationStatus) => void;
  /** checks global state for unsaved changes from any other component before running navigation logic */
  blockableNavigate: (navigate: () => void) => void;
};

const logWarning = () =>
  console.warn(
    "the component probably isn't wrapped with blockable-navigation-context"
  );

const BlockableNavigationContext =
  createContext<BlockableNavigationContextInterface>({
    navigationStatus: { isBlocked: false },
    setNavigationStatus: logWarning,
    blockableNavigate: logWarning,
  });

export default function BlockableNavigationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const theme = useTheme();
  const confirm = useConfirm();
  const [navigationStatus, setNavigationStatus] = useState<NavigationStatus>({
    isBlocked: false,
  });

  // if navigationStatus is blocked, show confirmation before page refresh too
  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();

      e.returnValue = "You have unsaved changes.";
      return "You have unsaved changes."; //
    };
    if (navigationStatus.isBlocked) {
      window.addEventListener("beforeunload", onBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [navigationStatus.isBlocked]);

  const blockableNavigate = async (navigate: () => void) => {
    if (navigationStatus.isBlocked === false) {
      navigate();
    } else {
      confirm({
        title: (
          <Typography
            component="div"
            style={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
            variant="h6"
          >
            <ErrorOutlineRounded />
            Your changes will be lost
          </Typography>
        ),
        description: <>You have unsaved changes.</>,
        confirmationText: "Save changes",
        confirmationButtonProps: { color: "success" },
        cancellationText: "Dismiss changes",
        cancellationButtonProps: { color: "error" },
        ...navigationStatus.confirmOptions,
      })
        .then(() => {
          (async () => {
            try {
              await navigationStatus.onBeforeUnblock();
              if (!navigationStatus.disableNavigateAfterConfirm) navigate();
            } catch (e: any) {
              console.log(
                "`onBeforeUnblock` resulted in error #klt023923-0",
                e
              );
            }
          })();
        })
        .catch(() => {
          // when user presses "dismiss changes"
          console.log("unsaved changes dismissed #qwe733242321");
          navigate();
        });
    }
  };

  return (
    <BlockableNavigationContext.Provider
      value={{
        navigationStatus,
        setNavigationStatus,
        blockableNavigate,
      }}
    >
      {children}
    </BlockableNavigationContext.Provider>
  );
}

export const useBlockableNavigation = () => {
  return useContext(BlockableNavigationContext);
};

// --------

type UseBlockNavigationInterface = {
  blockNavigationWhen: boolean;
  /** sometimes there are stale `onBeforeUnblock` functions in global state, they can be refreshed whenever passed thing to this array changes */
  effectDependencies?: (string | boolean)[];
} & Omit<NavigationStatusBlocked, "isBlocked">;

export const useBlockNavigation = ({
  blockNavigationWhen,
  onBeforeUnblock,
  confirmOptions,
  disableNavigateAfterConfirm = false,
  effectDependencies = [],
}: UseBlockNavigationInterface) => {
  const { setNavigationStatus } = useBlockableNavigation();

  useEffect(() => {
    if (blockNavigationWhen === true) {
      setNavigationStatus({
        isBlocked: true,
        onBeforeUnblock,
        confirmOptions,
        disableNavigateAfterConfirm,
      });
    } else {
      setNavigationStatus({ isBlocked: false });
    }

    // always reset upon unmount
    return () => setNavigationStatus({ isBlocked: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockNavigationWhen, disableNavigateAfterConfirm, ...effectDependencies]);
};
