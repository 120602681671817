import { ReactNode } from "react";
import useUserGetMeta from "./reactQueryHooks/user/useUserGetMeta";
import { purple } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { Grid, useTheme } from "@mui/material";

export default function StaffOnly({
  children,
  identifier,
}: {
  children: ReactNode;
  identifier?: string;
}) {
  const theme = useTheme();
  let user = useUserGetMeta({});
  let isVisible = user?.data?.data?.type === "staff";

  return isVisible ? (
    <Grid item xs={12}>
      <div
        style={{
          border: `1px solid ${purple[500]}`,
          borderRadius: theme.shape.borderRadius,
          width: "100%",
        }}
      >
        <div
          style={{
            background: purple[500],
            padding: theme.spacing(1),
            textAlign: "center",
          }}
        >
          <Typography variant={"body1"} style={{ color: "#FFFFFF" }}>
            Staff Only {identifier ? `${identifier}` : null}
          </Typography>
        </div>
        <div style={{ padding: theme.spacing(1) }}>{children}</div>
      </div>
    </Grid>
  ) : null;
}
