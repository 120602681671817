import { Tooltip, Typography } from "@mui/material";
import React from "react";
import useWorkspaceGetMetaStats from "utils/reactQueryHooks/workspace/useWorkspaceGetMetaStats";

type Props = { stats: ReturnType<typeof useWorkspaceGetMetaStats> };

export default function SidebarStatsContacts({ stats }: Props) {
  const totalContactsCount = stats.data?.data?.totalContacts || 0;
  const suspendedContactsCount = stats.data?.data?.totalContactsSuspended || 0;
  const warmContactsCount = stats.data?.data?.totalContactsWarm || 0;
  const activeContactsCount = totalContactsCount - suspendedContactsCount;
  const coldContactsCount = totalContactsCount - warmContactsCount;

  let content: React.ReactNode = null;

  if (activeContactsCount > 0 || coldContactsCount > 0) {
    content = (
      <Tooltip title="Active contacts / Cold contacts" arrow placement="top">
        <Typography color="text.secondary" variant="caption">
          {activeContactsCount}/{coldContactsCount}
        </Typography>
      </Tooltip>
    );
  }

  return content;
}
