import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  tenant: string;
  metric?: string;
  metricId?: string;
};

export type typeResultData = {
  tenant: string;
  metric: string;
  metricId: string;
  lastUsedAtMs: number;
  totalCount: number;
  totalCountUnique: number;
  totalValue: number;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___analyticsMetricGet(___options: typeOptions) {
  return async function ({
    workspaceId,
    tenant,
    metric,
    metricId,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      tenant, // (required) (undefined === null | "") Joi.string().min(0).max(300)
      metric, // (optional) (undefined === null | "") Joi.string().min(0).max(300)
      metricId, // (optional) (undefined === null | "") Joi.string().min(0).max(300)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (tenant === null || tenant === "") {
      delete ___payload?.["tenant"];
    }

    if (metric === null || metric === "") {
      delete ___payload?.["metric"];
    }

    if (metricId === null || metricId === "") {
      delete ___payload?.["metricId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      tenant: Joi.string().min(0).max(300).required(),
      metric: Joi.string().min(0).max(300),
      metricId: Joi.string().min(0).max(300),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___analyticsMetricGet" failed even before calling the network.
api/private/sdk/analytics/metric-get.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/analytics/metric-get",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
