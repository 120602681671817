import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/leads-list/stats";
import useCallSdk from "../useCallSdk";

export function buildLeadsListStatsQueryKey(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const leadsListId = _payload.leadsListId;
  let _queryKey: string[] = [];

  if (
    typeof workspaceId !== "undefined" &&
    workspaceId !== null &&
    workspaceId !== ""
  ) {
    _queryKey.push(String(workspaceId));
  }

  if (
    typeof leadsListId !== "undefined" &&
    leadsListId !== null &&
    leadsListId !== ""
  ) {
    _queryKey.push(String(leadsListId));
  }

  return ["leads-list:stats", ..._queryKey];
}

export function useLeadsListStatsQueryFn(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const leadsListId = _payload.leadsListId;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().leadsListStats({
      workspaceId,
      leadsListId,
    });
}

export type typeLeadsListStatsHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useLeadsListStats(
  _payload: typePayload,
  _options?: typeLeadsListStatsHookOptions
) {
  const workspaceId = _payload.workspaceId;
  const leadsListId = _payload.leadsListId;
  _options = _options || {};
  const _queryKey = buildLeadsListStatsQueryKey({ workspaceId, leadsListId });
  const _queryFn = useLeadsListStatsQueryFn({ workspaceId, leadsListId });

  return useQuery(_queryKey, _queryFn, _options);
}
