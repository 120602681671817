import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

export default function Timer({ endDate }: { endDate: number }) {
  const calculateTimeLeft = () => {
    const difference = endDate * 1000 - +new Date();
    let timeLeft: Record<string, number> = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hr: Math.floor((difference / (1000 * 60 * 60)) % 24),
        min: Math.floor((difference / (1000 * 60)) % 60),
        sec: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const formatTime = (value: any) => {
    return value.toString().padStart(2, "0");
  };

  return (
    <div style={{ display: "flex" }}>
      {Object.entries(timeLeft).map(([interval, value], index) => {
        let colon = "";

        if (index !== Object.entries(timeLeft).length - 1) {
          colon = " : ";
        }

        if (interval === "days") {
          colon = "";
        }

        return (
          <div
            key={interval}
            style={{
              display: "grid",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h6"
                align="center"
                sx={
                  interval === "days"
                    ? {
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        padding: "0.3rem 0.5rem",
                        textShadow: "0 0 10px rgba(0,0,0,0.5)",
                        borderRadius: "4px",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        marginTop: "20px",
                        marginRight: "10px",
                      }
                    : {
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        padding: "0.3rem 0.5rem",
                        textShadow: "0 0 10px rgba(0,0,0,0.5)",
                        borderRadius: "4px",
                        backgroundColor: "rgba(0,0,0,0.5)",
                      }
                }
              >
                {formatTime(value)}
              </Typography>
              {index !== Object.entries(timeLeft).length - 1 && (
                <Typography
                  variant="h6"
                  align="right"
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    textShadow: "0 0 10px rgba(0,0,0,0.5)",
                    whiteSpace: "nowrap",
                    margin: "0px 2px",
                  }}
                >
                  {colon}
                </Typography>
              )}
            </div>
            {interval === "days" && (
              <Typography
                component={"div"}
                variant="caption"
                align="center"
                color="text.secondary"
                sx={{
                  width: 30,
                  textAlign: "center",
                  color: "inherit",
                }}
              >
                {value === 1 ? "day" : "days"}
              </Typography>
            )}
          </div>
        );
      })}
    </div>
  );
}
