import { LoadingButton } from "@mui/lab";
import useConfirmCreateBan from "components/Admin/TabBannedAccounts/TableBannedAccounts/useConfirmCreateBan";
import useConfirmUnBan from "components/Admin/TabBannedAccounts/TableBannedAccounts/useConfirmUnBan";
import React from "react";
import useAdminSuspendGet from "utils/reactQueryHooks/admin-suspend/useAdminSuspendGet";

type Props = {
  suspendGet: ReturnType<typeof useAdminSuspendGet>;
  value: string;
};

export default function SingleBanCheckActionButton({
  suspendGet,
  value,
}: Props) {
  const isBanned = suspendGet.data?.data?.value;

  const confirmUnBan = useConfirmUnBan({});
  const confirmCreateBan = useConfirmCreateBan({});

  const isActionLoading =
    suspendGet.isLoading ||
    confirmUnBan.isLoading ||
    confirmCreateBan.isLoading;

  const onActionClick = () => {
    if (isBanned) {
      confirmUnBan.onUnBan({ value });
    } else {
      confirmCreateBan.onCreateBan({ value });
    }
  };

  return (
    <LoadingButton
      color={isBanned ? undefined : "error"}
      onClick={onActionClick}
      loading={isActionLoading}
      size="small"
      variant="outlined"
    >
      {isBanned ? "Un-ban" : "Ban"}
    </LoadingButton>
  );
}
