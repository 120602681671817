import { TypeLeadState } from "components/FirstsalesProspects/LeadsList/LeadsInsideList/LeadsList/LeadStatus";
import { TypeFetchingJobState } from "types";
import {
  DelimitedArrayParam,
  createEnumParam,
  withDefault,
} from "use-query-params";

// Campaigns
export const CAMPAIGNS_OPEN_SENDING_OVERVIEW =
  "campaigns-open-sending-overview";
export const CAMPAIGNS_OPEN_ADD_CAMPAIGN = "campaigns-open-add-campaign";
export const CAMPAIGNS_SINGLE_CAMPAIGN_ID = "single-campaign-id";
export const CAMPAIGNS_SINGLE_CAMPAIGN_STEP = "single-campaign-step";
export const CAMPAIGN_SEQUENCE_ID = "sequence-id";
export const CAMPAIGN_SINGLE_CONTACT_ID = "campaign-single-contact-id";
export const CAMPAIGN_CONTACTS_PREVIEW_TAB = "contacts-preview-tab";
const _CampaignPreviewTabParams = createEnumParam([
  "unknown",
  "zoho",
  "gmail",
  "microsoft",
  "usedContacts",
]);
export const CampaignPreviewTabParams = withDefault(
  _CampaignPreviewTabParams,
  "gmail" as const
);

export const planBucketTabNames = [
  "all",
  "planned",
  "executing",
  "delayed",
  "resuming", // after item is delayed it would go into resuming state and then would be planned again
  "failed",
  "sent",
  "done",
] as const;
export const planBucket = createEnumParam([...planBucketTabNames]);
export const CAMPAIGN_STEP_ID = "step-id";
export const CAMPAIGN_VARIANT_ID = "variant-id";
export const PLAN_ITEM_ID = "plan-item-id";
export const PLAN_BUCKET = "plan-bucket";
export const CAMPAIGN_METRIC_SEGMENT_ID = "metric-segment-id";
export const CAMPAIGN_OPEN_ADD_MAIL = "campaign-open-add-mail";
export const CAMPAIGN_OPEN_ADD_DOMAIN = "campaign-open-add-domain";
export const CAMPAIGN_MAIL_VIEW = "campaign-mail-view";
export const CAMPAIGN_OPTIONS_UTM_ENABLED = "campaign-options-utm-enabled";
export const CAMPAIGN_OPEN_UPLOAD_CONTACTS = "campaign-open-upload-contacts";

// campaign builder v2
export const CAMPAIGNS_SINGLE_CAMPAIGN_ID_BUILDER_V2 =
  "single-campaign-id-builder-v2";
export const CAMPAIGN_V2_STEPS = {
  CONTACTS: "contacts",
  SENDING: "sending",
  SCHEDULE: "schedule",
  SEQUENCE: "sequence",
  SUMMARY: "summary",

  SENDING_QUEUE: "sending-queue",
  ANALYTICS: "analytics",
} as const;
export const CampaignV2StepParams = createEnumParam([
  CAMPAIGN_V2_STEPS.CONTACTS,
  CAMPAIGN_V2_STEPS.SENDING,
  CAMPAIGN_V2_STEPS.SCHEDULE,
  CAMPAIGN_V2_STEPS.SEQUENCE,
  CAMPAIGN_V2_STEPS.SUMMARY,

  CAMPAIGN_V2_STEPS.SENDING_QUEUE,
  CAMPAIGN_V2_STEPS.ANALYTICS,
]);

// Contacts
export const CONTACTS_SINGLE_CONTACT_ID = "single-contact-id";
export const CONTACTS_OPEN_ADD_NEW_LIST = "contacts-open-add-new-list";
export const CONTACTS_SINGLE_LIST_ID = "single-list-id";
export const CONTACTS_OPEN_ADD_SEGMENT = "contacts-open-add-segment";
export const CONTACTS_SINGLE_SEGMENT_ID = "single-segment-id";
export const CONTACTS_ADD_CONTACTS_TO_LIST_ID = "add-contacts-to-list-id";
export const CONTACTS_LOGS_OPEN = "contact-logs-open";
export const CONTACTS_TAB_ADD_CONTACTS = "tab-add-contacts";
export const CONTACTS_ADD_CONTACTS_TABS = {
  CSV_UPLOAD: "csv-upload",
  CSV_PASTE: "csv-paste",
  MANUAL: "manual",
} as const;
export const CONTACTS_DEFAULT_ADD_CONTACTS_TAB =
  CONTACTS_ADD_CONTACTS_TABS.CSV_UPLOAD; // will be using it to open view create-contact view from various places(Contacts list and Single list view to begin with)
export const ContactsAddContactTabParams = createEnumParam([
  CONTACTS_ADD_CONTACTS_TABS.CSV_UPLOAD,
  CONTACTS_ADD_CONTACTS_TABS.CSV_PASTE,
  CONTACTS_ADD_CONTACTS_TABS.MANUAL,
]);

// with such enumParams we get default value instead of null | undefined and better auto-complete from pre-defined options
export const CONTACTS_TAB_CONTACTS = "tab-contacts";
export const CONTACT_TABS = {
  CONTACTS: "contacts",
  LISTS: "lists",
  SEGMENTS: "segments",
} as const;
const _ContactTabParams = createEnumParam([
  CONTACT_TABS.CONTACTS,
  CONTACT_TABS.LISTS,
  CONTACT_TABS.SEGMENTS,
]);
export const ContactTabParams = withDefault(
  _ContactTabParams,
  "contacts" as const
);

export const CONTACTS_IS_UPLOAD_PROGRESS_OPEN = "is-upload-progress-open";

// contacts filters
export const CONTACTS_CREATED_AT_AFTER = "contacts-created-at-after";
export const CONTACTS_CREATED_AT_BEFORE = "contacts-created-at-before";
export const CONTACTS_SORT_BY = "contacts-sort-by";

// contacts metrics views
export const CONTACTS_METRICS_VIEW = "contacts-metrics-view";
export const CONTACTS_METRICS_VIEWS = {
  SEGMENT_METRICS: "segment-metrics",
  LIST_METRICS: "list-metrics",
  CONTACTS_METRICS: "contacts-metrics",
} as const;
export const ContactsMetricsViewParams = createEnumParam([
  CONTACTS_METRICS_VIEWS.SEGMENT_METRICS,
  CONTACTS_METRICS_VIEWS.LIST_METRICS,
  CONTACTS_METRICS_VIEWS.CONTACTS_METRICS,
]);

// Mass actions contacts
export const MASS_ACTION_ON_ALL_CONTACTS = "mass-action-on-all-contacts";
export const MASS_ACTION_CONTACTS_ON_LIST_ID =
  "mass-action-contacts-on-list-id";
export const MASS_ACTION_CONTACTS_ON_SEGMENT_ID =
  "mass-action-contacts-on-segment-id";

export const MASS_ACTION_VIEW_CONTACTS = "mass-action-view-contacts";
export const MASS_ACTION_VIEWS_CONTACTS = {
  CONTACTS_DELETE: "contacts-delete",
  CONTACTS_SUSPEND: "contacts-suspend",
  CONTACTS_UNSUSPEND: "contacts-unsuspend",
  CONTACTS_SET_AS_WARM: "contacts-set-as-warm",
  CONTACTS_SET_AS_COLD: "contacts-set-as-cold",
  CONTACTS_VALIDATE: "contacts-validate",
  CONTACTS_ADD_TO_LIST: "contacts-add-to-list",
  CONTACTS_REMOVE_FROM_LIST: "contacts-remove-from-list",
  CONTACTS_EXPORT_AS_CSV: "contacts-export-as-csv",
  CONTACTS_ADD_TAGS: "contacts-add-tags",
  CONTACTS_REMOVE_TAGS: "contacts-remove-tags",
} as const;
export const ContactsMassActionViewParams = createEnumParam([
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_DELETE,
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_SUSPEND,
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_UNSUSPEND,
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_SET_AS_WARM,
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_SET_AS_COLD,
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_VALIDATE,
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_ADD_TO_LIST,
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_REMOVE_FROM_LIST,
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_EXPORT_AS_CSV,
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_ADD_TAGS,
  MASS_ACTION_VIEWS_CONTACTS.CONTACTS_REMOVE_TAGS,
]);

// Mass actions segments
export const MASS_ACTION_VIEW_SEGMENTS = "mass-action-view-segments";
export const MASS_ACTION_VIEWS_SEGMENTS = {
  SEGMENTS_DELETE: "segments-delete",
} as const;
export const SegmentsMassActionViewParams = createEnumParam([
  MASS_ACTION_VIEWS_SEGMENTS.SEGMENTS_DELETE,
]);

// Mass actions lists
export const MASS_ACTION_VIEW_LISTS = "mass-action-view-lists";
export const MASS_ACTION_VIEWS_LISTS = {
  LISTS_DELETE: "lists-delete",
} as const;
export const ListsMassActionViewParams = createEnumParam([
  MASS_ACTION_VIEWS_LISTS.LISTS_DELETE,
]);

// Mass actions campaigns
export const MASS_ACTION_VIEW_CAMPAIGNS = "mass-action-view-campaigns";
export const MASS_ACTION_VIEWS_CAMPAIGNS = {
  CAMPAIGNS_DELETE: "campaigns-delete",
  CAMPAIGNS_STOP: "campaigns-stop",
  CAMPAIGNS_START: "campaigns-start",
} as const;
export const CampaignsMassActionViewParams = createEnumParam([
  MASS_ACTION_VIEWS_CAMPAIGNS.CAMPAIGNS_DELETE,
  MASS_ACTION_VIEWS_CAMPAIGNS.CAMPAIGNS_STOP,
  MASS_ACTION_VIEWS_CAMPAIGNS.CAMPAIGNS_START,
]);
// Mass actions plan items
export const MASS_ACTION_VIEW_PLAN_ITEMS = "mass-action-view-plan-items";
export const MASS_ACTION_VIEWS_PLAN_ITEMS = {
  PLAN_ITEMS_DELETE: "plan-items-delete",
} as const;
export const PlanItemsMassActionViewParams = createEnumParam([
  MASS_ACTION_VIEWS_PLAN_ITEMS.PLAN_ITEMS_DELETE,
]);

// Analytics

export const ANALYTICS_TAB_ANALYTICS = "tab-analytics";
export const ANALYTICS_TABS = {
  OVERVIEW: "overview",
  METRICS: "metrics",
} as const;
const _AnalyticsTabParams = createEnumParam([
  ANALYTICS_TABS.METRICS,
  ANALYTICS_TABS.OVERVIEW,
]);
export const AnalyticsTabParams = withDefault(
  _AnalyticsTabParams,
  "metrics" as const // TODO: after we have overview, maybe better to have that as default value
);

export const ANALYTICS_SINGLE_METRIC_ID = "single-metric-id";

export const ANALYTICS_TAB_SINGLE_METRIC = "tab-single-metric";
export const ANALYTICS_SINGLE_METRIC_TABS = {
  CHART: "chart",
  ACTIVITY_FEED: "activity-feed",
} as const;
const _SingleMetricTabParams = createEnumParam([
  ANALYTICS_SINGLE_METRIC_TABS.CHART,
  ANALYTICS_SINGLE_METRIC_TABS.ACTIVITY_FEED,
]);
export const AnalyticsSingleMetricTabParams = withDefault(
  _SingleMetricTabParams,
  "chart" as const
);

// chart filters
export const FILTER_CHART_DATE_RANGE = "filter-chart-date-range";
export const FILTER_CHART_VIEW_BY = "filter-chart-view-by";
export const FILTER_CHART_FILTER = "filter-chart-filter";
export const FILTER_CHART_MEASUREMENT = "filter-chart-measurement";
export const FILTER_CHART_PROPERTY = "filter-chart-property";
export const FILTER_CHART_VALUE = "filter-chart-value";

// activity feed
export const ANALYTICS_ACTIVITY_FEED_METRIC_ID = "activity-feed-metric-id";
export const ANALYTICS_DIALOG_EVENT_ID = "dialog-event-id";

export const EVENTS_EXPORT_METRIC_ID = "events-export-metric-id";
export const EVENTS_EXPORT_TENANT = "events-export-tenant";

// activity feed filters
export const FILTER_ACTIVITY_FEED_DATE_RANGE = "filter-af-date-range";
export const FILTER_ACTIVITY_FEED_SORT_BY = "filter-af-sort-by";

// analytics-scope-picker
export const SCOPE_CAMPAIGN_ID = "scope-campaign-id";
export const SCOPE_SEQUENCE_ID = "scope-sequence-id";
export const SCOPE_STEP_ID = "scope-step-id";
export const SCOPE_VARIANT_ID = "scope-variant-id";

// Join Workspace

export const JOIN_WORKSPACE_INVITE_ID = "invite-id";
export const JOIN_WORKSPACE_TARGET_WORKSPACE_ID = "target-workspace-id";

// Team members
export const TEAM_MEMBERS_SINGLE_TEAM_MEMBER_ID = "single-team-member-id";

// create workspace
export const IS_CREATE_WORKSPACE_OPEN = "is-create-workspace-open";
export const CREATE_WORKSPACE_STEP = "create-workspace-step";
export const NAVIGATE_TO_WORKSPACE_ID = "navigate-to-workspace-id";

// Settings
export const SETTINGS_MAIL_AND_DOMAINS_VIEW = "mail-and-domains-view";
export const SETTINGS_UPDATE_FROM_NAME_MAIL_ID = "update-from-name-mail-id";
export const TAB_SETTINGS = "tab-settings";
export const SETTINGS_TABS = {
  WORKSPACE: "workspace",
  EMAILS_AND_DOMAINS: "emails-and-domains",
  WORKSPACE_STATUS: "workspace-status",
  WEBHOOKS: "webhooks",
} as const;
export const _SettingsTabParams = createEnumParam([
  SETTINGS_TABS.WORKSPACE,
  SETTINGS_TABS.EMAILS_AND_DOMAINS,
  SETTINGS_TABS.WORKSPACE_STATUS,
  SETTINGS_TABS.WEBHOOKS,
]);
export const SettingsTabParams = withDefault(
  _SettingsTabParams,
  SETTINGS_TABS.WORKSPACE
);

// Mail account
export const MAIL_RE_AUTHORIZE = "mail-re-authorize";
export const MAIL_ACCOUNTS_OPEN_ADD_MAIL = "mail-accounts-open-add-mail";
export const MAIL_ACCOUNTS_OPEN_ADD_BULK = "mail-accounts-open-add-bulk";
export const MAIL_ACCOUNT_ID = "mail-account-id";
export const MAIL_MESSAGE_ID = "mail-message-id";

// Mail account -> Signatures
export const MAIL_ACCOUNT_CREATE_SIGNATURE_OPEN = "create-signature-open";
export const MAIL_ACCOUNT_SINGLE_SIGNATURE_ID = "single-signature-id";
export const MAIL_ACCOUNT_SIGNATURE_TO_COPY = "signature-to-copy";

// single mail view tabs
export const TAB_SINGLE_MAIL_VIEW = "tab-single-mail-view";
export const SINGLE_MAIL_VIEW_TABS = {
  WARMING: "warming",
  TRACKING: "tracking",
  VIEW_LOGS: "logs",
  MAILBOX_SETTINGS: "mailbox-settings",
  GLOBAL_SENDING_LIMITS: "global-sending-limits",
  SIGNATURES: "signatures",
} as const;
export const _SingleMailViewTabParams = createEnumParam([
  SINGLE_MAIL_VIEW_TABS.TRACKING,
  SINGLE_MAIL_VIEW_TABS.WARMING,
  SINGLE_MAIL_VIEW_TABS.VIEW_LOGS,
  SINGLE_MAIL_VIEW_TABS.MAILBOX_SETTINGS,
  SINGLE_MAIL_VIEW_TABS.GLOBAL_SENDING_LIMITS,
  SINGLE_MAIL_VIEW_TABS.SIGNATURES,
]);
export const SingleMailViewTabParams = withDefault(
  _SingleMailViewTabParams,
  SINGLE_MAIL_VIEW_TABS.TRACKING
);

// Domains
export const SETTINGS_OPEN_ADD_DOMAIN = "open-add-domain";

// Firstsales prospects
export const PROSPECTS_SINGLE_JOB_ID = "single-job-id";
export const TAB_FIRSTSALES_PROSPECTS = "tab-firstsales-prospects";
export const PROSPECTS_TABS = {
  LEADS_LIST: "leads-list",
  LINKEDIN_FETCHING: "linkedin-fetching",
  SHARED_LEADS: "shared-leads",
} as const;
const _ProspectsTabParams = createEnumParam([
  PROSPECTS_TABS.LEADS_LIST,
  PROSPECTS_TABS.LINKEDIN_FETCHING,
  PROSPECTS_TABS.SHARED_LEADS,
]);
export const ProspectsTabParams = withDefault(
  _ProspectsTabParams,
  PROSPECTS_TABS.LEADS_LIST
);

// Firstsales prospects -> Fetching jobs
export const PROSPECTOR_FETCHING_JOBS_STATE = "fetching-jobs-state";
export const FETCHING_JOBS_STATES = {
  PENDING: "PENDING",
  RUNNING: "RUNNING",
  DONE: "DONE",
} as const;
export const fetchingJobStates: TypeFetchingJobState[] = [
  FETCHING_JOBS_STATES.DONE,
  FETCHING_JOBS_STATES.PENDING,
  FETCHING_JOBS_STATES.RUNNING,
];
export const FetchingJobStateParams = createEnumParam(fetchingJobStates);

// Firstsales prospects -> Leads list
export const PROSPECTS_LEADS_LIST_ID = "leads-list-id";
export const PROSPECTS_LEADS_LIST_STATE = "leads-list-state";
export const LEAD_STATES = {
  PENDING: "PENDING",
  WITH_EMAIL: "WITH_EMAIL",
  NO_EMAIL: "NO_EMAIL",
} as const;
export const leadStates: TypeLeadState[] = [
  LEAD_STATES.NO_EMAIL,
  LEAD_STATES.WITH_EMAIL,
  LEAD_STATES.PENDING,
];
export const LeadStateParams = createEnumParam(leadStates);
export const SHAREABLE_LINKS_VIEW_OPEN = "shareable-links-view-open";
export const EDIT_LEADS_SHARE_LINK_ID = "edit-leads-share-link-id";
export const LEADS_SHARE_LINK_ID = "leads-share-link-id";
export const LEADS_LIST_ORIGINAL_WORKSPACE = "leads-list-original-workspace";

// Firstsales prospects -> Leads list -> Leads export
export const PROSPECTS_LEADS_EXPORT_VIEW = "leads-export-view";
export const LEADS_EXPORT_VIEWS = {
  EXPORT_AS_CSV: "export-as-csv",
  EXPORT_TO_CONTACTS_LIST: "export-to-contacts-list",
} as const;
export const LeadsExportViewParams = createEnumParam([
  LEADS_EXPORT_VIEWS.EXPORT_AS_CSV,
  LEADS_EXPORT_VIEWS.EXPORT_TO_CONTACTS_LIST,
]);

// leads-list -> leads export filters
export const PROSPECTOR_LEADS_LIST_EXPORT_FILTER = "leads-list-export-filter";
export const LEADS_LIST_EXPORT_FILTERS = {
  ONLY_WITH_EMAIL: "with-email",
  ONLY_WITH_UNIQUE_EMAIL: "with-unique-email",
  ONLY_WITH_LINKEDIN_PREMIUM: "only-with-linkedin-premium",
} as const;
export const LeadsListExportFilterParams = DelimitedArrayParam;

// mass actions prospector fetching jobs
export const MASS_ACTION_VIEW_JOBS = "mass-action-view-jobs";
export const MASS_ACTION_VIEWS_JOBS = {
  JOBS_DELETE: "jobs-delete",
  JOBS_RESUME: "jobs-resume",
  JOBS_PAUSE: "jobs-pause",
} as const;
export const JobsMassActionViewParams = createEnumParam([
  MASS_ACTION_VIEWS_JOBS.JOBS_DELETE,
  MASS_ACTION_VIEWS_JOBS.JOBS_RESUME,
  MASS_ACTION_VIEWS_JOBS.JOBS_PAUSE,
]);

// mass actions prospector leads lists
export const MASS_ACTION_VIEW_LEADS_LISTS = "mass-action-view-leads-lists";
export const MASS_ACTION_VIEWS_LEADS_LISTS = {
  LEADS_LISTS_DELETE: "leads-lists-delete",
  LEADS_LISTS_CSV_EXPORT: "leads-lists-csv-export",
  LEADS_LISTS_CONTACTS_LIST_EXPORT: "leads-lists-contacts-list-export",
} as const;
export const LeadsListsMassActionViewParams = createEnumParam([
  MASS_ACTION_VIEWS_LEADS_LISTS.LEADS_LISTS_DELETE,
  MASS_ACTION_VIEWS_LEADS_LISTS.LEADS_LISTS_CSV_EXPORT,
  MASS_ACTION_VIEWS_LEADS_LISTS.LEADS_LISTS_CONTACTS_LIST_EXPORT,
]);

// Billing
export const BILLING_TAB_PLAN_SELECTOR = "tab-plan-selector";
export const TABS_PLAN_SELECTOR = {
  FS_OUTREACH: "fs-outreach",
  FS_PROSPECTOR: "fs-prospector",
  AI_CREDITS: "ai-credits",
} as const;
export const TabPlanSelectorParams = createEnumParam([
  TABS_PLAN_SELECTOR.FS_OUTREACH,
  TABS_PLAN_SELECTOR.FS_PROSPECTOR,
  TABS_PLAN_SELECTOR.AI_CREDITS,
]);

// plans purchase
/**  it is technically dropdown-index but public url looks bad with it so value which user sees is `plan` */
export const PLAN_SELECTOR_DROPDOWN_INDEX = "plan";

export const PLAN_SELECTOR_SELECTED_PLAN_INTERVAL = "type";
export const PlanSelectorPlanIntervalParams = createEnumParam([
  "monthly",
  "yearly",
]);

export const SELECTED_PROSPECTOR_PLAN_ID = "selected-prospector-plan-id";
export const SELECTED_OUTREACH_PLAN_ID = "selected-outreach-plan-id";

// Settings -> Webhooks
export const SINGLE_WEBHOOK_ID = "single-webhook-id";
export const ADD_WEBHOOK_FILTER_OPEN = "add-webhook-filter-open";
export const EDIT_WEBHOOK_FILTER_INDEX = "edit-webhook-filter-index";
export const SHOW_SINGLE_WEBHOOK_CALLS = "show-webhook-calls";
export const WEBHOOK_SHOW_EXTENDED_OPTIONS = "show-extended-options";
export const FILTER_WEBHOOK_CALLS_BY_STATUS = "filter-webhook-calls-by-status";
export const SINGLE_WEBHOOK_CALL_ID = "single-webhook-call-id";
export const FILTER_WEBHOOK_CALL_LOGS_BY_STATUS =
  "filter-webhook-call-logs-by-status";
export const DIALOG_SINGLE_CALL_LOG_DETAILS_ID =
  "dialog-single-call-log-details-id";

// Admin

export const ADMIN_CHECK_IF_BANNED_OPEN = "check-if-banned-open";

export const TAB_ADMIN = "tab-admin";
export const TABS_ADMIN = {
  BAN: "ban",
  PROSPECTING: "prospecting",
} as const;
export const TabAdminParams = createEnumParam([
  TABS_ADMIN.BAN,
  TABS_ADMIN.PROSPECTING,
]);
