import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import DialogCreateBan from "./DialogCreateBan";
import { WORKSPACE_ID, routes } from "config";
import useActiveWorkspaceId from "hooks/useActiveWorkspaceId";
import { ADMIN_CHECK_IF_BANNED_OPEN, TABS_ADMIN, TAB_ADMIN } from "queryParams";
import { Link } from "react-router-dom";

type Props = {};

export default function ButtonsBanViewActions({}: Props) {
  const workspaceId = useActiveWorkspaceId();
  const [isCreateBanOpen, setIsCreateBanOpen] = useState(false);

  const checkBanUrl = `${routes.admin.path}?${WORKSPACE_ID}=${workspaceId}&${TAB_ADMIN}=${TABS_ADMIN.BAN}&${ADMIN_CHECK_IF_BANNED_OPEN}=1`;

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button onClick={() => setIsCreateBanOpen(true)}>Create ban</Button>
      </Grid>

      <Grid item>
        <Button component={Link} to={checkBanUrl}>
          Check if banned
        </Button>
      </Grid>

      {isCreateBanOpen ? (
        <DialogCreateBan
          isOpen={isCreateBanOpen}
          onClose={() => setIsCreateBanOpen(false)}
        />
      ) : null}
    </Grid>
  );
}
