import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  tenant: string;
  uniqueOnly: string;
  metricId: string;
  lastKnown?: any;
  dateFrom?: string;
  dateTo?: string;
  groupedByAttribute?: string;
  where?: Object;
};

export type typeResultData = {
  Items: {
    time: string;
    count: number;
    customerId: string;
    value?: string | number;
    sum?: number;
  }[];
  metricId: string;
  isThereMore: boolean;
  lastKnown: any;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___analyticsMetricDataQuery(___options: typeOptions) {
  return async function ({
    workspaceId,
    tenant,
    uniqueOnly,
    metricId,
    lastKnown,
    dateFrom,
    dateTo,
    groupedByAttribute,
    where,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      tenant, // (required) (undefined === null | "") Joi.string().min(0).max(300)
      uniqueOnly, // (required) (undefined === null | "") Joi.string().valid('no','yes')
      metricId, // (required) (undefined === null | "") Joi.string().min(0).max(300)
      lastKnown, // (optional) (undefined === null | "") Joi.alternatives(Joi.string().min(1).max(100), Joi.object().min(2).max(4))
      dateFrom, // (optional) (undefined === null | "") Joi.string().pattern(/^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}:[0-9]{2}$/).note("YYYY-MM-DD HH:MM")
      dateTo, // (optional) (undefined === null | "") Joi.string().pattern(/^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}:[0-9]{2}$/).note("YYYY-MM-DD HH:MM")
      groupedByAttribute, // (optional) (undefined === null | "") Joi.string().min(0).max(2000)
      where, // (optional) (undefined === null) Joi.object({ attribute: Joi.string().min(0).max(2000), value: Joi.alternatives(Joi.string().min(0).max(2000), Joi.number())})
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (tenant === null || tenant === "") {
      delete ___payload?.["tenant"];
    }

    if (uniqueOnly === null || uniqueOnly === "") {
      delete ___payload?.["uniqueOnly"];
    }

    if (metricId === null || metricId === "") {
      delete ___payload?.["metricId"];
    }

    if (lastKnown === null || lastKnown === "") {
      delete ___payload?.["lastKnown"];
    }

    if (dateFrom === null || dateFrom === "") {
      delete ___payload?.["dateFrom"];
    }

    if (dateTo === null || dateTo === "") {
      delete ___payload?.["dateTo"];
    }

    if (groupedByAttribute === null || groupedByAttribute === "") {
      delete ___payload?.["groupedByAttribute"];
    }

    if (where === null) {
      delete ___payload?.["where"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      tenant: Joi.string().min(0).max(300).required(),
      uniqueOnly: Joi.string().valid("no", "yes").required(),
      metricId: Joi.string().min(0).max(300).required(),
      lastKnown: Joi.alternatives(
        Joi.string().min(1).max(100),
        Joi.object().min(2).max(4)
      ),
      dateFrom: Joi.string()
        .pattern(/^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}:[0-9]{2}$/)
        .note("YYYY-MM-DD HH:MM"),
      dateTo: Joi.string()
        .pattern(/^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}:[0-9]{2}$/)
        .note("YYYY-MM-DD HH:MM"),
      groupedByAttribute: Joi.string().min(0).max(2000),
      where: Joi.object({
        attribute: Joi.string().min(0).max(2000),
        value: Joi.alternatives(Joi.string().min(0).max(2000), Joi.number()),
      }),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___analyticsMetricDataQuery" failed even before calling the network.
api/private/sdk/analytics/metric-data-query.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/analytics/metric-data-query",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
