import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/team/delete";
import useCallSdk from "../useCallSdk";

type Options = Omit<
  UseMutationOptions<typeResult, unknown, typePayload, unknown>,
  "mutationFn"
> & {
  onSuccess?: (
    data: typeResult,
    variables: typePayload,
    context: unknown
  ) => unknown;
  doWaitForDependentQueries?: boolean;
};

export default function useTeamDelete(_options?: Options) {
  const _sdk = useCallSdk();
  const _queryClient = useQueryClient();
  _options = _options || {};

  return useMutation(_sdk.callSdk().teamDelete, {
    ..._options,
    onSuccess: (data, variables, context) => {
      if (_options?.onSuccess) {
        _options?.onSuccess(
          data as typeResult,
          variables as typePayload,
          context as unknown
        );
      }

      let _promises = Promise.all([
        _queryClient.invalidateQueries(["workspace:list"]),
        _queryClient.invalidateQueries(["workspace:get-meta-stats"]),
        _queryClient.invalidateQueries(["team:list"]),
        _queryClient.invalidateQueries(["team:get"]),
      ]);
      return _options?.doWaitForDependentQueries === true ? _promises : null;
    },
  });
}
