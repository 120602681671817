import useActiveWorkspaceId from "../../hooks/useActiveWorkspaceId";
import useWorkspaceGetMetaStats from "../../utils/reactQueryHooks/workspace/useWorkspaceGetMetaStats";
import { Grid, Typography, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import ProgressStatus from "./ProgressStatus";
import ProgressItem from "./ProgressItem";

export default function SidebarProgress() {
  const workspaceId = useActiveWorkspaceId();
  const workspaceStats = useWorkspaceGetMetaStats({ workspaceId });
  const theme = useTheme();
  const navigate = useNavigate();

  const stats = workspaceStats.data?.data;
  let areContactsUploaded = Boolean(stats?.totalContacts);
  let isCampaignCreated = Boolean(stats?.totalCampaigns);
  let isMailAddedForSending = Boolean(stats?.totalActiveMailAccountsForSending);
  let isMailAddedForTracking = Boolean(
    stats?.totalActiveMailAccountsForTracking
  );
  let isDomainAdded = Boolean(stats?.totalActiveDomains);
  let isCampaignRunning = Boolean(stats?.totalRunningCampaigns);
  let isMailAdded = isMailAddedForSending && isMailAddedForTracking;

  let isAllDone =
    areContactsUploaded &&
    isCampaignCreated &&
    isDomainAdded &&
    isCampaignRunning &&
    isMailAdded;

  let doneCount = 0;

  if (areContactsUploaded) {
    doneCount++;
  }
  if (isCampaignCreated) {
    doneCount++;
  }
  if (isDomainAdded) {
    doneCount++;
  }
  if (isCampaignRunning) {
    doneCount++;
  }
  if (isMailAdded) {
    doneCount++;
  }

  let doShow =
    typeof workspaceStats?.data?.data?.totalContacts !== "undefined" &&
    isAllDone === false;

  return doShow ? (
    <Grid item xs={12}>
      <div
        style={{
          padding: theme.spacing(2),
          background: blue["600"],
          color: "#FFF",
        }}
      >
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12} container spacing={1}>
            <ProgressItem
              isDone={Boolean(stats?.totalContacts)}
              label="Upload contacts"
              onClick={() => navigate(`/contacts?workspace-id=${workspaceId}`)}
              showLineThrough={Boolean(stats?.totalContacts)}
            />
            <ProgressItem
              isDone={Boolean(stats?.totalCampaigns)}
              label="Create campaign"
              onClick={() => navigate(`/campaigns?workspace-id=${workspaceId}`)}
              showLineThrough={Boolean(stats?.totalCampaigns)}
            />
            <ProgressItem
              isDone={Boolean(
                stats?.totalActiveMailAccountsForSending &&
                  stats?.totalActiveMailAccountsForTracking
              )}
              label="Add mail account for sending & tracking"
              onClick={() =>
                navigate(
                  `/settings?workspace-id=${workspaceId}&tab-settings=emails-and-domains`
                )
              }
              showLineThrough={Boolean(
                stats?.totalActiveMailAccountsForSending &&
                  stats?.totalActiveMailAccountsForTracking
              )}
            />
            <ProgressItem
              isDone={Boolean(stats?.totalActiveDomains)}
              label="Added valid domain for open/click tracking and unsubscribe"
              onClick={() =>
                navigate(
                  `/settings?workspace-id=${workspaceId}&tab-settings=emails-and-domains`
                )
              }
              showLineThrough={Boolean(stats?.totalActiveDomains)}
            />
            <ProgressItem
              isDone={Boolean(stats?.totalRunningCampaigns)}
              label="Run campaign"
              onClick={() => navigate(`/campaigns?workspace-id=${workspaceId}`)}
              showLineThrough={Boolean(stats?.totalRunningCampaigns)}
            />
            {doneCount > 0 ? (
              <ProgressStatus doneCount={doneCount} totalCount={5} />
            ) : (
              <Grid item xs={12}>
                <Typography
                  variant={"body2"}
                  style={{
                    color: "inherit",
                    textAlign: "center",
                    marginTop: theme.spacing(1),
                  }}
                  component={"div"}
                >
                  Let's go 🚀
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
  ) : null;
}
