import _setDefaultHeaders from "./setDefaultHeaders";
import workspaceUpdate from "./workspace/update";
import workspaceRestart from "./workspace/restart";
import workspaceList from "./workspace/list";
import workspaceGetMetaStats from "./workspace/get-meta-stats";
import workspaceGet from "./workspace/get";
import workspaceDelete from "./workspace/delete";
import workspaceCreate from "./workspace/create";
import workspaceContactAttributeList from "./workspace/contact-attribute-list";
import workspaceBillingRecheck from "./workspace/billing-recheck";
import workspaceAttributesList from "./workspace-attributes/list";
import webhookUpdate from "./webhook/update";
import webhookList from "./webhook/list";
import webhookGet from "./webhook/get";
import webhookDelete from "./webhook/delete";
import webhookCreate from "./webhook/create";
import webhookCallList from "./webhook-call/list";
import webhookCallGet from "./webhook-call/get";
import webhookCallLogsList from "./webhook-call-logs/list";
import webhookCallLogGet from "./webhook-call-log/get";
import userGetTrialDiscount from "./user/get-trial-discount";
import userGetMeta from "./user/get-meta";
import templateUpdate from "./template/update";
import templateList from "./template/list";
import templateGet from "./template/get";
import templateDelete from "./template/delete";
import templateCreate from "./template/create";
import teamUpdate from "./team/update";
import teamResetInvitationUrl from "./team/reset-invitation-url";
import teamList from "./team/list";
import teamGetMyProfile from "./team/get-my-profile";
import teamGetInvitationUrl from "./team/get-invitation-url";
import teamGetInvitationDetails from "./team/get-invitation-details";
import teamGet from "./team/get";
import teamDelete from "./team/delete";
import teamAcceptInvitationUrl from "./team/accept-invitation-url";
import tagsList from "./tags/list";
import subscriptionsListActive from "./subscriptions/list-active";
import stripeRawWebhook from "./stripe/raw-webhook";
import signatureUpdate from "./signature/update";
import signatureUnselect from "./signature/unselect";
import signatureSelect from "./signature/select";
import signatureList from "./signature/list";
import signatureGetSelected from "./signature/get-selected";
import signatureGet from "./signature/get";
import signatureDelete from "./signature/delete";
import signatureCreate from "./signature/create";
import sequenceUpdate from "./sequence/update";
import sequenceList from "./sequence/list";
import sequenceGet from "./sequence/get";
import sequenceDelete from "./sequence/delete";
import sequenceCreate from "./sequence/create";
import sendingQueueStatsGet from "./sending-queue-stats/get";
import segmentUpdate from "./segment/update";
import segmentList from "./segment/list";
import segmentGet from "./segment/get";
import segmentDelete from "./segment/delete";
import segmentCreate from "./segment/create";
import segmentContactList from "./segment-contact/list";
import searchNodeUpdate from "./search-node/update";
import searchNodeList from "./search-node/list";
import searchNodeDelete from "./search-node/delete";
import searchNodeAdd from "./search-node/add";
import searchNodeDocumentSet from "./search-node-document/set";
import searchNodeDocumentDelete from "./search-node-document/delete";
import prospectingCreditsStats from "./prospecting-credits/stats";
import prospectingBucketList from "./prospecting-bucket/list";
import prospectingBucketHaveIUsedTrialCredits from "./prospecting-bucket/have-i-used-trial-credits";
import prospectingBucketCreateTrial from "./prospecting-bucket/create-trial";
import profileUpdate from "./profile/update";
import profileImageRemove from "./profile/image-remove";
import profileGetImageUploadUrl from "./profile/get-image-upload-url";
import productDescriptionUpdate from "./product-description/update";
import productDescriptionList from "./product-description/list";
import productDescriptionGet from "./product-description/get";
import productDescriptionDelete from "./product-description/delete";
import productDescriptionCreate from "./product-description/create";
import planList from "./plan/list";
import planItemLogList from "./plan-item/log-list";
import planItemGet from "./plan-item/get";
import planItemDelete from "./plan-item/delete";
import openaiKeyList from "./openai-key/list";
import openaiKeyDelete from "./openai-key/delete";
import openaiKeyAdd from "./openai-key/add";
import mailUpdate from "./mail/update";
import mailTestSend from "./mail/test-send";
import mailTestReceive from "./mail/test-receive";
import mailMailboxSet from "./mail/mailbox-set";
import mailMailboxList from "./mail/mailbox-list";
import mailMailboxGet from "./mail/mailbox-get";
import mailLogList from "./mail/log-list";
import mailList from "./mail/list";
import mailGet from "./mail/get";
import mailDetectProvider from "./mail/detect-provider";
import mailDelete from "./mail/delete";
import mailCheckInbox from "./mail/check-inbox";
import mailAuthorize from "./mail/authorize";
import mailAdd from "./mail/add";
import mailMessageList from "./mail-message/list";
import mailMessageGet from "./mail-message/get";
import mailMessageCheck from "./mail-message/check";
import mailLimitsSet from "./mail-limits/set";
import mailAuthorizeRemove from "./mail-authorize/remove";
import mailAuthorizeGetGmailUrl from "./mail-authorize/get-gmail-url";
import listUpdate from "./list/update";
import listList from "./list/list";
import listGet from "./list/get";
import listDelete from "./list/delete";
import listCreate from "./list/create";
import listContactRemove from "./list/contact-remove";
import listContactList from "./list/contact-list";
import listContactAdd from "./list/contact-add";
import linkedinJobUploadData from "./linkedin-job/upload-data";
import linkedinJobUpdate from "./linkedin-job/update";
import linkedinJobList from "./linkedin-job/list";
import linkedinJobDescribe from "./linkedin-job/describe";
import linkedinJobDelete from "./linkedin-job/delete";
import linkedinJobCreate from "./linkedin-job/create";
import linkedinCompaniesCheck from "./linkedin-companies/check";
import leadsList from "./leads/list";
import leadsShareLinkUpdate from "./leads-share-link/update";
import leadsShareLinkList from "./leads-share-link/list";
import leadsShareLinkGet from "./leads-share-link/get";
import leadsShareLinkDelete from "./leads-share-link/delete";
import leadsShareLinkCreate from "./leads-share-link/create";
import leadsListUpdate from "./leads-list/update";
import leadsListStats from "./leads-list/stats";
import leadsListList from "./leads-list/list";
import leadsListGet from "./leads-list/get";
import leadsListDelete from "./leads-list/delete";
import leadsListCreate from "./leads-list/create";
import jobList from "./job/list";
import jobGet from "./job/get";
import jobCreate from "./job/create";
import executionList from "./execution/list";
import executionDelay from "./execution/delay";
import domainValidate from "./domain/validate";
import domainSuspend from "./domain/suspend";
import domainList from "./domain/list";
import domainGet from "./domain/get";
import domainDelete from "./domain/delete";
import domainCreate from "./domain/create";
import contactWarm from "./contact/warm";
import contactValidate from "./contact/validate";
import contactUpdate from "./contact/update";
import contactUnsuspend from "./contact/unsuspend";
import contactSuspend from "./contact/suspend";
import contactLogList from "./contact/log-list";
import contactList from "./contact/list";
import contactGetMetricIds from "./contact/get-metric-ids";
import contactGet from "./contact/get";
import contactDelete from "./contact/delete";
import contactCreate from "./contact/create";
import contactCold from "./contact/cold";
import contactSegmentList from "./contact-segment/list";
import contactListList from "./contact-list/list";
import contactAttributeDeleteFromWorkspaceAll from "./contact-attribute/delete-from-workspace-all";
import contactAttributeDeleteFromWorkspace from "./contact-attribute/delete-from-workspace";
import campaignUpdate from "./campaign/update";
import campaignStop from "./campaign/stop";
import campaignStart from "./campaign/start";
import campaignList from "./campaign/list";
import campaignGetMetricsIds from "./campaign/get-metrics-ids";
import campaignGet from "./campaign/get";
import campaignDelete from "./campaign/delete";
import campaignCreate from "./campaign/create";
import campaignMailRemove from "./campaign-mail/remove";
import campaignMailList from "./campaign-mail/list";
import campaignMailAdd from "./campaign-mail/add";
import campaignAiSubjectDelete from "./campaign-ai-subject/delete";
import campaignAiFormSave from "./campaign-ai-form/save";
import campaignAiFormGet from "./campaign-ai-form/get";
import billingGetStripePortalUrl from "./billing/get-stripe-portal-url";
import billingBuyProspecting from "./billing/buy-prospecting";
import billingBuyPlan from "./billing/buy-plan";
import authLogout from "./auth/logout";
import authLinkedin from "./auth/linkedin";
import authGoogle from "./auth/google";
import authFacebook from "./auth/facebook";
import authApple from "./auth/apple";
import apiKeyUpdate from "./api-key/update";
import apiKeyList from "./api-key/list";
import apiKeyGet from "./api-key/get";
import apiKeyDelete from "./api-key/delete";
import apiKeyCreate from "./api-key/create";
import analyticsMetricListAttributeValues from "./analytics/metric-list-attribute-values";
import analyticsMetricList from "./analytics/metric-list";
import analyticsMetricGet from "./analytics/metric-get";
import analyticsMetricDataQuery from "./analytics/metric-data-query";
import analyticsMetricAttributeList from "./analytics/metric-attribute-list";
import analyticsEventList from "./analytics/event-list";
import analyticsEventGet from "./analytics/event-get";
import analyticsCustomerList from "./analytics/customer-list";
import analyticsCustomerGet from "./analytics/customer-get";
import analyticsCustomerAttributesList from "./analytics/customer-attributes-list";
import analyticsCustomerAttributeValuesList from "./analytics/customer-attribute-values-list";
import adminWorkspaceAttributeSet from "./admin/workspace-attribute-set";
import adminWorkspaceAttributeDelete from "./admin/workspace-attribute-delete";
import adminSupportLeaveWorkspace from "./admin/support-leave-workspace";
import adminProspectingBucketDelete from "./admin/prospecting-bucket-delete";
import adminProspectingBucketCreate from "./admin/prospecting-bucket-create";
import adminListCustomDomains from "./admin/list-custom-domains";
import adminJoinAsSupport from "./admin/join-as-support";
import adminSuspendList from "./admin-suspend/list";
import adminSuspendGet from "./admin-suspend/get";
import adminSuspendDelete from "./admin-suspend/delete";
import adminSuspendCreate from "./admin-suspend/create";

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function sdk(options?: typeOptions) {
  options = options || {};
  options.apiHost = options.apiHost || "http://localhost:11136/private";
  options.headers = _setDefaultHeaders({ headers: options.headers });

  return {
    workspaceUpdate: workspaceUpdate(options),
    workspaceRestart: workspaceRestart(options),
    workspaceList: workspaceList(options),
    workspaceGetMetaStats: workspaceGetMetaStats(options),
    workspaceGet: workspaceGet(options),
    workspaceDelete: workspaceDelete(options),
    workspaceCreate: workspaceCreate(options),
    workspaceContactAttributeList: workspaceContactAttributeList(options),
    workspaceBillingRecheck: workspaceBillingRecheck(options),
    workspaceAttributesList: workspaceAttributesList(options),
    webhookUpdate: webhookUpdate(options),
    webhookList: webhookList(options),
    webhookGet: webhookGet(options),
    webhookDelete: webhookDelete(options),
    webhookCreate: webhookCreate(options),
    webhookCallList: webhookCallList(options),
    webhookCallGet: webhookCallGet(options),
    webhookCallLogsList: webhookCallLogsList(options),
    webhookCallLogGet: webhookCallLogGet(options),
    userGetTrialDiscount: userGetTrialDiscount(options),
    userGetMeta: userGetMeta(options),
    templateUpdate: templateUpdate(options),
    templateList: templateList(options),
    templateGet: templateGet(options),
    templateDelete: templateDelete(options),
    templateCreate: templateCreate(options),
    teamUpdate: teamUpdate(options),
    teamResetInvitationUrl: teamResetInvitationUrl(options),
    teamList: teamList(options),
    teamGetMyProfile: teamGetMyProfile(options),
    teamGetInvitationUrl: teamGetInvitationUrl(options),
    teamGetInvitationDetails: teamGetInvitationDetails(options),
    teamGet: teamGet(options),
    teamDelete: teamDelete(options),
    teamAcceptInvitationUrl: teamAcceptInvitationUrl(options),
    tagsList: tagsList(options),
    subscriptionsListActive: subscriptionsListActive(options),
    stripeRawWebhook: stripeRawWebhook(options),
    signatureUpdate: signatureUpdate(options),
    signatureUnselect: signatureUnselect(options),
    signatureSelect: signatureSelect(options),
    signatureList: signatureList(options),
    signatureGetSelected: signatureGetSelected(options),
    signatureGet: signatureGet(options),
    signatureDelete: signatureDelete(options),
    signatureCreate: signatureCreate(options),
    sequenceUpdate: sequenceUpdate(options),
    sequenceList: sequenceList(options),
    sequenceGet: sequenceGet(options),
    sequenceDelete: sequenceDelete(options),
    sequenceCreate: sequenceCreate(options),
    sendingQueueStatsGet: sendingQueueStatsGet(options),
    segmentUpdate: segmentUpdate(options),
    segmentList: segmentList(options),
    segmentGet: segmentGet(options),
    segmentDelete: segmentDelete(options),
    segmentCreate: segmentCreate(options),
    segmentContactList: segmentContactList(options),
    searchNodeUpdate: searchNodeUpdate(options),
    searchNodeList: searchNodeList(options),
    searchNodeDelete: searchNodeDelete(options),
    searchNodeAdd: searchNodeAdd(options),
    searchNodeDocumentSet: searchNodeDocumentSet(options),
    searchNodeDocumentDelete: searchNodeDocumentDelete(options),
    prospectingCreditsStats: prospectingCreditsStats(options),
    prospectingBucketList: prospectingBucketList(options),
    prospectingBucketHaveIUsedTrialCredits:
      prospectingBucketHaveIUsedTrialCredits(options),
    prospectingBucketCreateTrial: prospectingBucketCreateTrial(options),
    profileUpdate: profileUpdate(options),
    profileImageRemove: profileImageRemove(options),
    profileGetImageUploadUrl: profileGetImageUploadUrl(options),
    productDescriptionUpdate: productDescriptionUpdate(options),
    productDescriptionList: productDescriptionList(options),
    productDescriptionGet: productDescriptionGet(options),
    productDescriptionDelete: productDescriptionDelete(options),
    productDescriptionCreate: productDescriptionCreate(options),
    planList: planList(options),
    planItemLogList: planItemLogList(options),
    planItemGet: planItemGet(options),
    planItemDelete: planItemDelete(options),
    openaiKeyList: openaiKeyList(options),
    openaiKeyDelete: openaiKeyDelete(options),
    openaiKeyAdd: openaiKeyAdd(options),
    mailUpdate: mailUpdate(options),
    mailTestSend: mailTestSend(options),
    mailTestReceive: mailTestReceive(options),
    mailMailboxSet: mailMailboxSet(options),
    mailMailboxList: mailMailboxList(options),
    mailMailboxGet: mailMailboxGet(options),
    mailLogList: mailLogList(options),
    mailList: mailList(options),
    mailGet: mailGet(options),
    mailDetectProvider: mailDetectProvider(options),
    mailDelete: mailDelete(options),
    mailCheckInbox: mailCheckInbox(options),
    mailAuthorize: mailAuthorize(options),
    mailAdd: mailAdd(options),
    mailMessageList: mailMessageList(options),
    mailMessageGet: mailMessageGet(options),
    mailMessageCheck: mailMessageCheck(options),
    mailLimitsSet: mailLimitsSet(options),
    mailAuthorizeRemove: mailAuthorizeRemove(options),
    mailAuthorizeGetGmailUrl: mailAuthorizeGetGmailUrl(options),
    listUpdate: listUpdate(options),
    listList: listList(options),
    listGet: listGet(options),
    listDelete: listDelete(options),
    listCreate: listCreate(options),
    listContactRemove: listContactRemove(options),
    listContactList: listContactList(options),
    listContactAdd: listContactAdd(options),
    linkedinJobUploadData: linkedinJobUploadData(options),
    linkedinJobUpdate: linkedinJobUpdate(options),
    linkedinJobList: linkedinJobList(options),
    linkedinJobDescribe: linkedinJobDescribe(options),
    linkedinJobDelete: linkedinJobDelete(options),
    linkedinJobCreate: linkedinJobCreate(options),
    linkedinCompaniesCheck: linkedinCompaniesCheck(options),
    leadsList: leadsList(options),
    leadsShareLinkUpdate: leadsShareLinkUpdate(options),
    leadsShareLinkList: leadsShareLinkList(options),
    leadsShareLinkGet: leadsShareLinkGet(options),
    leadsShareLinkDelete: leadsShareLinkDelete(options),
    leadsShareLinkCreate: leadsShareLinkCreate(options),
    leadsListUpdate: leadsListUpdate(options),
    leadsListStats: leadsListStats(options),
    leadsListList: leadsListList(options),
    leadsListGet: leadsListGet(options),
    leadsListDelete: leadsListDelete(options),
    leadsListCreate: leadsListCreate(options),
    jobList: jobList(options),
    jobGet: jobGet(options),
    jobCreate: jobCreate(options),
    executionList: executionList(options),
    executionDelay: executionDelay(options),
    domainValidate: domainValidate(options),
    domainSuspend: domainSuspend(options),
    domainList: domainList(options),
    domainGet: domainGet(options),
    domainDelete: domainDelete(options),
    domainCreate: domainCreate(options),
    contactWarm: contactWarm(options),
    contactValidate: contactValidate(options),
    contactUpdate: contactUpdate(options),
    contactUnsuspend: contactUnsuspend(options),
    contactSuspend: contactSuspend(options),
    contactLogList: contactLogList(options),
    contactList: contactList(options),
    contactGetMetricIds: contactGetMetricIds(options),
    contactGet: contactGet(options),
    contactDelete: contactDelete(options),
    contactCreate: contactCreate(options),
    contactCold: contactCold(options),
    contactSegmentList: contactSegmentList(options),
    contactListList: contactListList(options),
    contactAttributeDeleteFromWorkspaceAll:
      contactAttributeDeleteFromWorkspaceAll(options),
    contactAttributeDeleteFromWorkspace:
      contactAttributeDeleteFromWorkspace(options),
    campaignUpdate: campaignUpdate(options),
    campaignStop: campaignStop(options),
    campaignStart: campaignStart(options),
    campaignList: campaignList(options),
    campaignGetMetricsIds: campaignGetMetricsIds(options),
    campaignGet: campaignGet(options),
    campaignDelete: campaignDelete(options),
    campaignCreate: campaignCreate(options),
    campaignMailRemove: campaignMailRemove(options),
    campaignMailList: campaignMailList(options),
    campaignMailAdd: campaignMailAdd(options),
    campaignAiSubjectDelete: campaignAiSubjectDelete(options),
    campaignAiFormSave: campaignAiFormSave(options),
    campaignAiFormGet: campaignAiFormGet(options),
    billingGetStripePortalUrl: billingGetStripePortalUrl(options),
    billingBuyProspecting: billingBuyProspecting(options),
    billingBuyPlan: billingBuyPlan(options),
    authLogout: authLogout(options),
    authLinkedin: authLinkedin(options),
    authGoogle: authGoogle(options),
    authFacebook: authFacebook(options),
    authApple: authApple(options),
    apiKeyUpdate: apiKeyUpdate(options),
    apiKeyList: apiKeyList(options),
    apiKeyGet: apiKeyGet(options),
    apiKeyDelete: apiKeyDelete(options),
    apiKeyCreate: apiKeyCreate(options),
    analyticsMetricListAttributeValues:
      analyticsMetricListAttributeValues(options),
    analyticsMetricList: analyticsMetricList(options),
    analyticsMetricGet: analyticsMetricGet(options),
    analyticsMetricDataQuery: analyticsMetricDataQuery(options),
    analyticsMetricAttributeList: analyticsMetricAttributeList(options),
    analyticsEventList: analyticsEventList(options),
    analyticsEventGet: analyticsEventGet(options),
    analyticsCustomerList: analyticsCustomerList(options),
    analyticsCustomerGet: analyticsCustomerGet(options),
    analyticsCustomerAttributesList: analyticsCustomerAttributesList(options),
    analyticsCustomerAttributeValuesList:
      analyticsCustomerAttributeValuesList(options),
    adminWorkspaceAttributeSet: adminWorkspaceAttributeSet(options),
    adminWorkspaceAttributeDelete: adminWorkspaceAttributeDelete(options),
    adminSupportLeaveWorkspace: adminSupportLeaveWorkspace(options),
    adminProspectingBucketDelete: adminProspectingBucketDelete(options),
    adminProspectingBucketCreate: adminProspectingBucketCreate(options),
    adminListCustomDomains: adminListCustomDomains(options),
    adminJoinAsSupport: adminJoinAsSupport(options),
    adminSuspendList: adminSuspendList(options),
    adminSuspendGet: adminSuspendGet(options),
    adminSuspendDelete: adminSuspendDelete(options),
    adminSuspendCreate: adminSuspendCreate(options),
  };
}
