import { CircularProgress, Fade, TextField } from "@mui/material";
import useWorkspaceList from "utils/reactQueryHooks/workspace/useWorkspaceList";

export default function SelectWorkspace({
  onChange,
  value,
}: {
  onChange: (value: string) => void;
  value: string;
}) {
  const workspaceListQuery = useWorkspaceList({
    perPage: 1000,
  });

  const options: React.ReactNode[] = [];

  workspaceListQuery.data?.data?.Items?.forEach((workspace) => {
    options.push(
      <option key={workspace.workspaceId} value={workspace.workspaceId}>
        {workspace.title}
      </option>
    );
  });

  if (options.length === 0) {
    options.push(
      <option key={"empty"} disabled value={"-"}>
        No workspaces yet
      </option>
    );
  }

  return (
    <TextField
      fullWidth
      label={
        <span>
          Select workspace
          {workspaceListQuery.isLoading ? (
            <Fade in={workspaceListQuery.isLoading}>
              <CircularProgress size={12} />
            </Fade>
          ) : null}
        </span>
      }
      size="small"
      select
      SelectProps={{ native: true }}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => onChange(e.target.value)}
      value={value}
    >
      <option disabled value=""></option>
      {options}
    </TextField>
  );
}
