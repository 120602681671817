import { ArrowBackRounded } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { WORKSPACE_ID, routes } from "config";
import useActiveWorkspaceId from "hooks/useActiveWorkspaceId";
import { TABS_ADMIN, TAB_ADMIN } from "queryParams";
import { useState } from "react";
import { Link } from "react-router-dom";
import EnterValueToCheck from "./EnterValueToCheck";
import BanCheckResults from "./BanCheckResults";

type Props = {};

export default function CheckIfBanned({}: Props) {
  const workspaceId = useActiveWorkspaceId();

  const bansViewUrl = `${routes.admin.path}?${WORKSPACE_ID}=${workspaceId}&${TAB_ADMIN}=${TABS_ADMIN.BAN}`;
  const [valuesToCheck, setValuesToCheck] = useState<string[]>([]);

  return (
    <>
      <div>
        <Button
          startIcon={<ArrowBackRounded />}
          component={Link}
          to={bansViewUrl}
          size="small"
        >
          Bans
        </Button>
      </div>

      <div style={{ maxWidth: 600, margin: "auto" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <EnterValueToCheck
              valuesToCheck={valuesToCheck}
              setValuesToCheck={setValuesToCheck}
            />
          </Grid>

          <BanCheckResults valuesToCheck={valuesToCheck} />
        </Grid>
      </div>
    </>
  );
}
