import sdk from "../sdk";
import { UAParser } from "ua-parser-js";
import { useSnackbar } from "notistack";

import { config } from "config";

import getAccessToken from "utils/providers/AuthProvider/helpers/getAccessToken";
import getAccessTokenExpiresAt from "utils/providers/AuthProvider/helpers/getAccessTokenExpiresAt";
import { useAuth } from "utils/providers/AuthProvider";
import getValidSemverEnv from "./getValidSemverEnv";

export default function useCallSdk() {
  const auth = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const logoutIfTokenExpired = () => {
    const accessTokenExpiresAt = getAccessTokenExpiresAt();

    if (
      auth.isLoggedIn &&
      accessTokenExpiresAt &&
      accessTokenExpiresAt < Math.floor(Date.now() / 1000)
    ) {
      enqueueSnackbar("Session expired, please login again");
      auth.removeAuthorizedUser();
    }
  };

  const callSdk = () => {
    logoutIfTokenExpired();

    const parser = new UAParser();
    const parserResult = parser.getResult();
    const environment = [
      parserResult.browser.name,
      parserResult.device.model,
      parserResult.device.type,
      parserResult.device.vendor,
      parserResult.os.name,
      parserResult.os.version,
      parserResult.cpu.architecture,
    ]
      .filter((_env) => Boolean(_env))
      .join(" ");

    return sdk({
      apiHost: config.apiHost,
      headers: {
        "x-access-token": getAccessToken() || "",
        "x-environment": environment,
        "x-environment-version": getValidSemverEnv(navigator.userAgent),
      },
    });
  };

  return { callSdk };
}
