import { Container, Grid, Typography, useTheme } from "@mui/material";
import useActiveWorkspaceId from "hooks/useActiveWorkspaceId";
import { useNavigate } from "react-router-dom";
import { purple } from "@mui/material/colors";
import useTeamDelete from "../../utils/reactQueryHooks/team/useTeamDelete";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import useUserGetMeta from "../../utils/reactQueryHooks/user/useUserGetMeta";

export default function HelloBarJoinedAsSupport() {
  const theme = useTheme();
  const navigate = useNavigate();
  const workspaceId = useActiveWorkspaceId();
  const { enqueueSnackbar } = useSnackbar();
  let userMeta = useUserGetMeta();
  let userId = userMeta?.data?.data?.userId || "";

  const leaveWorkspace = useTeamDelete({
    onSuccess: () => {
      navigate(`/workspaces`);
      enqueueSnackbar("Left workspace", { variant: "success" });
    },
  });

  return (
    <Grid
      style={{
        padding: theme.spacing(0, 2),
        background: purple[500],
        color: "#FFFFFF",
      }}
      item
      xs={12}
      container
      justifyContent="center"
    >
      <Container maxWidth={"md"}>
        <Grid
          item
          xs={12}
          container
          alignItems={"center"}
          spacing={2}
          justifyContent={"center"}
        >
          <Grid item>
            <Typography
              variant={"body1"}
              style={{
                color: "#FFFFFF",
                fontWeight: "bold",
                textAlign: "center",
              }}
              component={"div"}
            >
              Joined as support
            </Typography>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={leaveWorkspace.isLoading}
              onClick={() => {
                leaveWorkspace.mutate({ workspaceId, userId });
              }}
              size={"small"}
              variant={"outlined"}
              style={{
                margin: theme.spacing(2),
                whiteSpace: "nowrap",
                color: "#FFFFFF",
                borderColor: "#FFFFFF",
              }}
            >
              Leave workspace
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
