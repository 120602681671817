import { createTheme, ThemeProvider } from "@mui/material";
import { amber, blue, green, red } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { ReactNode } from "react";
import useGetThemeMode from "../useGetThemeMode";

export default function MuiThemeProvider({
  children,
}: {
  children: ReactNode;
}) {
  const themeMode = useGetThemeMode();

  const theme = createTheme({
    palette: {
      mode: themeMode,
      primary: {
        main: "#FF8E00",
        light: "#FFA433",
        dark: "#B26300",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: blue[600],
        light: blue[400],
        dark: blue[800],
        contrastText: "#FFFFFF",
      },
      success: {
        main: green[500],
        light: green[300],
        dark: green[800],
        contrastText: "#FFFFFF",
      },
      warning: {
        main: amber[500],
        light: amber[300],
        dark: amber[800],
        contrastText: "#FFFFFF",
      },
      error: {
        main: red[500],
        light: red[300],
        dark: red[800],
        contrastText: "#FFFFFF",
      },
      background: {
        default: themeMode === "dark" ? "#20232A" : "#FAFBFB",
        paper: themeMode === "dark" ? "#282C34" : "#FFFFFF",
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
      h1: { fontSize: "3rem" },
      h2: { fontSize: "2.5rem" },
      h3: {
        fontSize: "2.5rem", // time-pickers get messed if we make it smaller or allow margins
        // marginTop: 24,
        // marginBottom: 24,
        fontWeight: 100,
      },
      h4: { fontSize: "1.7rem", marginTop: 24, marginBottom: 24 },
      h5: { fontSize: "1.5rem" },
      h6: { fontSize: "1.25rem" },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: { textTransform: "none", borderRadius: "8px" },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: { borderRadius: "10px" },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            opacity: 1,
          },
          tooltip: {
            backgroundColor: "#424242",
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          anchorOriginBottomLeft: {
            bottom: "44px !important",
          },
        },
      },
      MuiCircularProgress: {
        defaultProps: {
          disableShrink: true,
        },
      },
      MuiTableRow: {
        defaultProps: {
          hover: true,
        },
      },
      MuiTypography: {
        defaultProps: {
          color: "text.primary",
          variant: "body1",
        },
      },
    },
  });

  // @ts-ignore
  theme.components.MuiDialog = {
    defaultProps: {
      open: true,
      scroll: "body",
      fullWidth: true,
      maxWidth: "xs",
      PaperProps: {
        style: {
          margin: 8,
          width: "100%",
          padding: 0,
        },
      },
      sx: {
        "& .MuiDialog-container .MuiDialog-paper": {
          [theme.breakpoints.down("sm")]: {
            maxHeight: "100%",
            maxWidth: "95%",
          },
        },
      },
    },
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  );
}
