export default function useGetThemeMode(): "dark" | "light" {
  let themeMode: "dark" | "light" =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches === true
      ? "dark"
      : "light";

  let themeFromLocalStorage = localStorage.getItem("ui-theme") || "";

  if (themeFromLocalStorage === "dark" || themeFromLocalStorage === "light") {
    themeMode = themeFromLocalStorage;
  } else if (localStorage.getItem("ui-theme")) {
    localStorage.removeItem("ui-theme");
  }

  return themeMode;
}
