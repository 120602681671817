import { Button, Container, Grid, Typography, useTheme } from "@mui/material";
import useActiveWorkspaceId from "hooks/useActiveWorkspaceId";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import TickingJsx from "../partials/TickingJsx";
import useUserGetTrialDiscount from "../../utils/reactQueryHooks/user/useUserGetTrialDiscount";
import Timer from "./Timer";
import { useBlockableNavigation } from "utils/providers/BlockableNavigationProvider";
import { useAuth } from "utils/providers/AuthProvider";
import { routes } from "config";

export default function HelloBar7dTrial() {
  const theme = useTheme();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const workspaceId = useActiveWorkspaceId();
  const offer = useUserGetTrialDiscount(
    {},
    {
      refetchInterval: 30000,
      enabled: auth.isLoggedIn,
    }
  );
  const offerEndInSeconds = offer?.data?.data?.offerEndInSeconds || 0;
  const offerEndAt = Math.floor(Date.now() / 1000) + offerEndInSeconds;

  let content: null | ReactNode = null;

  const { blockableNavigate } = useBlockableNavigation();

  if (offerEndInSeconds && offer?.data?.data?.percentOff === 10) {
    content = (
      <TickingJsx
        fnToTick={() => {
          return offerEndInSeconds > 0 ? (
            <>
              Final call! A 10% subscription discount is still up for grabs!
              Delay no more, this is your last chance for savings!
            </>
          ) : (
            <>Offer is over :(</>
          );
        }}
      />
    );
  }

  if (offerEndInSeconds && offer?.data?.data?.percentOff === 15) {
    content = (
      <TickingJsx
        fnToTick={() => {
          return offerEndInSeconds > 0 ? (
            <>
              Quick reminder: you still have a chance to snag a 15% discount on
              your subscription! Don’t miss out!
            </>
          ) : (
            <>Offer is over :(</>
          );
        }}
      />
    );
  }

  if (offerEndInSeconds && offer?.data?.data?.percentOff === 20) {
    content = (
      <TickingJsx
        fnToTick={() => {
          return offerEndInSeconds > 0 ? (
            <>
              Skip the trial now and get in early to enjoy up to 20% off your
              subscription - delay, and the discount drops!
            </>
          ) : (
            <>Offer is over :(</>
          );
        }}
      />
    );
  }

  const isPickingPlans =
    location.pathname === routes.billing.path ||
    location.pathname === routes.fs_prospector_plans.path ||
    location.pathname === routes.fs_outreach_plans.path ||
    location.pathname === routes.plans.path;

  return content ? (
    <Grid
      style={{
        padding: theme.spacing(0, 2),
        background: "#3F51B5",
        color: "#FFFFFF",
      }}
      item
      xs={12}
      container
      justifyContent="center"
    >
      <Container maxWidth={"md"}>
        <Grid item xs={12} container alignItems={"center"} spacing={2}>
          <Grid item>
            <Timer endDate={offerEndAt} />
          </Grid>
          <Grid item flex={1}>
            <Typography
              variant={"body1"}
              style={{
                color: "#FFFFFF",
                fontWeight: "bold",
                textAlign: "center",
              }}
              component={"div"}
            >
              {content}
            </Typography>
          </Grid>
          <Grid item>
            {isPickingPlans ? null : (
              <Button
                onClick={() =>
                  blockableNavigate(() =>
                    navigate(`/billing?workspace-id=${workspaceId}`)
                  )
                }
                size={"small"}
                variant={"contained"}
                style={{
                  margin: theme.spacing(2),
                  whiteSpace: "nowrap",
                  padding: theme.spacing(1, 5),
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                Pick plan
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  ) : null;
}
