import { routes } from "../config";
import useWorkspaceGet from "../utils/reactQueryHooks/workspace/useWorkspaceGet";
import { Button, Typography, useTheme } from "@mui/material";
import useActiveWorkspaceId from "../hooks/useActiveWorkspaceId";
import usePrefetchWorkspaces from "../hooks/prefetch/usePrefetchWorkspaces";
import { useBlockableNavigation } from "../utils/providers/BlockableNavigationProvider";
import { useLocation, useNavigate } from "react-router-dom";
import ClickToCopy from "./partials/ClickToCopy";
import { useSnackbar } from "notistack";

export default function CurrentWorkspaceSelection() {
  let workspaceId = useActiveWorkspaceId();
  const { enqueueSnackbar } = useSnackbar();
  const workspace = useWorkspaceGet({ workspaceId });
  const theme = useTheme();
  const prefetchWorkspaces = usePrefetchWorkspaces();
  const { blockableNavigate } = useBlockableNavigation();
  let navigate = useNavigate();
  let location = useLocation();

  return (
    <Button
      fullWidth
      href={`${routes.workspaces.path}`}
      onMouseEnter={prefetchWorkspaces}
      onClick={(e) => {
        if (!e.ctrlKey && !e.metaKey) {
          e.preventDefault();
          blockableNavigate(() =>
            setTimeout(() => {
              navigate(`${routes.workspaces.path}`);
            }, 200)
          );
        }
      }}
      variant={routes.workspaces.path === location.pathname ? "text" : "text"}
      color={
        routes.workspaces.path === location.pathname ? "primary" : "inherit"
      }
      style={{
        padding: theme.spacing(1, 2),
        borderRadius: 0,
        justifyContent: "start",
        display: "block",
      }}
    >
      <Typography component={"div"}>{workspace?.data?.data?.title}</Typography>
      <Typography
        component={"div"}
        variant={"caption"}
        color={"text.secondary"}
      >
        Click to change workspace
      </Typography>
    </Button>
  );
}
