import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = { nodeId: string; title: string; key: string };

export type typeResultData = {};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___searchNodeUpdate(___options: typeOptions) {
  return async function ({
    nodeId,
    title,
    key,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      nodeId, // (required) (undefined === null | "") Joi.string().min(0).max(2000)
      title, // (required) (undefined === null | "") Joi.string().min(0).max(2000)
      key, // (required) (undefined === null | "") Joi.string().min(0).max(2000)
    };

    if (nodeId === null || nodeId === "") {
      delete ___payload?.["nodeId"];
    }

    if (title === null || title === "") {
      delete ___payload?.["title"];
    }

    if (key === null || key === "") {
      delete ___payload?.["key"];
    }
    let schema = Joi.object({
      nodeId: Joi.string().min(0).max(2000).required(),
      title: Joi.string().min(0).max(2000).required(),
      key: Joi.string().min(0).max(2000).required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___searchNodeUpdate" failed even before calling the network.
api/private/sdk/search-node/update.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/search-node/update",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
