import { Skeleton, Typography, useTheme } from "@mui/material";
import { LEADS_LIST_ORIGINAL_WORKSPACE } from "queryParams";
import React from "react";
import { StringParam, useQueryParams } from "use-query-params";
import useLeadsListGet from "utils/reactQueryHooks/leads-list/useLeadsListGet";
import useLeadsListStats from "utils/reactQueryHooks/leads-list/useLeadsListStats";
import { typeResultData } from "utils/sdk/leads-share-link/get";

type Props = {
  shareLinkGet: typeResultData;
};

export default function LeadsListDescription({ shareLinkGet }: Props) {
  const theme = useTheme();

  const [queryParams] = useQueryParams({
    [LEADS_LIST_ORIGINAL_WORKSPACE]: StringParam,
  });

  const leadsListOriginalWorkspaceId =
    queryParams[LEADS_LIST_ORIGINAL_WORKSPACE] || "";

  const leadsListGet = useLeadsListGet({
    workspaceId: leadsListOriginalWorkspaceId,
    leadsListId: shareLinkGet.leadsListId || "",
  });
  const leadsListStats = useLeadsListStats({
    workspaceId: leadsListOriginalWorkspaceId,
    leadsListId: shareLinkGet.leadsListId || "",
  });

  return !leadsListGet.data?.data || !leadsListStats.data?.data ? (
    <Skeleton height={56} variant="rounded" />
  ) : (
    <>
      <Typography
        color="text.secondary"
        fontWeight={400}
        textAlign={"center"}
        variant="h6"
      >
        Leads list:{" "}
        <strong style={{ color: theme.palette.text.primary }}>
          {leadsListGet.data.data.title}
        </strong>{" "}
        is being shared with you.
      </Typography>

      <Typography
        color="text.secondary"
        fontWeight={400}
        textAlign={"center"}
        style={{ marginTop: theme.spacing(2) }}
      >
        Contains{" "}
        <strong style={{ color: theme.palette.text.primary }}>
          {leadsListStats.data.data.stats.with_email}
        </strong>{" "}
        leads with email.
      </Typography>
    </>
  );
}
