import { Grid, Typography, useTheme } from "@mui/material";

export default function ProgressStatus({
  doneCount,
  totalCount,
}: {
  doneCount: number;
  totalCount: number;
}) {
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Typography
        variant={"body2"}
        style={{
          color: "inherit",
          textAlign: "center",
          marginTop: theme.spacing(1),
        }}
        component={"div"}
      >
        {doneCount} of {totalCount} done 🎉
      </Typography>
    </Grid>
  );
}
