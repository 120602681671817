import { useTheme } from "@mui/material";
// import PgLogoWhite from "assets/logos/pg-logo-white.svg";
import { Outlet } from "react-router-dom";

export default function AuthScreensLayout() {
  const theme = useTheme();

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
      }}
    >
      <div
        style={{
          width: 480,
          maxWidth: "95%",
          margin: theme.spacing(6, 0, 6),
        }}
      >
        <Outlet />
      </div>
    </div>
  );
}
