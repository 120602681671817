import AllProviders from "utils/providers/AllProviders";
import AppRoutes from "components/partials/AppRoutes";
// import BasicPasswordProtection from "./partials/BasicPasswordProtection";

export default function App() {
  return (
    <>
      <AllProviders>
        <AppRoutes />
      </AllProviders>
    </>
  );
}
