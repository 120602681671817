import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  jobId: string;
  companies: Object;
  leads: Object;
};

export type typeResultData = { isSuccess: boolean };
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___linkedinJobUploadData(___options: typeOptions) {
  return async function ({
    workspaceId,
    jobId,
    companies,
    leads,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      jobId, // (required) (undefined === null | "") Joi.string().pattern(/^[a-zA-Z0-9-_]{15,100}$/)
      companies, // (required) Joi.object()
      leads, // (required) Joi.object()
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (jobId === null || jobId === "") {
      delete ___payload?.["jobId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      jobId: Joi.string()
        .pattern(/^[a-zA-Z0-9-_]{15,100}$/)
        .required(),
      companies: Joi.object().required(),
      leads: Joi.object().required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___linkedinJobUploadData" failed even before calling the network.
api/private/sdk/linkedin-job/upload-data.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/linkedin-job/upload-data",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
