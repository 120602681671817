import { MenuRounded } from "@mui/icons-material";
import { ButtonBase, Divider, Drawer, useTheme } from "@mui/material";
import SidebarContent from "components/SidebarContent";
import { useState } from "react";

const drawerWidth = 260;

export default function Header() {
  const theme = useTheme();
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: 80,
        }}
      >
        <div style={{ flex: 1, lineHeight: 0 }}>
          <img
            alt="logo"
            src={
              theme.palette.mode === "light" ? "/logo.svg" : "logo-light.svg"
            }
            width={189}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div style={{ margin: theme.spacing(0, 2) }}>
            <ButtonBase
              style={{
                borderRadius: theme.spacing(1),
              }}
              onClick={handleDrawerToggle}
            >
              <MenuRounded />
            </ButtonBase>
          </div>
        </div>
      </div>

      <Divider />

      <Drawer
        anchor="right"
        variant="temporary"
        open={isMobileSidebarOpen}
        onClose={handleDrawerToggle}
        onClick={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            maxWidth: "95%",
          },
        }}
      >
        <SidebarContent />
      </Drawer>
    </>
  );
}
