import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  campaignId: string;
  title?: string;
  options?: {
    doSkipRecentlyEmailed: boolean;
    isUnsubscribeGlobal: boolean;
    doIncludeUtm: boolean;
    utm: { [key: string]: string };
    doSendTextOnly: boolean;
    doTrackClick: boolean;
    doTrackOpen: boolean;
    followUpPercentage: number;
    cc: string;
    bcc: string;
    replyTo: string;
  };
  schedule?: {
    timezone: string;
    variant: "custom" | "variant-1";
    days: {
      monday: { from: number; to: number; maxSendCount: number }[];
      tuesday: { from: number; to: number; maxSendCount: number }[];
      wednesday: { from: number; to: number; maxSendCount: number }[];
      thursday: { from: number; to: number; maxSendCount: number }[];
      friday: { from: number; to: number; maxSendCount: number }[];
      saturday: { from: number; to: number; maxSendCount: number }[];
      sunday: { from: number; to: number; maxSendCount: number }[];
    };
  };
  filters?: (
    | {
        condition:
          | "tags_include"
          | "tags_exclude"
          | "in_list"
          | "not_in_list"
          | "in_segment"
          | "status"
          | "not_in_segment"
          | "was_used_in_campaign"
          | "was_not_used_in_campaign"
          | "created_before_at"
          | "created_after_at";
        value: string | number;
      }
    | {
        condition: "attribute" | "sys_attribute";
        operator:
          | "equals"
          | "doesnt_equal"
          | "contains"
          | "doesnt_contains"
          | "is_in"
          | "is_not_in"
          | "starts_with"
          | "doesnt_start_with"
          | "ends_with"
          | "doesnt_end_with"
          | "is_set"
          | "is_not_set";
        attribute: string;
        value: string | string[];
      }
  )[][];
};

export type typeResultData = {
  workspaceId: string;
  campaignId: string;
  title: string;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___campaignUpdate(___options: typeOptions) {
  return async function ({
    workspaceId,
    campaignId,
    title,
    options,
    schedule,
    filters,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      campaignId, // (required) (undefined === null | "") Joi.string().pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/)
      title, // (optional) (undefined === null | "") Joi.string().min(0).max(200)
      options, // (optional) (undefined === null) Joi.object({doSkipRecentlyEmailed:Joi.boolean(),doIncludeUtm:Joi.boolean(),utm:Joi.object().pattern(Joi.string().max(100),Joi.string().max(100)),doSendTextOnly:Joi.boolean(),doTrackClick:Joi.boolean(),doTrackOpen:Joi.boolean(),followUpPercentage:Joi.number().integer().min(0).max(100),cc:Joi.string().email({tlds:{allow:false}}).max(200).allow(""),bcc:Joi.string().email({tlds:{allow:false}}).max(200).allow(""),replyTo:Joi.string().email({tlds:{allow:false}}).max(200).allow("")});
      schedule, // (optional) (undefined === null) Joi.object({timezone:Joi.string().max(100),variant: Joi.string().valid("custom", "variant-1").optional(),days:Joi.object({monday:Joi.array().items(Joi.object({from:Joi.number().integer().min(0).max(1440),to:Joi.number().integer().min(0).max(1440),maxSendCount:Joi.number().integer().min(0).max(1e6)})),tuesday:Joi.array().items(Joi.object({from:Joi.number().integer().min(0).max(1440),to:Joi.number().integer().min(0).max(1440),maxSendCount:Joi.number().integer().min(0).max(1e6)})),wednesday:Joi.array().items(Joi.object({from:Joi.number().integer().min(0).max(1440),to:Joi.number().integer().min(0).max(1440),maxSendCount:Joi.number().integer().min(0).max(1e6)})),thursday:Joi.array().items(Joi.object({from:Joi.number().integer().min(0).max(1440),to:Joi.number().integer().min(0).max(1440),maxSendCount:Joi.number().integer().min(0).max(1e6)})),friday:Joi.array().items(Joi.object({from:Joi.number().integer().min(0).max(1440),to:Joi.number().integer().min(0).max(1440),maxSendCount:Joi.number().integer().min(0).max(1e6)})),saturday:Joi.array().items(Joi.object({from:Joi.number().integer().min(0).max(1440),to:Joi.number().integer().min(0).max(1440),maxSendCount:Joi.number().integer().min(0).max(1e6)})),sunday:Joi.array().items(Joi.object({from:Joi.number().integer().min(0).max(1440),to:Joi.number().integer().min(0).max(1440),maxSendCount:Joi.number().integer().min(0).max(1e6)}))})});
      filters, // (optional) (undefined === null) Joi.array().items(   Joi.array().items(     Joi.alternatives().try(       Joi.object({         condition: Joi.string().valid(           "tags_include",           "tags_exclude",           "in_list",           "not_in_list",           "in_segment",           "status",           "was_used_in_campaign",           "was_not_used_in_campaign",           "not_in_segment",           "created_before_at",           "created_after_at"         ),         value: Joi.when("condition", {           is: Joi.valid("created_before_at", "created_after_at"),           then: Joi.number().integer().min(0).required(),           otherwise: Joi.string().max(200).required(),         }).required(),       }),       Joi.object({         condition: Joi.string().valid("attribute", "sys_attribute"),         operator: Joi.string().valid(           "equals",           "doesnt_equal",           "contains",           "doesnt_contains",           "is_in",           "is_not_in",           "starts_with",           "doesnt_start_with",           "ends_with",           "doesnt_end_with",           "is_set",           "is_not_set"         ),         attribute: Joi.string().max(200).required(),         value: Joi.when("operator", {           is: Joi.valid("is_set", "is_not_set"),           then: Joi.string().allow("").max(200).optional(),           otherwise: Joi.when("operator", {             is: Joi.valid("is_in", "is_not_in"),             then: Joi.array().items(Joi.string().max(200)),             otherwise: Joi.string().min(1).max(200).required(),           }).required(),         }).required(),       })     )   ) )
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (campaignId === null || campaignId === "") {
      delete ___payload?.["campaignId"];
    }

    if (title === null || title === "") {
      delete ___payload?.["title"];
    }

    if (options === null) {
      delete ___payload?.["options"];
    }

    if (schedule === null) {
      delete ___payload?.["schedule"];
    }

    if (filters === null) {
      delete ___payload?.["filters"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      campaignId: Joi.string()
        .pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
      title: Joi.string().min(0).max(200),
      options: Joi.object({
        doSkipRecentlyEmailed: Joi.boolean(),
        doIncludeUtm: Joi.boolean(),
        utm: Joi.object().pattern(Joi.string().max(100), Joi.string().max(100)),
        doSendTextOnly: Joi.boolean(),
        doTrackClick: Joi.boolean(),
        doTrackOpen: Joi.boolean(),
        followUpPercentage: Joi.number().integer().min(0).max(100),
        cc: Joi.string()
          .email({ tlds: { allow: false } })
          .max(200)
          .allow(""),
        bcc: Joi.string()
          .email({ tlds: { allow: false } })
          .max(200)
          .allow(""),
        replyTo: Joi.string()
          .email({ tlds: { allow: false } })
          .max(200)
          .allow(""),
      }),
      schedule: Joi.object({
        timezone: Joi.string().max(100),
        variant: Joi.string().valid("custom", "variant-1").optional(),
        days: Joi.object({
          monday: Joi.array().items(
            Joi.object({
              from: Joi.number().integer().min(0).max(1440),
              to: Joi.number().integer().min(0).max(1440),
              maxSendCount: Joi.number().integer().min(0).max(1e6),
            })
          ),
          tuesday: Joi.array().items(
            Joi.object({
              from: Joi.number().integer().min(0).max(1440),
              to: Joi.number().integer().min(0).max(1440),
              maxSendCount: Joi.number().integer().min(0).max(1e6),
            })
          ),
          wednesday: Joi.array().items(
            Joi.object({
              from: Joi.number().integer().min(0).max(1440),
              to: Joi.number().integer().min(0).max(1440),
              maxSendCount: Joi.number().integer().min(0).max(1e6),
            })
          ),
          thursday: Joi.array().items(
            Joi.object({
              from: Joi.number().integer().min(0).max(1440),
              to: Joi.number().integer().min(0).max(1440),
              maxSendCount: Joi.number().integer().min(0).max(1e6),
            })
          ),
          friday: Joi.array().items(
            Joi.object({
              from: Joi.number().integer().min(0).max(1440),
              to: Joi.number().integer().min(0).max(1440),
              maxSendCount: Joi.number().integer().min(0).max(1e6),
            })
          ),
          saturday: Joi.array().items(
            Joi.object({
              from: Joi.number().integer().min(0).max(1440),
              to: Joi.number().integer().min(0).max(1440),
              maxSendCount: Joi.number().integer().min(0).max(1e6),
            })
          ),
          sunday: Joi.array().items(
            Joi.object({
              from: Joi.number().integer().min(0).max(1440),
              to: Joi.number().integer().min(0).max(1440),
              maxSendCount: Joi.number().integer().min(0).max(1e6),
            })
          ),
        }),
      }),
      filters: Joi.array().items(
        Joi.array().items(
          Joi.alternatives().try(
            Joi.object({
              condition: Joi.string().valid(
                "tags_include",
                "tags_exclude",
                "in_list",
                "not_in_list",
                "in_segment",
                "status",
                "was_used_in_campaign",
                "was_not_used_in_campaign",
                "not_in_segment",
                "created_before_at",
                "created_after_at"
              ),
              value: Joi.when("condition", {
                is: Joi.valid("created_before_at", "created_after_at"),
                then: Joi.number().integer().min(0).required(),
                otherwise: Joi.string().max(200).required(),
              }).required(),
            }),
            Joi.object({
              condition: Joi.string().valid("attribute", "sys_attribute"),
              operator: Joi.string().valid(
                "equals",
                "doesnt_equal",
                "contains",
                "doesnt_contains",
                "is_in",
                "is_not_in",
                "starts_with",
                "doesnt_start_with",
                "ends_with",
                "doesnt_end_with",
                "is_set",
                "is_not_set"
              ),
              attribute: Joi.string().max(200).required(),
              value: Joi.when("operator", {
                is: Joi.valid("is_set", "is_not_set"),
                then: Joi.string().allow("").max(200).optional(),
                otherwise: Joi.when("operator", {
                  is: Joi.valid("is_in", "is_not_in"),
                  then: Joi.array().items(Joi.string().max(200)),
                  otherwise: Joi.string().min(1).max(200).required(),
                }).required(),
              }).required(),
            })
          )
        )
      ),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___campaignUpdate" failed even before calling the network.
api/private/sdk/campaign/update.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/campaign/update",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
