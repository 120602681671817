import { Button } from "@mui/material";
import { routes } from "config";
import { IS_CREATE_WORKSPACE_OPEN } from "queryParams";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {};

export default function ButtonCreateWorkspaceForLeadsListShare({}: Props) {
  const navigate = useNavigate();
  let url = `${routes.workspaces.path}?${IS_CREATE_WORKSPACE_OPEN}=1`;

  // TODO: after creating ws or cancelling creation, manage navigation once clear what ids need to be used

  return (
    <div style={{ textAlign: "center" }}>
      <Button onClick={() => navigate(url)}>Create new workspace</Button>
    </div>
  );
}
