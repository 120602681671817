import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  segmentId: string;
  title?: string;
  filters?: any;
};

export type typeResultData = {
  workspaceId: string;
  segmentId: string;
  title: string;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___segmentUpdate(___options: typeOptions) {
  return async function ({
    workspaceId,
    segmentId,
    title,
    filters,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      segmentId, // (required) (undefined === null | "") Joi.string().pattern(/^segment-[a-zA-Z0-9-_]{15,60}$/)
      title, // (optional) (undefined === null | "") Joi.string().min(0).max(200)
      filters, // (optional) (undefined === null) Joi.array().items(   Joi.array().items(     Joi.alternatives().try(       Joi.object({         condition: Joi.string().valid(           "tags_include",           "tags_exclude",           "in_list",           "not_in_list",           "in_segment",           "status",           "was_used_in_campaign",           "was_not_used_in_campaign",           "not_in_segment",           "created_before_at",           "created_after_at"         ),         value: Joi.when("condition", {           is: Joi.valid("created_before_at", "created_after_at"),           then: Joi.number().integer().min(0).required(),           otherwise: Joi.string().max(200).required(),         }).required(),       }),       Joi.object({         condition: Joi.string().valid("attribute", "sys_attribute"),         operator: Joi.string().valid(           "equals",           "doesnt_equal",           "contains",           "doesnt_contains",           "is_in",           "is_not_in",           "starts_with",           "doesnt_start_with",           "ends_with",           "doesnt_end_with",           "is_set",           "is_not_set"         ),         attribute: Joi.string().max(200).required(),         value: Joi.when("operator", {           is: Joi.valid("is_set", "is_not_set"),           then: Joi.string().allow("").max(200).optional(),           otherwise: Joi.when("operator", {             is: Joi.valid("is_in", "is_not_in"),             then: Joi.array().items(Joi.string().max(200)),             otherwise: Joi.string().min(1).max(200).required(),           }).required(),         }).required(),       })     )   ) )
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (segmentId === null || segmentId === "") {
      delete ___payload?.["segmentId"];
    }

    if (title === null || title === "") {
      delete ___payload?.["title"];
    }

    if (filters === null) {
      delete ___payload?.["filters"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      segmentId: Joi.string()
        .pattern(/^segment-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
      title: Joi.string().min(0).max(200),
      filters: Joi.array().items(
        Joi.array().items(
          Joi.alternatives().try(
            Joi.object({
              condition: Joi.string().valid(
                "tags_include",
                "tags_exclude",
                "in_list",
                "not_in_list",
                "in_segment",
                "status",
                "was_used_in_campaign",
                "was_not_used_in_campaign",
                "not_in_segment",
                "created_before_at",
                "created_after_at"
              ),
              value: Joi.when("condition", {
                is: Joi.valid("created_before_at", "created_after_at"),
                then: Joi.number().integer().min(0).required(),
                otherwise: Joi.string().max(200).required(),
              }).required(),
            }),
            Joi.object({
              condition: Joi.string().valid("attribute", "sys_attribute"),
              operator: Joi.string().valid(
                "equals",
                "doesnt_equal",
                "contains",
                "doesnt_contains",
                "is_in",
                "is_not_in",
                "starts_with",
                "doesnt_start_with",
                "ends_with",
                "doesnt_end_with",
                "is_set",
                "is_not_set"
              ),
              attribute: Joi.string().max(200).required(),
              value: Joi.when("operator", {
                is: Joi.valid("is_set", "is_not_set"),
                then: Joi.string().allow("").max(200).optional(),
                otherwise: Joi.when("operator", {
                  is: Joi.valid("is_in", "is_not_in"),
                  then: Joi.array().items(Joi.string().max(200)),
                  otherwise: Joi.string().min(1).max(200).required(),
                }).required(),
              }).required(),
            })
          )
        )
      ),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___segmentUpdate" failed even before calling the network.
api/private/sdk/segment/update.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/segment/update",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
