import { LogoutRounded } from "@mui/icons-material";
import { Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import useActiveWorkspaceId from "hooks/useActiveWorkspaceId";
import { useAuth } from "utils/providers/AuthProvider";
import useAuthLogout from "utils/reactQueryHooks/auth/useAuthLogout";
import useTeamGetMyProfile from "utils/reactQueryHooks/team/useTeamGetMyProfile";
import CurrentWorkspaceSelection from "../CurrentWorkspaceSelection";
import SidebarProgress from "../SidebarProgress";
import ThemeModeSelection from "../ThemeModeSelection";
import SidebarBottomButtons from "./SidebarBottomButtons";
import SidebarMenuItems from "./SidebarMenuItems";

export default function SidebarContent() {
  const theme = useTheme();

  let workspaceId = useActiveWorkspaceId();

  const auth = useAuth();
  const logout = useAuthLogout({});
  const myProfile = useTeamGetMyProfile({ workspaceId });

  return (
    <div style={{ padding: theme.spacing(3, 0, 4) }}>
      <div style={{ textAlign: "center" }}>
        <img
          alt="logo"
          src={theme.palette.mode === "light" ? "/logo.svg" : "logo-light.svg"}
          height={73}
          width={189}
          style={{ marginBottom: theme.spacing(2) }}
        />
      </div>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CurrentWorkspaceSelection />
        </Grid>

        <SidebarProgress />

        <Grid item xs={12}>
          <Divider style={{ margin: theme.spacing(1, 0) }} />
        </Grid>

        <SidebarMenuItems />

        <Grid item xs={12}>
          <Divider style={{ margin: theme.spacing(1, 0, 2) }} />
        </Grid>

        <SidebarBottomButtons />

        <Grid item xs={12}>
          <Divider style={{ margin: theme.spacing(2, 0) }} />
        </Grid>
        {myProfile?.data?.data?.email ? (
          <Grid item xs={12}>
            <Typography
              variant={"caption"}
              color={"textSecondary"}
              component={"div"}
              style={{ textAlign: "center" }}
            >
              Logged in as
              <br />
              {myProfile?.data?.data?.email}
            </Typography>
          </Grid>
        ) : null}

        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          style={{ margin: theme.spacing(0, 1) }}
        >
          <Grid item>
            <Button
              onClick={() => {
                logout.mutate({});
                auth.removeAuthorizedUser();
              }}
              color={"error"}
              style={{
                margin: theme.spacing(0, 0, 6),
                padding: theme.spacing(1, 2),
                borderRadius: 0,
                justifyContent: "start",
              }}
              startIcon={<LogoutRounded />}
            >
              Log out
            </Button>
          </Grid>
          <Grid item>
            <ThemeModeSelection />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
