import { Tooltip, Typography } from "@mui/material";
import React from "react";
import useWorkspaceGetMetaStats from "utils/reactQueryHooks/workspace/useWorkspaceGetMetaStats";

type Props = { stats: ReturnType<typeof useWorkspaceGetMetaStats> };

export default function SidebarStatsCampaigns({ stats }: Props) {
  const runningCampaignsCount = stats.data?.data?.totalRunningCampaigns || 0;

  let content: React.ReactNode = null;

  if (runningCampaignsCount > 0) {
    content = (
      <Tooltip title="Running campaigns" arrow placement="top">
        <Typography color="text.secondary" variant="caption">
          <span
            style={{ fontWeight: runningCampaignsCount > 0 ? 700 : undefined }}
          >
            {runningCampaignsCount}
          </span>
        </Typography>
      </Tooltip>
    );
  }

  return content;
}
