import { Button, ButtonGroup, Grid, TextField } from "@mui/material";
import useProspectingBucketList from "../../../utils/reactQueryHooks/prospecting-bucket/useProspectingBucketList";
import useActiveWorkspaceId from "../../../hooks/useActiveWorkspaceId";
import useAdminProspectingBucketDelete from "../../../utils/reactQueryHooks/admin/useAdminProspectingBucketDelete";
import { LoadingButton } from "@mui/lab";
import useAdminProspectingBucketCreate from "../../../utils/reactQueryHooks/admin/useAdminProspectingBucketCreate";
import React from "react";

const moment = require("moment");

export default function Prospecting() {
  const workspaceId = useActiveWorkspaceId();
  let buckets = useProspectingBucketList({ workspaceId });
  let deleteBucket = useAdminProspectingBucketDelete({});
  let createBucket = useAdminProspectingBucketCreate({});
  const [value, setValue] = React.useState("");
  const [expireInDays, setExpireInDays] = React.useState(0);

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <h2>create bucket</h2>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            label="Credits count"
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup variant="contained">
            <Button
              color={expireInDays === 0 ? "primary" : "inherit"}
              onClick={() => {
                setExpireInDays(0);
              }}
            >
              never expire
            </Button>
            <Button
              color={expireInDays === 7 ? "primary" : "inherit"}
              onClick={() => {
                setExpireInDays(7);
              }}
            >
              expire in 7d
            </Button>
            <Button
              color={expireInDays === 14 ? "primary" : "inherit"}
              onClick={() => {
                setExpireInDays(14);
              }}
            >
              expire in 14d
            </Button>
            <Button
              color={expireInDays === 30 ? "primary" : "inherit"}
              onClick={() => {
                setExpireInDays(30);
              }}
            >
              expire in 30d
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            disabled={!value}
            variant="contained"
            color="primary"
            onClick={() => {
              let payload: any = {
                workspaceId,
                credits: parseInt(value, 10),
              };
              if (expireInDays > 0) {
                payload.expireAt = moment().add(expireInDays, "days").unix();
              }
              createBucket.mutate(payload);
            }}
            loading={createBucket.isLoading}
          >
            Create
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h2>current buckets of this workspace:</h2>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {buckets?.data?.data?.Items?.[0] ? (
          <>
            {buckets?.data?.data?.Items.map((bucket) => {
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid item>
                    <div>credits count: {bucket.creditsCount}</div>
                    <div>type: {bucket.type}</div>
                    <div>user email: {bucket.userEmail}</div>
                    <div>
                      expire:{" "}
                      {bucket.expireAt
                        ? moment.unix(bucket.expireAt).fromNow()
                        : "never"}
                    </div>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      loading={deleteBucket.isLoading}
                      color={"error"}
                      onClick={() => {
                        deleteBucket.mutate({
                          workspaceId,
                          prospectingCreditsBucketId:
                            bucket.prospectingCreditsBucketId,
                        });
                      }}
                    >
                      Delete
                    </LoadingButton>
                  </Grid>
                </Grid>
              );
            })}
          </>
        ) : null}
      </Grid>
    </Grid>
  );
}
