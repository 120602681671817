import useUserGetMeta from "../../utils/reactQueryHooks/user/useUserGetMeta";
import AdminLayout from "./AdminLayout";
import NotFound from "../partials/NotFound";

export default function Admin() {
  let user = useUserGetMeta({});
  let isVisible = user?.data?.data?.type === "staff";

  return isVisible ? <AdminLayout /> : <NotFound />;
}
