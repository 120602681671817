import { Button, Container, Grid, Typography, useTheme } from "@mui/material";
import useActiveWorkspaceId from "hooks/useActiveWorkspaceId";
import { useNavigate } from "react-router-dom";
import useWorkspaceGet from "../../utils/reactQueryHooks/workspace/useWorkspaceGet";

export default function HelloBarNotPaid() {
  const theme = useTheme();
  const navigate = useNavigate();
  const workspaceId = useActiveWorkspaceId();
  const workspace = useWorkspaceGet({ workspaceId });
  const isPaid = workspace?.data?.data?.isPaid === true;

  return isPaid === false ? (
    <Grid
      style={{
        padding: theme.spacing(0, 2),
        background: theme.palette.error.main,
        color: "#FFFFFF",
      }}
      item
      xs={12}
      container
      justifyContent="center"
    >
      <Container maxWidth={"md"}>
        <Grid
          item
          xs={12}
          container
          alignItems={"center"}
          spacing={2}
          justifyContent={"center"}
          flexWrap={"nowrap"}
        >
          <Grid item>
            <Typography
              variant={"body1"}
              style={{
                color: "#FFFFFF",
                fontWeight: "bold",
                textAlign: "center",
              }}
              component={"div"}
            >
              Workspace is not paid
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => navigate(`/billing?workspace-id=${workspaceId}`)}
              size={"small"}
              variant={"contained"}
              style={{
                margin: theme.spacing(2),
                whiteSpace: "nowrap",
                padding: theme.spacing(1, 5),
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              Pick plan
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  ) : null;
}
