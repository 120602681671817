import { Button, Grid, useTheme } from "@mui/material";
import { WORKSPACE_ID } from "config";
import usePrefetchCampaigns from "hooks/prefetch/usePrefetchCampaigns";
import usePrefetchContacts from "hooks/prefetch/usePrefetchContacts";
import usePrefetchSettingsWorkspace from "hooks/prefetch/usePrefetchSettingsWorkspace";
import useActiveWorkspaceId from "hooks/useActiveWorkspaceId";
import { useLocation, useNavigate } from "react-router-dom";
import { useBlockableNavigation } from "utils/providers/BlockableNavigationProvider";
import SidebarMenuItemIcon from "./SidebarMenuItemIcon";
import { Route } from ".";
import useWorkspaceGetMetaStats from "utils/reactQueryHooks/workspace/useWorkspaceGetMetaStats";
import SidebarMenuItemStats from "./SidebarMenuItemStats";
import { red } from "@mui/material/colors";

type Props = {
  route: Route;
  stats: ReturnType<typeof useWorkspaceGetMetaStats>;
};

export default function SidebarMenuItem({ route, stats }: Props) {
  const theme = useTheme();
  const workspaceId = useActiveWorkspaceId();
  const location = useLocation();
  const navigate = useNavigate();
  const { blockableNavigate } = useBlockableNavigation();

  const prefetchCampaigns = usePrefetchCampaigns();
  const prefetchSettingsWorkspace = usePrefetchSettingsWorkspace();
  const prefetchContacts = usePrefetchContacts();

  return (
    <Grid item xs={12}>
      <Button
        startIcon={<SidebarMenuItemIcon route={route} />}
        fullWidth
        key={route.path}
        onMouseEnter={() => {
          if (route.path === "/campaigns") {
            prefetchCampaigns();
          }
          if (route.path === "/contacts") {
            prefetchContacts();
          }
          if (route.path === "/settings") {
            prefetchSettingsWorkspace();
          }
        }}
        href={`${route.path}?${WORKSPACE_ID}=${workspaceId}`}
        onClick={(e) => {
          if (!e.ctrlKey && !e.metaKey) {
            e.preventDefault();
            // doesn't need to know what are unsaved changes(if any) or how to save them
            blockableNavigate(() =>
              setTimeout(() => {
                navigate(`${route.path}?${WORKSPACE_ID}=${workspaceId}`);
              }, 200)
            );
          }
        }}
        variant={route.path === location.pathname ? "text" : "text"}
        color={route.path === location.pathname ? "primary" : "inherit"}
        style={{
          padding: theme.spacing(1, 2),
          borderRadius: 0,
          justifyContent: "start",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Grid container justifyContent={"space-between"}>
            <Grid item>{route.title}</Grid>
            <Grid item>
              <SidebarMenuItemStats route={route} stats={stats} />
            </Grid>
            {route.isNew ? (
              <Grid item>
                <span
                  style={{
                    padding: "3px 2px",
                    color: "#FFF",
                    borderRadius: 6,
                    backgroundColor: red[600],
                  }}
                >
                  NEW
                </span>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </Button>
    </Grid>
  );
}
