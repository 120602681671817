import { ContentCopyRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { typeResultData } from "utils/sdk/admin-suspend/list";
import useConfirmUnBan from "./useConfirmUnBan";

type Props = {
  ban: typeResultData["Items"][number];
};

export default function TableRowSingleBan({ ban }: Props) {
  const theme = useTheme();
  const snackbar = useSnackbar();

  const confirmUnBan = useConfirmUnBan({});

  const onCopy = () => {
    navigator.clipboard.writeText(ban.value);
    snackbar.enqueueSnackbar(`Copied to clipboard: ${ban.value}`, {
      variant: "success",
    });
  };

  return (
    <TableRow hover={false}>
      <TableCell style={{ width: "100%" }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item>
            <Typography fontWeight={700} variant="body2">
              {ban.value}
            </Typography>
          </Grid>

          <Grid item>
            <Tooltip
              arrow
              placement="top"
              title={`Click to copy: ${ban.value}`}
            >
              <IconButton onClick={onCopy} size="small">
                <ContentCopyRounded
                  fontSize="small"
                  style={{ color: theme.palette.text.secondary }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>

      <TableCell>
        <LoadingButton
          loading={confirmUnBan.isLoading}
          onClick={() => confirmUnBan.onUnBan({ value: ban.value })}
          size="small"
        >
          Un-ban
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
}
