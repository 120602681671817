import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  campaignId: string;
  senderCompanyName?: string;
  senderServiceDescription?: string;
  potentialBenefit?: string;
  prospectDescription?: string;
  campaignGoal?: string;
  tone?:
    | "sarcastic"
    | "funny"
    | "professional"
    | "businessCasual"
    | "formal"
    | "humble"
    | "informative"
    | "passionate"
    | "joyful"
    | "urgent"
    | "worried"
    | "assertive"
    | "candid"
    | "bold"
    | "excited"
    | "similarToTonyRobbins"
    | "similarToGaryVaynerchuck"
    | "similarToTimFerris"
    | "similarToNeilPatel";
};

export type typeResultData = { isSuccess: boolean };
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___campaignAiFormSave(___options: typeOptions) {
  return async function ({
    workspaceId,
    campaignId,
    senderCompanyName,
    senderServiceDescription,
    potentialBenefit,
    campaignGoal,
    tone,
    prospectDescription,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      campaignId, // (required) (undefined === null | "") Joi.string().pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/)
      senderCompanyName, // (optional) (undefined === null | "") Joi.string().min(0).max(3000)
      senderServiceDescription, // (optional) (undefined === null | "") Joi.string().min(0).max(3000)
      potentialBenefit, // (optional) (undefined === null | "") Joi.string().min(0).max(3000)
      campaignGoal, // (optional) (undefined === null | "") Joi.string().min(0).max(3000)
      tone, // (optional) (undefined === null) Joi.string().valid(   'sarcastic',   'funny',   'professional',   'businessCasual',   'formal',   'humble',   'informative',   'passionate',   'joyful',   'urgent',   'worried',   'assertive',   'candid',   'bold',   'excited',   'similarToTonyRobbins',   'similarToGaryVaynerchuck',   'similarToTimFerris',   'similarToNeilPatel' )
      prospectDescription, // (optional) (undefined === null | "") Joi.string().min(0).max(3000)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (campaignId === null || campaignId === "") {
      delete ___payload?.["campaignId"];
    }

    if (senderCompanyName === null || senderCompanyName === "") {
      delete ___payload?.["senderCompanyName"];
    }

    if (senderServiceDescription === null || senderServiceDescription === "") {
      delete ___payload?.["senderServiceDescription"];
    }

    if (potentialBenefit === null || potentialBenefit === "") {
      delete ___payload?.["potentialBenefit"];
    }

    if (campaignGoal === null || campaignGoal === "") {
      delete ___payload?.["campaignGoal"];
    }

    if (tone === null) {
      delete ___payload?.["tone"];
    }

    if (prospectDescription === null || prospectDescription === "") {
      delete ___payload?.["prospectDescription"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      campaignId: Joi.string()
        .pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
      senderCompanyName: Joi.string().min(0).max(3000),
      senderServiceDescription: Joi.string().min(0).max(3000),
      potentialBenefit: Joi.string().min(0).max(3000),
      campaignGoal: Joi.string().min(0).max(3000),
      tone: Joi.string().valid(
        "sarcastic",
        "funny",
        "professional",
        "businessCasual",
        "formal",
        "humble",
        "informative",
        "passionate",
        "joyful",
        "urgent",
        "worried",
        "assertive",
        "candid",
        "bold",
        "excited",
        "similarToTonyRobbins",
        "similarToGaryVaynerchuck",
        "similarToTimFerris",
        "similarToNeilPatel"
      ),
      prospectDescription: Joi.string().min(0).max(3000),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___campaignAiFormSave" failed even before calling the network.
api/private/sdk/campaign-ai-form/save.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/campaign-ai-form/save",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
