import {
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/leads-list/list";
import useCallSdk from "../useCallSdk";
import { useEffect } from "react";

export function buildLeadsListListQueryKey(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const perPage = _payload?.perPage;
  const lastKnown = _payload?.lastKnown;
  let _queryKey: string[] = [];

  if (
    typeof workspaceId !== "undefined" &&
    workspaceId !== null &&
    workspaceId !== ""
  ) {
    _queryKey.push(String(workspaceId));
  }

  if (typeof perPage !== "undefined" && perPage !== null) {
    _queryKey.push(String(perPage));
  }

  if (
    typeof lastKnown !== "undefined" &&
    lastKnown !== null &&
    lastKnown !== ""
  ) {
    _queryKey.push(String(lastKnown));
  }

  return ["leads-list:list", ..._queryKey];
}

export function useLeadsListListQueryFn(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const perPage = _payload?.perPage;
  const lastKnown = _payload?.lastKnown;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().leadsListList({
      workspaceId,
      perPage,
      lastKnown,
    });
}

export type typeLeadsListListHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useLeadsListList(
  _payload: typePayload,
  _options?: typeLeadsListListHookOptions
) {
  const workspaceId = _payload.workspaceId;
  const perPage = _payload?.perPage;
  const lastKnown = _payload?.lastKnown;
  _options = _options || {};
  const _queryKey = buildLeadsListListQueryKey({
    workspaceId,
    perPage,
    lastKnown,
  });
  const _queryFn = useLeadsListListQueryFn({ workspaceId, perPage, lastKnown });

  return useQuery(_queryKey, _queryFn, _options);
}

export type typeLeadsListListHookOptionsInfinite = UseInfiniteQueryOptions<
  typeResult,
  unknown,
  typeResult,
  typeResult,
  string[]
>;

export function useLeadsListListInfinity(
  _payload: typePayload,
  _options?: typeLeadsListListHookOptionsInfinite
) {
  const workspaceId = _payload.workspaceId;
  const perPage = _payload?.perPage;
  const _queryClient = useQueryClient();
  _options = _options || {};
  const _queryKey = buildLeadsListListQueryKey({ workspaceId, perPage });
  const _sdk = useCallSdk();

  if (typeof _options.getNextPageParam === "undefined") {
    _options.getNextPageParam = (_lastPage: typeResult) =>
      _lastPage.data?.isThereMore ? _lastPage.data?.lastKnown : undefined;
  }

  useEffect(() => {
    const _onUnmount = () => {
      _queryClient.setQueryData(_queryKey, (_oldData: any) => {
        if (_oldData?.pages?.[1] && _oldData?.pageParams[1]) {
          return {
            pages: _oldData.pages.slice(0, 1),
            pageParams: _oldData.pageParams.slice(0, 1),
          };
        }
      });
    };

    return _onUnmount;
  }, []);

  return useInfiniteQuery(
    _queryKey,
    ({ pageParam = undefined }) =>
      _sdk.callSdk().leadsListList({
        workspaceId,
        perPage,
        lastKnown: pageParam,
      }),
    _options
  );
}
