import {
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/campaign/list";
import useCallSdk from "../useCallSdk";
import { useEffect } from "react";

export function buildCampaignListQueryKey(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const lastKnown = _payload?.lastKnown;
  const perPage = _payload?.perPage;
  const sortOrder = _payload?.sortOrder;
  const createdAtMsFrom = _payload?.createdAtMsFrom;
  const createdAtMsTo = _payload?.createdAtMsTo;
  let _queryKey: string[] = [];

  if (
    typeof workspaceId !== "undefined" &&
    workspaceId !== null &&
    workspaceId !== ""
  ) {
    _queryKey.push(String(workspaceId));
  }

  if (
    typeof lastKnown !== "undefined" &&
    lastKnown !== null &&
    lastKnown !== ""
  ) {
    _queryKey.push(String(lastKnown));
  }

  if (typeof perPage !== "undefined" && perPage !== null) {
    _queryKey.push(String(perPage));
  }

  if (
    typeof sortOrder !== "undefined" &&
    sortOrder !== null &&
    sortOrder !== ""
  ) {
    _queryKey.push(String(sortOrder));
  }

  if (typeof createdAtMsFrom !== "undefined" && createdAtMsFrom !== null) {
    _queryKey.push(String(createdAtMsFrom));
  }

  if (typeof createdAtMsTo !== "undefined" && createdAtMsTo !== null) {
    _queryKey.push(String(createdAtMsTo));
  }

  return ["campaign:list", ..._queryKey];
}

export function useCampaignListQueryFn(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const lastKnown = _payload?.lastKnown;
  const perPage = _payload?.perPage;
  const sortOrder = _payload?.sortOrder;
  const createdAtMsFrom = _payload?.createdAtMsFrom;
  const createdAtMsTo = _payload?.createdAtMsTo;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().campaignList({
      workspaceId,
      lastKnown,
      perPage,
      sortOrder,
      createdAtMsFrom,
      createdAtMsTo,
    });
}

export type typeCampaignListHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useCampaignList(
  _payload: typePayload,
  _options?: typeCampaignListHookOptions
) {
  const workspaceId = _payload.workspaceId;
  const lastKnown = _payload?.lastKnown;
  const perPage = _payload?.perPage;
  const sortOrder = _payload?.sortOrder;
  const createdAtMsFrom = _payload?.createdAtMsFrom;
  const createdAtMsTo = _payload?.createdAtMsTo;
  _options = _options || {};
  const _queryKey = buildCampaignListQueryKey({
    workspaceId,
    lastKnown,
    perPage,
    sortOrder,
    createdAtMsFrom,
    createdAtMsTo,
  });
  const _queryFn = useCampaignListQueryFn({
    workspaceId,
    lastKnown,
    perPage,
    sortOrder,
    createdAtMsFrom,
    createdAtMsTo,
  });

  return useQuery(_queryKey, _queryFn, _options);
}

export type typeCampaignListHookOptionsInfinite = UseInfiniteQueryOptions<
  typeResult,
  unknown,
  typeResult,
  typeResult,
  string[]
>;

export function useCampaignListInfinity(
  _payload: typePayload,
  _options?: typeCampaignListHookOptionsInfinite
) {
  const workspaceId = _payload.workspaceId;
  const perPage = _payload?.perPage;
  const sortOrder = _payload?.sortOrder;
  const createdAtMsFrom = _payload?.createdAtMsFrom;
  const createdAtMsTo = _payload?.createdAtMsTo;
  const _queryClient = useQueryClient();
  _options = _options || {};
  const _queryKey = buildCampaignListQueryKey({
    workspaceId,
    perPage,
    sortOrder,
    createdAtMsFrom,
    createdAtMsTo,
  });
  const _sdk = useCallSdk();

  if (typeof _options.getNextPageParam === "undefined") {
    _options.getNextPageParam = (_lastPage: typeResult) =>
      _lastPage.data?.isThereMore ? _lastPage.data?.lastKnown : undefined;
  }

  useEffect(() => {
    const _onUnmount = () => {
      _queryClient.setQueryData(_queryKey, (_oldData: any) => {
        if (_oldData?.pages?.[1] && _oldData?.pageParams[1]) {
          return {
            pages: _oldData.pages.slice(0, 1),
            pageParams: _oldData.pageParams.slice(0, 1),
          };
        }
      });
    };

    return _onUnmount;
  }, []);

  return useInfiniteQuery(
    _queryKey,
    ({ pageParam = undefined }) =>
      _sdk.callSdk().campaignList({
        workspaceId,
        perPage,
        sortOrder,
        createdAtMsFrom,
        createdAtMsTo,
        lastKnown: pageParam,
      }),
    _options
  );
}
