import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = { workspaceId: string; campaignId: string };

export type typeResultData = {
  workspaceId: string;
  campaignId: string;
  title: string;
  schedule: {
    timezone: string;
    variant: "custom" | "variant-1";
    days: {
      monday: { from: number; to: number; maxSendCount: number }[];
      tuesday: { from: number; to: number; maxSendCount: number }[];
      wednesday: { from: number; to: number; maxSendCount: number }[];
      thursday: { from: number; to: number; maxSendCount: number }[];
      friday: { from: number; to: number; maxSendCount: number }[];
      saturday: { from: number; to: number; maxSendCount: number }[];
      sunday: { from: number; to: number; maxSendCount: number }[];
    };
  };
  segmentIds: {
    unknown: string;
    zoho: string;
    gmail: string;
    microsoft: string;
    usedContacts: string;
  };
  segmentStats: {
    unknown: { totalContacts: number };
    zoho: { totalContacts: number };
    gmail: { totalContacts: number };
    microsoft: { totalContacts: number };
    usedContacts: { totalContacts: number };
  };

  isRunning: boolean;
  options: {
    isUnsubscribeGlobal: boolean;
    doSkipRecentlyEmailed: boolean;
    doIncludeUtm: boolean;
    utm: { [key: string]: string };
    doSendTextOnly: boolean;
    doTrackClick: boolean;
    doTrackOpen: boolean;
    followUpPercentage: number;
    cc: string;
    bcc: string;
    replyTo: string;
  };
  filters: Array<
    Array<
      | {
          condition:
            | "tags_include"
            | "tags_exclude"
            | "in_list"
            | "not_in_list"
            | "in_segment"
            | "status"
            | "was_used_in_campaign"
            | "was_not_used_in_campaign"
            | "not_in_segment"
            | "created_before_at"
            | "created_after_at";
          value: string | number;
        }
      | {
          condition: "attribute" | "sys_attribute";
          operator:
            | "equals"
            | "doesnt_equal"
            | "contains"
            | "doesnt_contains"
            | "is_in"
            | "is_not_in"
            | "starts_with"
            | "doesnt_start_with"
            | "ends_with"
            | "doesnt_end_with"
            | "is_set"
            | "is_not_set";
          value: string | string[];
          attribute: string;
        }
    >
  >;
  createdAtMs: number;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___campaignGet(___options: typeOptions) {
  return async function ({
    workspaceId,
    campaignId,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      campaignId, // (required) (undefined === null | "") Joi.string().pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (campaignId === null || campaignId === "") {
      delete ___payload?.["campaignId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      campaignId: Joi.string()
        .pattern(/^campaign-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___campaignGet" failed even before calling the network.
api/private/sdk/campaign/get.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/campaign/get",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
