import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/leads-list/get";
import useCallSdk from "../useCallSdk";

export function buildLeadsListGetQueryKey(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const leadsListId = _payload.leadsListId;
  let _queryKey: string[] = [];

  if (
    typeof workspaceId !== "undefined" &&
    workspaceId !== null &&
    workspaceId !== ""
  ) {
    _queryKey.push(String(workspaceId));
  }

  if (
    typeof leadsListId !== "undefined" &&
    leadsListId !== null &&
    leadsListId !== ""
  ) {
    _queryKey.push(String(leadsListId));
  }

  return ["leads-list:get", ..._queryKey];
}

export function useLeadsListGetQueryFn(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const leadsListId = _payload.leadsListId;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().leadsListGet({
      workspaceId,
      leadsListId,
    });
}

export type typeLeadsListGetHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useLeadsListGet(
  _payload: typePayload,
  _options?: typeLeadsListGetHookOptions
) {
  const workspaceId = _payload.workspaceId;
  const leadsListId = _payload.leadsListId;
  _options = _options || {};
  const _queryKey = buildLeadsListGetQueryKey({ workspaceId, leadsListId });
  const _queryFn = useLeadsListGetQueryFn({ workspaceId, leadsListId });

  return useQuery(_queryKey, _queryFn, _options);
}
