import moment from "moment";
import { useEffect, useState } from "react";

// wrapped in function so other files can access but it will always give them new object
// to avoid potential issues with mutability
export const getDefaultSuffixConfig = () => ({
  year: { singular: " year", plural: " years" },
  month: { singular: " month", plural: " months" },
  day: { singular: " day", plural: " days" },
  hour: { singular: " hour", plural: " hours" },
  minute: { singular: " minute", plural: " minutes" },
  second: { singular: " second", plural: " seconds" },
});
const defaultSuffixConfig = getDefaultSuffixConfig();

export type SuffixConfig = typeof defaultSuffixConfig;

const getSuffix = (
  count: number,
  unit: keyof SuffixConfig,
  suffixConfig?: SuffixConfig
) => {
  const _suffixConfig = suffixConfig ? suffixConfig : defaultSuffixConfig;

  return count > 1 ? _suffixConfig[unit].plural : _suffixConfig[unit].singular;
};

export const getFinalTimerText = ({
  timerEndsAt,
  suffixConfig,
}: {
  timerEndsAt: number;
  suffixConfig?: SuffixConfig;
}) => {
  const presentToTimerEndDuration = moment.duration(
    moment.unix(timerEndsAt).diff(moment())
  );

  const years = presentToTimerEndDuration.years();
  const yearsSegment =
    years > 0 ? `${years}${getSuffix(years, "year", suffixConfig)}` : ``;

  const months = presentToTimerEndDuration.months();
  const monthsSegment =
    months > 0 ? `${months}${getSuffix(months, "month", suffixConfig)}` : ``;

  const days = presentToTimerEndDuration.days();
  const daysSegment =
    days > 0 ? `${days}${getSuffix(days, "day", suffixConfig)}` : ``;

  const hours = presentToTimerEndDuration.hours();
  const hoursSegment =
    hours > 0 ? `${hours}${getSuffix(hours, "hour", suffixConfig)}` : ``;

  const minutes = presentToTimerEndDuration.minutes();
  const minutesSegment =
    minutes > 0
      ? `${minutes}${getSuffix(minutes, "minute", suffixConfig)}`
      : ``;

  const seconds = presentToTimerEndDuration.seconds();
  const secondsSegment =
    seconds > 0
      ? `${seconds}${getSuffix(seconds, "second", suffixConfig)}`
      : ``;

  let finalText: string = "";
  [
    yearsSegment,
    monthsSegment,
    daysSegment,
    hoursSegment,
    minutesSegment,
    secondsSegment,
  ].forEach((timeSegment) => {
    if (timeSegment) {
      // could even allow to customize this " : " separator, but most likely not needed
      finalText += (finalText ? " : " : "") + timeSegment;
    }
  });

  return finalText;
};

type Args = {
  suffixConfig?: SuffixConfig;
  /** unix timestamp */
  timerEndsAt: number;
};

/** @returns `timerText` or empty string if the timer has run out */
export default function useTimeLeftTimer({ suffixConfig, timerEndsAt }: Args) {
  const [timerText, setTimerText] = useState<string>(
    getFinalTimerText({ timerEndsAt, suffixConfig })
  );

  useEffect(() => {
    // because interval won't run until 1 sec
    setTimerText(getFinalTimerText({ timerEndsAt, suffixConfig }));

    const intervalId = setInterval(() => {
      const _timerText = getFinalTimerText({ timerEndsAt, suffixConfig });
      setTimerText(_timerText);
      if (_timerText === "") clearInterval(intervalId); // so timer calculations don't keep running once it expired, if `timerEndsAt` changes entire effect will anyway re-run
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerEndsAt]);

  return { timerText };
}
