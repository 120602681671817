import useActiveWorkspaceId from "../../hooks/useActiveWorkspaceId";
import useWorkspaceGet from "../../utils/reactQueryHooks/workspace/useWorkspaceGet";
import { Fade, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { routes } from "../../config";
import { useNavigate } from "react-router-dom";
import useTeamGetMyProfile from "../../utils/reactQueryHooks/team/useTeamGetMyProfile";
import useUserGetMeta from "../../utils/reactQueryHooks/user/useUserGetMeta";
import useAdminJoinAsSupport from "../../utils/reactQueryHooks/admin/useAdminJoinAsSupport";

export default function WorkspaceLoading({
  children,
}: {
  children: ReactNode;
}) {
  let userMeta = useUserGetMeta({});
  const workspaceId = useActiveWorkspaceId();
  let navigate = useNavigate();
  const joinAsSupport = useAdminJoinAsSupport({
    onSuccess: (data) => {
      if (data?.data?.isSuccess) {
        // auto joined as support, need page refresh
        window.location.reload();
      } else {
        navigate(routes.workspaces.path);
      }
    },
    onError: () => {
      navigate(routes.workspaces.path);
    },
  });

  // pre-load profile info
  let profile = useTeamGetMyProfile({ workspaceId });

  const workspace = useWorkspaceGet(
    { workspaceId },
    {
      enabled: Boolean(workspaceId && profile?.data?.data?.userId),
      onSuccess: (res) => {
        if (res.error?.code) {
          if (userMeta?.data?.data?.type === "staff") {
            joinAsSupport.mutate({
              workspaceId,
            });
          } else {
            navigate(routes.workspaces.path);
          }
        }
      },
      refetchInterval: 10000,
    }
  );

  const theme = useTheme();

  let content = null;

  if (workspace?.data?.data?.workspaceId === workspaceId) {
    content = <>{children}</>;
  } else {
    content = (
      <div style={{ textAlign: "center", margin: theme.spacing(4, 0) }}>
        <Fade in>
          <Typography variant={"caption"} color={"text.secondary"}>
            Loading...
          </Typography>
        </Fade>
      </div>
    );
  }

  return content;
}
