import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = { workspaceId: string; contactId: string };

export type typeResultData = {
  workspaceId: string;
  contactId: string;
  bucket: "new" | "active" | "suspended";
  status: "warm" | "cold";
  email: string;
  firstName?: string;
  lastName?: string;
  tags: string[];
  createdAtMs: number;
  validatedAtMs: number;
  lastEventAtMs: number;
  primaryMx?: string;
  isDisposable?: boolean;

  source: string;
  suspendReason?: string;
  attributes: { [key: string]: string };
  systemAttributes: { [key: string]: string };
  stats: {
    sentCount: number;
    replyCount: number;
    openCount: number;
    clickCount: number;
    unsubscribeCount: number;
    bounceCount: number;
    unsubscribedAt: number;
    sentAt: number;
    clickAt: number;
    openAt: number;
    bounceAt: number;
  };
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___contactGet(___options: typeOptions) {
  return async function ({
    workspaceId,
    contactId,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      contactId, // (required) (undefined === null | "") Joi.string().pattern(/^contact-[a-zA-Z0-9-_]{15,60}$/)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (contactId === null || contactId === "") {
      delete ___payload?.["contactId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      contactId: Joi.string()
        .pattern(/^contact-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___contactGet" failed even before calling the network.
api/private/sdk/contact/get.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/contact/get",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
