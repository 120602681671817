import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  email: string;
  listId: string;
  firstName?: string;
  lastName?: string;
  attributes?: Object;
  tags?: any;
  source?: string;
  doUpdateIfExist?: boolean;
};

export type typeResultData = {
  workspaceId: string;
  contactId: string;
  email: string;
  listId: string;
  isFoundExising: boolean;
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___contactCreate(___options: typeOptions) {
  return async function ({
    workspaceId,
    email,
    listId,
    firstName,
    lastName,
    attributes,
    tags,
    source,
    doUpdateIfExist,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      email, // (required) (undefined === null | "") Joi.string().trim().lowercase().max(100).email({tlds: {allow: false}});
      listId, // (required) (undefined === null | "") Joi.string().pattern(/^list-[a-zA-Z0-9-_]{15,60}$/)
      firstName, // (optional) (undefined === null | "") Joi.string().min(0).max(200)
      lastName, // (optional) (undefined === null | "") Joi.string().min(0).max(200)
      attributes, // (optional) (undefined === null) Joi.object().pattern(/[^|]{1,100}$/, Joi.string().min(1).max(2000) ).min(0).max(50)
      tags, // (optional) (undefined === null) Joi.array().items(Joi.string().min(1).max(20)).max(5)
      source, // (optional) (undefined === null | "") Joi.string().min(0).max(3000)
      doUpdateIfExist, // (optional) Joi.boolean()
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (email === null || email === "") {
      delete ___payload?.["email"];
    }

    if (listId === null || listId === "") {
      delete ___payload?.["listId"];
    }

    if (firstName === null || firstName === "") {
      delete ___payload?.["firstName"];
    }

    if (lastName === null || lastName === "") {
      delete ___payload?.["lastName"];
    }

    if (attributes === null) {
      delete ___payload?.["attributes"];
    }

    if (tags === null) {
      delete ___payload?.["tags"];
    }

    if (source === null || source === "") {
      delete ___payload?.["source"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      email: Joi.string()
        .trim()
        .lowercase()
        .max(100)
        .email({ tlds: { allow: false } })
        .required(),
      listId: Joi.string()
        .pattern(/^list-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
      firstName: Joi.string().min(0).max(200),
      lastName: Joi.string().min(0).max(200),
      attributes: Joi.object()
        .pattern(/[^|]{1,100}$/, Joi.string().min(1).max(2000))
        .min(0)
        .max(50),
      tags: Joi.array().items(Joi.string().min(1).max(20)).max(5),
      source: Joi.string().min(0).max(3000),
      doUpdateIfExist: Joi.boolean(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___contactCreate" failed even before calling the network.
api/private/sdk/contact/create.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/contact/create",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
