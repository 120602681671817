import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  mailId: string;
  messageId: string;
};

export type typeResultData = {
  workspaceId: string;
  mailId: string;
  messageId: string;
  subject: string;
  from: string;
  to: string;
  receivedAt: number;
  mailMessageId: string;
  replyTo?: string;
  inReplyTo?: string;
  foundIds?: string[];
  rawEmail: string;
  issueDetected:
    | "none"
    | "hard-bounce"
    | "soft-bounce"
    | "auto-reply"
    | "reported-as-spam";
  parsedEmail: {
    attachments: {
      type: "attachment";
      content: Buffer;
      contentType: string;
      contentDisposition: string;
      filename?: string | undefined;
      headers: Map<
        string,
        | string
        | string[]
        | {
            value: {
              address?: string | undefined;
              name: string;
              group?:
                | {
                    address?: string | undefined;
                    name: string;
                    group?: undefined;
                  }[]
                | undefined;
            }[];
            html: string;
            text: string;
          }
        | Date
        | {
            value: string;
            params: { [key: string]: string };
          }
      >;
      headerLines: ReadonlyArray<{
        key: string;
        line: string;
      }>;
      checksum: string;
      size: number;
      contentId?: string | undefined;
      cid?: string | undefined;
      related: boolean;
    }[];
    headers: Map<
      string,
      | string
      | string[]
      | {
          value: {
            address?: string | undefined;
            name: string;
            group?:
              | {
                  address?: string | undefined;
                  name: string;
                  group?: undefined;
                }[]
              | undefined;
          }[];
          html: string;
          text: string;
        }
      | Date
      | {
          value: string;
          params: { [key: string]: string };
        }
    >;
    headerLines: ReadonlyArray<{
      key: string;
      line: string;
    }>;
    html: string | false;
    text?: string | undefined;
    textAsHtml?: string | undefined;
    subject?: string | undefined;
    references?: string[] | string | undefined;
    date?: Date | undefined;
    to?:
      | {
          value: {
            address?: string | undefined;
            name: string;
            group?:
              | {
                  address?: string | undefined;
                  name: string;
                  group?: undefined;
                }[]
              | undefined;
          }[];
          html: string;
          text: string;
        }
      | {
          value: {
            address?: string | undefined;
            name: string;
            group?:
              | {
                  address?: string | undefined;
                  name: string;
                  group?: undefined;
                }[]
              | undefined;
          }[];
          html: string;
          text: string;
        }[]
      | undefined;
    from?:
      | {
          value: {
            address?: string | undefined;
            name: string;
            group?:
              | {
                  address?: string | undefined;
                  name: string;
                  group?: undefined;
                }[]
              | undefined;
          }[];
          html: string;
          text: string;
        }
      | undefined;
    cc?:
      | {
          value: {
            address?: string | undefined;
            name: string;
            group?:
              | {
                  address?: string | undefined;
                  name: string;
                  group?: undefined;
                }[]
              | undefined;
          }[];
          html: string;
          text: string;
        }
      | {
          value: {
            address?: string | undefined;
            name: string;
            group?:
              | {
                  address?: string | undefined;
                  name: string;
                  group?: undefined;
                }[]
              | undefined;
          }[];
          html: string;
          text: string;
        }[]
      | undefined;
    bcc?:
      | {
          value: {
            address?: string | undefined;
            name: string;
            group?:
              | {
                  address?: string | undefined;
                  name: string;
                  group?: undefined;
                }[]
              | undefined;
          }[];
          html: string;
          text: string;
        }
      | {
          value: {
            address?: string | undefined;
            name: string;
            group?:
              | {
                  address?: string | undefined;
                  name: string;
                  group?: undefined;
                }[]
              | undefined;
          }[];
          html: string;
          text: string;
        }[]
      | undefined;
    replyTo?:
      | {
          value: {
            address?: string | undefined;
            name: string;
            group?:
              | {
                  address?: string | undefined;
                  name: string;
                  group?: undefined;
                }[]
              | undefined;
          }[];
          html: string;
          text: string;
        }
      | undefined;
    messageId?: string | undefined;
    inReplyTo?: string | undefined;
    priority?: "normal" | "low" | "high" | undefined;
  };
};
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___mailMessageGet(___options: typeOptions) {
  return async function ({
    workspaceId,
    mailId,
    messageId,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      mailId, // (required) (undefined === null | "") Joi.string().pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
      messageId, // (required) (undefined === null | "") Joi.string().min(0).max(3000)
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (mailId === null || mailId === "") {
      delete ___payload?.["mailId"];
    }

    if (messageId === null || messageId === "") {
      delete ___payload?.["messageId"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      mailId: Joi.string()
        .pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
      messageId: Joi.string().min(0).max(3000).required(),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___mailMessageGet" failed even before calling the network.
api/private/sdk/mail-message/get.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/mail-message/get",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
