import {
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/workspace/list";
import useCallSdk from "../useCallSdk";
import { useEffect } from "react";

export function buildWorkspaceListQueryKey(_payload?: typePayload) {
  const perPage = _payload?.perPage;
  const lastKnown = _payload?.lastKnown;
  let _queryKey: string[] = [];

  if (typeof perPage !== "undefined" && perPage !== null) {
    _queryKey.push(String(perPage));
  }

  if (
    typeof lastKnown !== "undefined" &&
    lastKnown !== null &&
    lastKnown !== ""
  ) {
    _queryKey.push(String(lastKnown));
  }

  return ["workspace:list", ..._queryKey];
}

export function useWorkspaceListQueryFn(_payload?: typePayload) {
  const perPage = _payload?.perPage;
  const lastKnown = _payload?.lastKnown;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().workspaceList({
      perPage,
      lastKnown,
    });
}

export type typeWorkspaceListHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useWorkspaceList(
  _payload?: typePayload,
  _options?: typeWorkspaceListHookOptions
) {
  const perPage = _payload?.perPage;
  const lastKnown = _payload?.lastKnown;
  _options = _options || {};
  const _queryKey = buildWorkspaceListQueryKey({ perPage, lastKnown });
  const _queryFn = useWorkspaceListQueryFn({ perPage, lastKnown });

  return useQuery(_queryKey, _queryFn, _options);
}

export type typeWorkspaceListHookOptionsInfinite = UseInfiniteQueryOptions<
  typeResult,
  unknown,
  typeResult,
  typeResult,
  string[]
>;

export function useWorkspaceListInfinity(
  _payload?: typePayload,
  _options?: typeWorkspaceListHookOptionsInfinite
) {
  const perPage = _payload?.perPage;
  const _queryClient = useQueryClient();
  _options = _options || {};
  const _queryKey = buildWorkspaceListQueryKey({ perPage });
  const _sdk = useCallSdk();

  if (typeof _options.getNextPageParam === "undefined") {
    _options.getNextPageParam = (_lastPage: typeResult) =>
      _lastPage.data?.isThereMore ? _lastPage.data?.lastKnown : undefined;
  }

  useEffect(() => {
    const _onUnmount = () => {
      _queryClient.setQueryData(_queryKey, (_oldData: any) => {
        if (_oldData?.pages?.[1] && _oldData?.pageParams[1]) {
          return {
            pages: _oldData.pages.slice(0, 1),
            pageParams: _oldData.pageParams.slice(0, 1),
          };
        }
      });
    };

    return _onUnmount;
  }, []);

  return useInfiniteQuery(
    _queryKey,
    ({ pageParam = undefined }) =>
      _sdk.callSdk().workspaceList({
        perPage,
        lastKnown: pageParam,
      }),
    _options
  );
}
