import HelloBar7dTrial from "./HelloBar7dTrial";
import { useTheme } from "@mui/material";
import useWorkspaceGet from "../../utils/reactQueryHooks/workspace/useWorkspaceGet";
import useActiveWorkspaceId from "../../hooks/useActiveWorkspaceId";
import useTeamGetMyProfile from "../../utils/reactQueryHooks/team/useTeamGetMyProfile";
import HelloBarJoinedAsSupport from "./HelloBarJoinedAsSupport";
import HelloBarNotPaid from "./HelloBarNotPaid";

export default function HelloBar() {
  const theme = useTheme();
  const workspaceId = useActiveWorkspaceId();
  const workspace = useWorkspaceGet({ workspaceId });

  let myProfile = useTeamGetMyProfile({ workspaceId });

  /*
  localStorage.setItem("h24k3erhfdg", "24lirjg");
*/
  return (
    <div style={{ padding: theme.spacing(0, 0, 6) }}>
      {workspace?.data?.data?.paidUntilAt === 0 ? <HelloBar7dTrial /> : null}
      {workspace?.data?.data?.paidUntilAt === 0 ? <HelloBarNotPaid /> : null}
      {myProfile?.data?.data?.role === "support" ? (
        <HelloBarJoinedAsSupport />
      ) : null}
    </div>
  );
}
