import Joi, { ValidationError } from "joi";
import call from "../call";
import runValidation from "../runValidation";

export type typePayload = {
  workspaceId: string;
  mailId: string;
  title: string;
  content?: string;
  isSelected?: boolean;
  meta?: { [key: string]: string | number | boolean };
};

export type typeResultData = { signatureId: string; title: string };
export type typeResultError = {
  code: string;
  message: string;
  validationError?: ValidationError;
  details?: Object;
};
export type typeResult = {
  data: null | typeResultData;
  error: null | typeResultError;
  meta?: null | Object;
};

export type typeOptions = {
  headers?: { [key: string]: string };
  apiHost?: string;
  isDebugMode?: boolean;
  isLocalRunMode?: boolean;
};

export default function ___signatureCreate(___options: typeOptions) {
  return async function ({
    workspaceId,
    mailId,
    title,
    content,
    isSelected,
    meta,
  }: typePayload): Promise<typeResult> {
    let ___payload: { [key: string]: any } = {
      workspaceId, // (required) (undefined === null | "") Joi.string().pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
      mailId, // (required) (undefined === null | "") Joi.string().pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
      title, // (required) (undefined === null | "") Joi.string().min(0).max(3000)
      content, // (optional) (undefined === null | "") Joi.string().min(0).max(50000)
      isSelected, // (optional) (undefined === null) Joi.boolean()
      meta, // (optional) (undefined === null) Joi.object().pattern(Joi.string(), [Joi.string(), Joi.number(), Joi.boolean()])
    };

    if (workspaceId === null || workspaceId === "") {
      delete ___payload?.["workspaceId"];
    }

    if (mailId === null || mailId === "") {
      delete ___payload?.["mailId"];
    }

    if (title === null || title === "") {
      delete ___payload?.["title"];
    }

    if (content === null || content === "") {
      delete ___payload?.["content"];
    }

    if (isSelected === null) {
      delete ___payload?.["isSelected"];
    }

    if (meta === null) {
      delete ___payload?.["meta"];
    }
    let schema = Joi.object({
      workspaceId: Joi.string()
        .pattern(/^workspace-[a-zA-Z0-9-_]{1,300}$/)
        .required(),
      mailId: Joi.string()
        .pattern(/^mail-[a-zA-Z0-9-_]{15,60}$/)
        .required(),
      title: Joi.string().min(0).max(3000).required(),
      content: Joi.string().min(0).max(50000),
      isSelected: Joi.boolean(),
      meta: Joi.object().pattern(Joi.string(), [
        Joi.string(),
        Joi.number(),
        Joi.boolean(),
      ]),
    });

    let validationResult = runValidation({
      payload: ___payload,
      schema,
    });

    if (typeof validationResult.error !== "undefined") {
      try {
        throw new Error("");
      } catch (e) {
        let debug: any = validationResult;
        try {
          debug = JSON.stringify(validationResult);
        } catch (e) {
          // Usually should stringify
        }
        console.error(
          `Payload validation inside SDK method "___signatureCreate" failed even before calling the network.
api/private/sdk/signature/create.ts
${JSON.stringify(debug)}`,
          e
        );
      }
      return {
        error: {
          code: "VALIDATION_ERROR",
          message: "Invalid input",
          validationError: validationResult.error,
        },
        data: null,
      };
    }

    return await call({
      payload: ___payload,
      endpoint: "/signature/create",
      httpMethod: "POST",
      options: ___options,
    });
  };
}
