import { WORKSPACE_ID } from "../../config";
import { StringParam, useQueryParams } from "use-query-params";
import { useQueryClient } from "@tanstack/react-query";
import {
  buildTeamGetMyProfileQueryKey,
  useTeamGetMyProfileQueryFn,
} from "../../utils/reactQueryHooks/team/useTeamGetMyProfile";
import {
  buildTeamGetInvitationUrlQueryKey,
  useTeamGetInvitationUrlQueryFn,
} from "../../utils/reactQueryHooks/team/useTeamGetInvitationUrl";
import {
  buildTeamListQueryKey,
  useTeamListQueryFn,
} from "../../utils/reactQueryHooks/team/useTeamList";

export default function usePrefetchSettingsWorkspace() {
  const queryClient = useQueryClient();
  const [queryParams] = useQueryParams({
    [WORKSPACE_ID]: StringParam,
  });
  let workspaceId = queryParams[WORKSPACE_ID] || "";

  const teamGetMyProfileQueryKey = buildTeamGetMyProfileQueryKey({
    workspaceId,
  });
  const teamGetMyProfileQueryFn = useTeamGetMyProfileQueryFn({ workspaceId });

  const teamGetInvitationUrlQueryKey = buildTeamGetInvitationUrlQueryKey({
    workspaceId,
  });
  const teamGetInvitationUrlQueryFn = useTeamGetInvitationUrlQueryFn({
    workspaceId,
  });

  const teamListQueryKey = buildTeamListQueryKey({
    workspaceId,
    perPage: 1000,
  });
  const teamListQueryFn = useTeamListQueryFn({
    workspaceId,
    perPage: 1000,
  });

  return () => {
    queryClient.prefetchQuery(
      teamGetMyProfileQueryKey,
      () => teamGetMyProfileQueryFn(),
      { staleTime: Infinity }
    );
    queryClient.prefetchQuery(
      teamGetInvitationUrlQueryKey,
      () => teamGetInvitationUrlQueryFn(),
      { staleTime: Infinity }
    );
    queryClient.prefetchInfiniteQuery(
      teamListQueryKey,
      () => teamListQueryFn(),
      { staleTime: Infinity }
    );
  };
}
