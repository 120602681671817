import { Divider, Grid, Tab, Tabs } from "@mui/material";
import { TABS_ADMIN, TAB_ADMIN, TabAdminParams } from "queryParams";
import { useQueryParams } from "use-query-params";
import Prospecting from "./Prospecting";
import TabBannedAccounts from "./TabBannedAccounts";

type Props = {};

export default function TabsAdmin({}: Props) {
  const [queryParams, setQueryParams] = useQueryParams({
    [TAB_ADMIN]: TabAdminParams,
  });
  const selectedTab = queryParams[TAB_ADMIN] || "ban";

  let tabContent: React.ReactNode;
  if (selectedTab === "ban") {
    tabContent = <TabBannedAccounts />;
  } else {
    tabContent = <Prospecting />;
  }

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tabs value={selectedTab}>
            <Tab
              value={TABS_ADMIN.BAN}
              label="Ban"
              onClick={() =>
                setQueryParams({
                  "tab-admin": "ban",
                })
              }
            />
            <Tab
              value={TABS_ADMIN.PROSPECTING}
              label="Prospecting"
              onClick={() =>
                setQueryParams({
                  "tab-admin": "prospecting",
                })
              }
            />
          </Tabs>
        </div>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        {tabContent}
      </Grid>
    </Grid>
  );
}
