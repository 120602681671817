import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { typePayload, typeResult } from "../../sdk/team/get-my-profile";
import useCallSdk from "../useCallSdk";

export function buildTeamGetMyProfileQueryKey(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  let _queryKey: string[] = [];

  if (
    typeof workspaceId !== "undefined" &&
    workspaceId !== null &&
    workspaceId !== ""
  ) {
    _queryKey.push(String(workspaceId));
  }

  return ["team:get-my-profile", ..._queryKey];
}

export function useTeamGetMyProfileQueryFn(_payload: typePayload) {
  const workspaceId = _payload.workspaceId;
  const _sdk = useCallSdk();

  return () =>
    _sdk.callSdk().teamGetMyProfile({
      workspaceId,
    });
}

export type typeTeamGetMyProfileHookOptions = UseQueryOptions<
  typeResult,
  unknown,
  typeResult,
  string[]
>;

export default function useTeamGetMyProfile(
  _payload: typePayload,
  _options?: typeTeamGetMyProfileHookOptions
) {
  const workspaceId = _payload.workspaceId;
  _options = _options || {};
  const _queryKey = buildTeamGetMyProfileQueryKey({ workspaceId });
  const _queryFn = useTeamGetMyProfileQueryFn({ workspaceId });

  return useQuery(_queryKey, _queryFn, _options);
}
